import { axios } from "../../../lib/axios";

const getRavageur = async (startDate, endDate) => {
  const { alertepucerons } = await axios.get(`/alerte-pucerons/detection-ravageur?startDate=${startDate}&endDate=${endDate}`);
  return alertepucerons;
};

const setRavageur = async (data) => {
  return await axios.post(`/alerte-pucerons/detection-ravageur`, data);  
};

const getCodifRavageur = async () => {
  const { agronomie } = await axios.get(`/agronomie/conseilagricole/etapes?code=&avecdes=A`);
  return agronomie;
};

const getImage = async (ravageur) => {
  return await axios.get(`/stats-sia/file?server=${ravageur.serveur}&path=${ravageur.chemin}&name=${ravageur.photo}`, {returnsFile: true, responseType: 'blob'});
};

export { getRavageur, setRavageur, getCodifRavageur, getImage }