import { create } from 'zustand';

const useMapStore = create(() => ({
  map: null,
}));

const setMap = (map) =>
  useMapStore.setState(() => ({
    map: map,
  }));

export { useMapStore, setMap };
