import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import FormSelect from '../../../../components/FormSelect';
import { typePrint } from '../../enums';

const ChoiseForm = ({ onSubmit }) => {
  const defaultValues = {
    typeprint: 'portrait',
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = methods;

  const [typeprint] = useState(typePrint);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Impression</Typography>
        <Divider />
        <FormSelect name="typeprint" control={control} label="Mise en page" MenuProps={MenuProps} disableClear={true}>
          {typeprint.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={isSubmitting}>
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Imprimer'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default ChoiseForm;
