import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import FormSelect from '../../../../components/FormSelect';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import {
  // getSecteur,
  // getUsines,
  // getTechType,
  // getTechList,
  getCommunes,
  // getEtaArrachage,
  // getGroupeArrachage,
  getCampagnes,
} from '../../api';
import { natureProduitCode } from '../../enums';
import { getAnneeSemisList } from '../../rules/couvert-mellifere';

const defaultValues = {
  campagne: '',
  codeplanteur: '',
  commune: '',
  // secteur: '',
  // usine: '',
  // techtype: '',
  // technom: '',
  culture: '',
  anneesemis: '',
  // eta: '',
  // grparrachage: '',
};

const SearchForm = ({ onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    // setValue,
    setError,
    clearErrors,
    watch,
    reset,
    control,
  } = methods;

  const watchCulture = watch('culture');

  // const [usines, setUsines] = useState([]);
  // const [types, setTechType] = useState([]);
  // const [noms, setTechNom] = useState([]);
  // const [etas, setEtas] = useState([]);
  // const [groupes, setGroupes] = useState([]);
  const [campagnes, setCampagnes] = useState([]);
  const [isCouvertMellifere, setIsCouvertMellifere] = useState(false);
  const [isVinasse, setIsVinasse] = useState(false);

  const cultures = [
    { label: 'Toutes', value: natureProduitCode.planteur },
    { label: 'Betterave', value: natureProduitCode.betterave },
    { label: 'Luzerne', value: natureProduitCode.luzerne },
    { label: 'Vinasse', value: natureProduitCode.vinasse },
    { label: 'Couvert méllifère', value: natureProduitCode.couvertMellifere },
  ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init campagnes
  useEffect(() => {
    (async () => {
      const data = await getCampagnes();
      const mapCampagnes = data.map((i) => ({ label: i.libcamp, value: i.campsur4 }));
      setCampagnes(mapCampagnes);
    })();
  }, []);

  useEffect(() => {
    if (watchCulture != '' && watchCulture === natureProduitCode.couvertMellifere) {
      setIsCouvertMellifere(true);
    } else if (watchCulture != '' && watchCulture === natureProduitCode.vinasse) {
      setIsVinasse(true);
    } else {
      setIsCouvertMellifere(false);
      setIsVinasse(false);
    }
  }, [watchCulture]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Rechercher des parcelles</Typography>
        <Divider />
        <Typography variant="subtitle1">Code(s)</Typography>
        <FormSelect name="campagne" control={control} label="Campagne" MenuProps={MenuProps}>
          {campagnes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText name="codeplanteur" control={control} label="Code planteur" />
        <FormInputAutocomplete
          name="commune"
          control={control}
          label="Commune"
          placeholder="Tapez une commune"
          displayProp="nom"
          equalityProp="codeInsee"
          source={getCommunes}
        />
        <Divider />
        <FormSelect
          name="culture"
          control={control}
          label="Type de culture"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {cultures.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        {isCouvertMellifere && (
          <FormSelect name="anneesemis" control={control} label="Année semis" MenuProps={MenuProps}>
            {getAnneeSemisList().map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </FormSelect>
        )}
        {isVinasse && (
          <FormInputText name="codecommande" control={control} label="N° commande vinasse" number={true} />
        )}
        
        {<Typography variant="inherit" color="red">
        {errors?.form?.message}
        </Typography>}
        <Button
          onClick={() => {
            // data validation before submit
            const atLeastOneMandatoryValues =
              getValues(['codeplanteur', 'commune', 'codecommande']).join('').length > 0;
            if (!atLeastOneMandatoryValues) {
              setError('form', {
                type: 'manual',
                message: 'Veuillez renseigner au moins un champ le code planteur ou une commune',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          // onClick={handleSubmit(onSubmit)}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Rechercher'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default SearchForm;
