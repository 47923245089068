import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import {
  Button,
  Paper,
  Typography,
  Divider,
  MenuItem,
  CircularProgress,
  Link,
  Skeleton,
  Box,
  ListSubheader,
  Chip,
  Checkbox,
  ListItemText,
} from '@mui/material';
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import { getCommunes, getPlanteurByNom } from '../../../parcelle/api';
import { dateToString } from '../../../../utils/date';
import { getImage } from '../../api';
import { fileBufferToBase64 } from '../../../../utils/image';
import { setImageDialog } from '../../../../stores/imageDialog';
import { getLabelGps } from '../../enums';

const EditForm = ({ onValid, onRefuse, ravageur, codifRavageur }) => {
  const defaultValues = {
    typeEtchrono: ravageur.codif_list_typeEtchrono,
    commune: {
      codeInsee: ravageur.communeinsee,
      nom: ravageur.nomcommune,
    },
    planteur: {
      cpcu: ravageur.planteur,
      nom: ravageur.nomplanteur,
    },
  };

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
    watch,
    getValues,
    setError,
    clearErrors,
  } = methods;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const [showSkeletonPhoto, setShowSkeletonPhoto] = useState(true);
  const [photo, setPhoto] = useState('');
  const [types, setTypes] = useState(ravageur.codif_list_typeEtchrono);
  const watchTypes = watch('typeEtchrono');

  useEffect(() => {
    setTypes(watchTypes);
  }, [watchTypes]);

  useEffect(() => {
    (() => {
      getImage(ravageur)
        .then((imageData) => fileBufferToBase64(imageData, ravageur.photo))
        .then((base64) => {
          setPhoto(base64);
        })
        .finally(() => setShowSkeletonPhoto(false));
    })();
  });

  const onPhotoClick = () => {
    setImageDialog(true, [
      {
        src: photo,
        title: dateToString(ravageur.dateheurephoto),
      },
    ]);
  };

  const divRemoveTags = document.createElement('div');
  divRemoveTags.innerHTML = ravageur.commentaire ? ravageur.commentaire : '';
  const commentaire = divRemoveTags.textContent || divRemoveTags.innerText || '';

  const famillesObservation = [...new Set( codifRavageur.map(codif => codif.libtype)) ];

  return (
    <>
      <Paper
        style={{
          display: 'grid',
          gridRowGap: '5px',
          padding: '20px',
        }}
      >
        <Typography variant="h6">Observation du {dateToString(ravageur.dateheurephoto)}</Typography>
        <Typography>USG: {ravageur.nomusine ? ravageur.nomusine : 'inconnue'}</Typography>
        <Typography>
          Parcelle: {ravageur.nomparcelle ? ravageur.nomparcelle : 'inconnue'}
        </Typography>
        <Typography>{getLabelGps(ravageur)}</Typography>
        <Typography>
          Envoyé par :{' '}
          <Link href={'mailto: ' + ravageur.mail + '?subject=CRISTAL%20Vigie%20PUCERONS'}>
            {ravageur.mail}
          </Link>
        </Typography>
      </Paper>
      <Paper
        style={{
          display: 'grid',
          gridRowGap: '20px',
          padding: '0 20px 20px 20px',
        }}
      >
        <FormProvider {...methods}>
          <Divider />

          {showSkeletonPhoto && <Skeleton animation="wave" variant="rounded" height={150} />}
          {!showSkeletonPhoto && (
            <img
              src={photo}
              style={{ cursor: 'pointer', maxWidth: '100%' }}
              onClick={onPhotoClick}
            />
          )}
          <Box component="div" whiteSpace="normal" sx={{ wordBreak: 'break-word' }}>
            {commentaire}
          </Box>

          <Divider />

          <FormInputAutocomplete
            name="planteur"
            control={control}
            label="Planteur"
            placeholder="Tapez un nom de planteur"
            displayProp="nom"
            equalityProp="cpcu"
            displayValue={ravageur.nomplanteur}
            source={getPlanteurByNom}
          />

          <FormInputAutocomplete
            name="commune"
            control={control}
            label="Commune"
            placeholder="Tapez une commune"
            displayProp="nom"
            equalityProp="codeInsee"
            displayValue={ravageur.nomcommune}
            source={getCommunes}
          />

          <FormSelect
            name="typeEtchrono"
            control={control}
            label="Type d'observation"
            MenuProps={MenuProps}
            multiple
            renderValue={(values) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {values.map((value) => {
                  const type = codifRavageur.filter(c => c.id == value)[0];
                  return (<Chip key={type.id} label={type.lib} />);
                })}
              </Box>
            )}
          >
            {famillesObservation.map(famille => {
              return [
                  <ListSubheader key={famille}>{famille}</ListSubheader>,
                  ...codifRavageur.filter(codif => codif.libtype == famille).map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      <Checkbox checked={types.indexOf(option.id) > -1} />
                      <ListItemText primary={option.lib} />
                    </MenuItem>
                  ))
                ]
            })}
          </FormSelect>

          <Typography variant="inherit" color="red">
            {errors?.form?.message}
          </Typography>
          <Button
            onClick={() => {
              const types = [];
              getValues('typeEtchrono').forEach(typeEtChrono => {
                const type = codifRavageur.filter(c => c.id == typeEtChrono)[0].type;
                if (types.indexOf(type) < 0) {
                  types.push(type);
                }
              });

              if (
                getValues('typeEtchrono').length == 0 ||
                !getValues('commune') ||
                !getValues('commune').codeInsee ||
                getValues('commune').codeInsee == '0000000'
              ) {
                setError('form', {
                  type: 'manual',
                  message:
                    'Veuillez sélectionner un type d\'observation et une commune avant de valider',
                });
              } else if (types.length > 1) {
                setError('form', {
                  type: 'manual',
                  message:
                  'Vous ne pouvez pas sélectionner des types de différentes familles d\'observation',
                });
              } else {
                clearErrors();
                return handleSubmit(onValid)();
              }
            }}
            variant="contained"
            color="success"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Valider'}
          </Button>
          <Button
            onClick={() => {
              return handleSubmit(onRefuse)();
            }}
            variant="contained"
            color="error"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Refuser'}
          </Button>
        </FormProvider>
      </Paper>
    </>
  );
};

export default EditForm;
