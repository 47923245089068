import { useState, useEffect } from 'react';
import View from 'ol/View';
import { useViewStore } from '../stores/view';
import useWindowSize from './useWindowSize';
import { useSideBarStore } from '../stores/sidebar';
import { isMobile } from 'react-device-detect';

const useView = () => {
  const stateView = useViewStore((state) => state.view);
  // eslint-disable-next-line no-unused-vars
  const [view, setView] = useState(new View({ ...stateView }));

  const stateCenterView = useViewStore((state) => state.center);
  const stateZoomView = useViewStore((state) => state.zoom);
  const stateExtentView = useViewStore((state) => state.extent);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (view && stateCenterView && stateZoomView) {
      view.setCenter(stateCenterView);
      view.setZoom(stateZoomView);
    }
  }, [view, stateCenterView, stateZoomView]);

  useEffect(() => {
    if (view && stateExtentView) {
      view.fit(stateExtentView, {
        size: [windowSize.width, windowSize.height], 
        padding: [100, 100, 100, useSideBarStore.getState().visible && !isMobile ? 400 : 100]
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, stateExtentView]); // don't add windowSize as dependency, we don't want the map to update extent when window is resizing

  // save each view zoom and center change's into store
  // debounced with 500ms
  // useEffect(() => {
  //   if (view) {
  //     view.on(
  //       'change:resolution',
  //       debounce((e) => {
  //         const view = e.target;
  //         setViewZoom(view.getZoom());
  //         setViewExtent(view.calculateExtent(getMapSize()));
  //       }, 500)
  //     );

  //     view.on(
  //       'change:center',
  //       debounce((e) => {
  //         const view = e.target;
  //         setViewCenter(view.getCenter());
  //         setViewExtent(view.calculateExtent(getMapSize()));
  //       }, 500)
  //     );
  //   }
  // }, [setViewCenter, setViewZoom, setViewExtent, view]);

  return [view];
};

export default useView;
