import React, { useState, useEffect, useRef } from 'react';
import { List, Drawer, SwipeableDrawer, Fab, Divider } from '@mui/material';
import Cluster from 'ol/source/Cluster';
import * as features from '../../features';
import { useLayers } from '../../hooks';
import can from '../../utils/duckTyping';
import { isMobile } from 'react-device-detect';
import { blue } from '@mui/material/colors';
import { Global } from '@emotion/react';
import { toggleSideBar, useSideBarStore } from '../../stores/sidebar';
import { setActiveLayer, useLayerStore } from '../../stores/layers';
import { useItemsStore, highlightItem, addItems } from '../../stores/menuItems';
import LayerMenuActions from './LayerMenuActions';
import LayerListItems from './LayerListItems';
import LayerSectionedListItems from './LayerSectionedListItems';
import TopBar from './TopBar';
import ScrollTop from '../ScrollTop';
import MaterialIcon from '../MaterialIcon';

export default function SideBar({ toggle = false }) {
  const visible = useSideBarStore((state) => state.visible);
  const activeLayer = useLayerStore((state) => state.activeLayer);
  const stateItems = useItemsStore((state) => state.items);
  const stateHighlightItem = useItemsStore((state) => state.highlightItem);

  const { vectorLayers } = useLayers();

  const drawerRef = useRef(null);
  const [menuLayers, setMenuLayers] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [menuListItems, setMenuListItems] = useState([]);
  const [showMenuListItems, setShowMenuListItems] = useState(true);
  const [sectionedListItems, setSectionedListItems] = useState(false);

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);

  const [sectionStatus, setSectionStatus] = useState({});
  const [actionSectionStatus, setActionSectionStatus] = useState({});

  const handleSectionPageChange = (event, value) => {
    let sectionId = event.target.closest('.MuiPagination-root').getAttribute('data-section-id'); // Attribut d'un bouton de numéro de page
    const newStatus = { ...sectionStatus };
    newStatus[sectionId].page = value;
    setSectionStatus(newStatus);
    setPage(value);
  };

  const toggleSectionStatus = (itemId) => {
    const newStatus = { ...sectionStatus };
    newStatus[itemId].open = !sectionStatus[itemId].open;
    setSectionStatus(newStatus);
  };

  const toggleActionSectionStatus = (key) => {
    const newActionStatus = { ...actionSectionStatus };
    newActionStatus[key].open = !actionSectionStatus[key].open;
    setActionSectionStatus(newActionStatus);
  };

  const buildMenuItems = (layer) => {
    const keys = Object.keys(features);
    const key = keys.filter((key) => features[key].id === layer.get('id'));
    const newActionsSectionStatus = { ...actionSectionStatus };
    let menuActions = null;

    if (can(features[key], 'menuItems')) {
      menuActions = features[key].menuItems();
      menuActions.map((i) => {
        if (i.childs && i.childs.length > 0) {
          if (actionSectionStatus[i.key] === undefined) {
            newActionsSectionStatus[i.key] = {
              open: i.open,
            };
          }
          return i;
        } else {
          return i;
        }
      });
    }
    setActionSectionStatus(newActionsSectionStatus);
    setMenuItems(menuActions);
  };

  const displayHighlightedItemComponent = (highlightedItem) => {
    if (!highlightedItem?.layer || !highlightedItem?.feature) {
      return null;
    }

    const keys = Object.keys(features);
    const key = keys.filter((key) => features[key].id === highlightedItem.layer.get('id'));
    if (can(features[key], 'highlightedItemComponent')) {
      return features[key].highlightedItemComponent(highlightedItem.feature);
    } else {
      return null;
    }
  };

  const buildMenuListItems = (layer) => {
    const keys = Object.keys(features);
    const key = keys.filter((key) => features[key].id === layer.get('id'));
    const source =
      layer.getSource() instanceof Cluster ? layer.getSource().getSource() : layer.getSource();

    let menuListItems = [];
    let hasSection = false;
    const newSectionStatus = { ...sectionStatus };

    if (can(features[key], 'itemsList')) {
      menuListItems = features[key].itemsList();
      menuListItems.map((i) => {
        if (i.section) {
          if (sectionStatus[i.itemId] === undefined) {
            newSectionStatus[i.itemId] = {
              open: i.open,
              page: 1,
            };
          }
          newSectionStatus[i.itemId].noOfPages = Math.ceil(i.childs.length / itemsPerPage);
          if (newSectionStatus[i.itemId].page > newSectionStatus[i.itemId].noOfPages) {
            newSectionStatus[i.itemId].page = 1;
          }

          hasSection = true;
        }
      });
    } else if (can(features[key], 'mapItem') && can(features[key], 'listItem')) {
      // map data and create items
      menuListItems = source.getFeatures().map((f) => {
        features[key].mapItem(f);
        const item = features[key].listItem(f);
        return item;
      });
    }

    setSectionedListItems(hasSection);
    setSectionStatus(newSectionStatus);
    setMenuListItems(menuListItems);

    return menuListItems.length;
  };

  const getColor = (cardItem) => {
    if (cardItem.color) {
      return cardItem.color;
    }

    const keys = Object.keys(features);
    const key = keys.filter((key) => features[key].id === activeLayer?.get('id'));

    if (key.length > 0 && features[key].props.color) {
      return features[key].props.color;
    } else {
      return blue[500];
    }
  };

  const scrollToTop = () => {
    if (drawerRef.current) {
      const anchor = document.querySelector('#back-to-top-anchor');

      if (anchor) {
        anchor.scrollIntoView({
          block: 'center',
        });
      }
    }
  };

  useEffect(() => {
    toggleSideBar(toggle);
  }, [toggle]);

  // setup menu items that come from store
  useEffect(() => {
    if (stateItems && Object.keys(stateItems).length === 3) {
      let children = null;

      if (stateItems.children) {
        children = stateItems.children.map((c) => {
          return React.createElement(c.component, c.props, c.children);
        });
      }

      const component = React.createElement(stateItems.component, stateItems.props, children);
      setShowMenuListItems(false);
      setMenuItems(component);

      // Scroll Sidebar to top
      scrollToTop();
    }
  }, [stateItems]);

  useEffect(() => {
    if (stateHighlightItem?.layer) {
      const layers = vectorLayers.filter((l) => l.get('visible') === true);
      const isPresent = layers.filter(
        (l) => l.get('name') === stateHighlightItem.layer.get('name')
      );

      if (isPresent.length > 0) {
        // need it to prevent infinite loop because the layer of highlight item may be not visible now
        // and we can't apply highlight if layer is not visible.
        // also we need to clear items in store
        if (stateHighlightItem.layer.get('id') === activeLayer.get('id')) {
          // change page if the item is not on the current
          menuListItems.map((item, index) => {
            if (item.section) {
              item.childs.map((child, childIndex) => {
                if (
                  child.itemId === stateHighlightItem.key ||
                  (stateHighlightItem.feature &&
                    child.itemId === stateHighlightItem.feature.get('nanoid'))
                ) {
                  const newStatus = { ...sectionStatus };
                  const itemPage = Math.ceil((childIndex + 1) / itemsPerPage);
                  let updateSectionStatus = false;

                  if (itemPage !== newStatus[item.itemId].page) {
                    updateSectionStatus = true;
                    newStatus[item.itemId].page = itemPage;
                  }
                  if (!newStatus[item.itemId].open) {
                    updateSectionStatus = true;
                    newStatus[item.itemId].open = true;
                  }
                  if (updateSectionStatus) {
                    setSectionStatus(newStatus);
                  }
                }
              });
            } else {
              if (
                item.itemId === stateHighlightItem.key ||
                (stateHighlightItem.feature &&
                  item.itemId === stateHighlightItem.feature.get('nanoid'))
              ) {
                const itemPage = Math.ceil((index + 1) / itemsPerPage);
                if (itemPage !== page) {
                  setPage(itemPage);
                }
              }
            }
          });
        } else {
          setActiveLayer(stateHighlightItem.layer);
        }
        // Scroll to item
        if (stateHighlightItem.feature && stateHighlightItem.scrollToCard && drawerRef.current) {
          setTimeout(() => {
            const anchor = document.querySelector(
              '#card-' + stateHighlightItem.feature.get('nanoid') + '-anchor'
            );
            if (anchor) {
              anchor.scrollIntoView({ block: 'center' });
            }
          }, 200); // Delay to let the sidebar render the new page
        }
      } else {
        highlightItem({});
      }
    }
    // eslint-disable-next-line
  }, [activeLayer, stateHighlightItem, menuListItems]); // don't add 'page' to dependencies array (it will block the pagination component on current page)

  // setup menu items that come from visible layers
  // or when layer changes
  useEffect(() => {
    const layers = vectorLayers.filter((l) => l.get('visible') === true);

    // Si le layer actif est caché, on active le premier layer visible disponible
    if (layers.length > 0) {
      vectorLayers
        .filter((l) => l.get('visible') === false)
        .map((l) => {
          if (activeLayer && activeLayer.get('id') === l.get('id')) {
            setActiveLayer(layers[0]);
          }
        });
    }

    // Si on a pas de layer actif et qu'on a des layers visibles, on active le premier layer visible disponible
    if (layers.length > 0 && !activeLayer) {
      setActiveLayer(layers[0]);
    }

    // Si on a aucun layer visible et qu'on a un layer actif, on le désactive
    if (layers.length == 0 && activeLayer) {
      setActiveLayer(null);
    }

    // On met à jour le menu layer de la sidebar
    setMenuLayers(layers);

    // On met à jour la sidebar que si on a des layers visibles et un layer actif
    if (layers.length > 0 && activeLayer) {
      const isShowingTools = stateItems && Object.keys(stateItems).length === 3;
      if (isShowingTools) {
        addItems({});
        setTimeout(() => scrollToTop(), 200); // délai pour affichage des cards
      }
      layers.map((l) => {
        if (activeLayer && activeLayer.get('id') === l.get('id')) {
          buildMenuItems(l);
          const itemsCount = buildMenuListItems(l);
          const pages = Math.ceil(itemsCount / itemsPerPage);
          setPage(1);
          setNoOfPages(pages);
        }
      });
      setShowMenuListItems(true);
    } else {
      setShowMenuListItems(false);
      setMenuItems([]);
      setMenuListItems([]);
      setMenuLayers([]);
      setPage(1);
      setNoOfPages(1);
    }
    // eslint-disable-next-line
  }, [activeLayer, vectorLayers]);

  const childItems = (
    <>
      <List>
        <LayerMenuActions
          menuActions={menuItems}
          actionSectionStatus={actionSectionStatus}
          toggleActionSectionStatus={toggleActionSectionStatus}
        />
      </List>
      <Divider />
      {displayHighlightedItemComponent(stateHighlightItem)}
      {showMenuListItems && !sectionedListItems && (
        <LayerListItems
          menuListItems={menuListItems}
          itemsPerPage={itemsPerPage}
          page={page}
          setPage={setPage}
          noOfPages={noOfPages}
          layerName={activeLayer?.get('name')}
          getColor={getColor}
        />
      )}
      {showMenuListItems && sectionedListItems && (
        <LayerSectionedListItems
          menuListItems={menuListItems}
          itemsPerPage={itemsPerPage}
          sectionStatus={sectionStatus}
          toggleSectionStatus={toggleSectionStatus}
          layerName={activeLayer?.get('name')}
          getColor={getColor}
          handleSectionPageChange={handleSectionPageChange}
        />
      )}
    </>
  );

  if (!isMobile) {
    return (
      <>
        <Drawer
          variant="persistent"
          anchor="left"
          open={visible}
          ref={drawerRef}
          PaperProps={{
            sx: { width: `calc(25%)` },
          }}
          className="sideBar" // Ajout d'une classe pour cacher lors de l'impression
        >
          <TopBar
            toggleSideBar={toggleSideBar}
            menuLayers={menuLayers}
            activeLayer={activeLayer}
            buildMenuItems={buildMenuItems}
            buildMenuListItems={buildMenuListItems}
            setShowMenuListItems={setShowMenuListItems}
          />
          {childItems}
        </Drawer>
        {visible && (
          <ScrollTop target={drawerRef.current ? drawerRef.current.childNodes[0] : undefined}>
            <Fab color="secondary" size="small">
              <MaterialIcon icon={'KeyboardArrowUp'} />
            </Fab>
          </ScrollTop>
        )}
        {(visible && (
          <Fab
            size="small"
            onClick={() => toggleSideBar(false)}
            sx={{
              textTransform: 'none',
              bgColor: 'white',
              position: 'absolute',
              zIndex: 2,
              left: !visible ? `calc(1%)` : `calc(25%)`,
              top: `calc(50%)`,
              bottom: `calc(50%)`,
              marginLeft: !visible ? `1%` : `2%`,
              transition: 'transform 0.15s ease-in-out',
              '&:hover': {
                transform: 'scale3d(1.5, 1.5, 1.5)',
              },
            }}
          >
            <MaterialIcon icon={'ArrowBackIosNewIcon'} />
          </Fab>
        )) ||
          (!visible && (
            <Fab
              onClick={() => toggleSideBar(true)}
              size="small"
              sx={{
                textTransform: 'none',
                bgColor: 'white',
                position: 'absolute',
                zIndex: 2,
                top: `calc(50%)`,
                bottom: `calc(50%)`,
                left: !visible ? `calc(1%)` : `calc(25%)`,
                transition: 'transform 0.15s ease-in-out',
                marginLeft: !visible ? `1%` : `2%`,
                '&:hover': {
                  transform: 'scale3d(1.5, 1.5, 1.5)',
                },
              }}
            >
              <MaterialIcon icon={'ArrowForwardIos'} />
            </Fab>
          ))}
      </>
    );
  } else {
    const drawerBleeding = 25;
    return (
      <>
        <Global
          styles={{
            '.MuiDrawer-root > .MuiPaper-root': {
              height: `calc(100% - 60px)`,
            },
          }}
        />
        <SwipeableDrawer
          anchor="bottom"
          open={visible}
          ref={drawerRef}
          onClose={() => toggleSideBar(false)}
          onOpen={() => toggleSideBar(true)}
          swipeAreaWidth={drawerBleeding}
          disableSwipeToOpen={false}
          PaperProps={{
            sx: { width: `calc(100%)` },
          }}
          ModalProps={{
            keepMounted: true,
          }}
          className="sideBar" //Ajout d'une classe pour cacher lors de l'impression
        >
          <TopBar
            toggleSideBar={toggleSideBar}
            menuLayers={menuLayers}
            activeLayer={activeLayer}
            buildMenuItems={buildMenuItems}
            buildMenuListItems={buildMenuListItems}
            setShowMenuListItems={setShowMenuListItems}
          />
          {childItems}
        </SwipeableDrawer>
        <ScrollTop target={drawerRef.current ? drawerRef.current.childNodes[0] : undefined}>
          <Fab color="secondary" size="small">
            <MaterialIcon icon={'KeyboardArrowUp'} />
          </Fab>
        </ScrollTop>
        {(visible && (
          <Fab
            size="medium"
            onClick={() => toggleSideBar(false)}
            sx={{
              textTransform: 'none',
              bgColor: 'white',
              position: 'absolute',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              left: `calc(50% - 20px)`,
              top: `5px`,
            }}
          >
            <MaterialIcon icon={'ExpandMore'} />
          </Fab>
        )) ||
          (!visible && (
            <Fab
              onClick={() => toggleSideBar(true)}
              size="medium"
              sx={{
                textTransform: 'none',
                bgColor: 'white',
                position: 'absolute',
                zIndex: (theme) => theme.zIndex.drawer + 1,
                bottom: `25px`,
                left: `calc(50% - 20px)`,
              }}
            >
              <MaterialIcon icon={'ExpandLess'} />
            </Fab>
          ))}
      </>
    );
  }
}
