import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from "react-hook-form";
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import FormSelect from '../../../../components/FormSelect';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import { getEntreprise, getUsines } from '../../api';
import { typeMaterielAgricole } from '../../enums';

const defaultValues = {
  entreprise: '',
  materiels: [],
  typemateriel: '',
  usine: '',
};

const SearchForm = ({ materiels, onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    control,
  } = methods;

  const watchTypeMateriel = watch('typemateriel');
  const watchEntreprise = watch('entreprise');
  const watchCodeMateriel = watch('codemateriel');


  const [usines, setUsines] = useState([]);
  const [disabledUsine, setDisabledUsine] = useState(true);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init usines list
  useEffect(() => {
    (async () => {
      const data = await getUsines();
      const mapUsines = data.map((u) => ({ label: u.nom, value: u.code_usine }));
      setUsines(mapUsines);
      setDisabledUsine(true);
    })();
  }, []);

  useEffect(() => {
    if (watchTypeMateriel != '') {
      setValue('codemateriel', '');
      setDisabledUsine(false);
    } else {
      setValue('usine', '');
      setDisabledUsine(true);
    }
  }, [watchTypeMateriel, setValue]);

  useEffect(() => {
    if (watchCodeMateriel != '') {
      setValue('usine', '');
      setValue('entreprise', '');
      setValue('typemateriel', '');
      setDisabledUsine(true);
    }
  }, [watchCodeMateriel, setValue]);

  useEffect(() => {
    if (watchEntreprise != '') {
      setValue('codemateriel', '');
    }
  }, [watchEntreprise, setValue]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
      <Typography variant="h6">Rechercher des matériels agricoles</Typography>
      <Divider />
      <Typography variant="subtitle1">Code(s)</Typography>
      <FormInputAutocomplete
        name="materiels"
        multiple
        control={control}
        label="Code(s) matériel(s)"
        placeholder="Saisir un code et taper 'Entrée'"
        displayProp="code"
        equalityProp="code"
        autoSelect
        filterSelectedOptions
        noOptionsText="Aucun matériel trouvé"
        isLocalSource
        source={materiels}
      />

      <FormInputAutocomplete
        name="entreprise"
        control={control}
        label="Nom entreprise"
        placeholder="Tapez un nom d'entreprise"
        displayProp="nomentrep"
        equalityProp="codeentrep"
        source={getEntreprise}
      />

      <FormSelect
        name="typemateriel"
        control={control}
        label="Type de matériel"
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            },
          },
        }}
      >
        {typeMaterielAgricole.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormSelect>
      <FormSelect
        name="usine"
        control={control}
        label="Usine de livraison"
        MenuProps={MenuProps}
        inputProps={{ readOnly: disabledUsine }}
      >
        {usines.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </FormSelect>
      <Typography variant="inherit" color="red">
        {errors?.form?.message}
      </Typography>
      <Button
        onClick={() => {
          // data validation before submit
          const atLeastOneMandatoryValues =
            getValues(['materiels', 'entreprise', 'typemateriel', 'usine']).join('').length > 0;
          if (!atLeastOneMandatoryValues) {
            setError('form', {
              type: 'manual',
              message:
                'Veuillez renseigner au moins un champ (code matériel, nom entreprise, type de matériel)',
            });
          } else {
            clearErrors();
            return handleSubmit(onSubmit)();
          }
        }}
        variant="contained"
        disabled={isSubmitting}
      >
        {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Rechercher'}
      </Button>
      <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
        Effacer
      </Button>
      </FormProvider>
    </Paper>
  );
};

export default SearchForm;
