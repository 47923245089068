import { transform } from 'ol/proj';

const toWGS84 = (x, y) => {
  return transform([x, y], 'EPSG:3857', 'EPSG:4326');
};

const fromWGS84 = (x, y) => {
  return transform([x, y], 'EPSG:4326', 'EPSG:3857');
};

export { toWGS84, fromWGS84 };
