import React, { useState, useEffect, forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  CardHeader,
  Card,
  Button,
  IconButton,
  CardActions,
  CardContent,
  Typography,
  Collapse,
  Tooltip,
  Avatar,
} from '@mui/material';
import MaterialIcon from '../MaterialIcon';
import { blue } from '@mui/material/colors';
import { useItemsStore } from '../../stores/menuItems';

const ExpandMore = styled((props) => {
  // eslint-disable-next-line no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const InfosCard = (props, ref) => {
  const {
    titleHeader = '',
    subHeader = '',
    content = [],
    actions = [],
    avatar = null,
    style,
    layerName = '',
    itemId = '',
    enableAllActionOnCenter = true,
    enableExpendAction = true,
    showAlwaysContent = false,
    color = blue[500],
    onExpand = null
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const stateHighlightItem = useItemsStore((state) => state.highlightItem);

  const handleExpandClick = () => {
    if(!expanded && onExpand) onExpand();
    setExpanded(!expanded);
  };

  const renderActions = (actions) => {
    if (enableAllActionOnCenter || showActions) {
      return actions.map((a, index) => (
        <Tooltip key={index} title={a.title} arrow>
          <IconButton onClick={a.handleClick}>
            <MaterialIcon icon={a.icon} />
          </IconButton>
        </Tooltip>
      ));
    } else {
      // first action must be 'Center'
      return actions.slice(0, 1).map((a, index) => (
        <Tooltip key={index} title={a.title} arrow>
          <IconButton onClick={a.handleClick}>
            <MaterialIcon icon={a.icon} />
          </IconButton>
        </Tooltip>
      ));
    }
  };

  // only show all actions on selected item
  useEffect(() => {
    if (stateHighlightItem && itemId) {
      if (stateHighlightItem.key === itemId  || (stateHighlightItem.feature && stateHighlightItem.feature.get('nanoid') === itemId)) {
        setShowActions(true);
      } else {
        setShowActions(false);
      }
    }
  }, [stateHighlightItem, itemId]);

  return (
    <Card ref={ref} style={style} sx={showActions ? { m: 1, bgcolor: 'lightgrey', width: '100%' } : { m: 1, width: '100%' }}>
      <CardHeader
        avatar={avatar != null ? (avatar) : <Avatar sx={{ bgcolor: color }}>{layerName.substring(0, 2)}</Avatar>}
        // action={
        //   <IconButton>
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={titleHeader}
        subheader={subHeader}
      />
      {/* <CardMedia
        component="img"
        height="194"
        image="/static/images/cards/paella.jpg"
        alt="Paella dish"
      /> */}
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent> */}
      <CardActions disableSpacing>
        {renderActions(actions)}
        {enableExpendAction && (
          <ExpandMore expand={expanded} onClick={handleExpandClick}>
            <MaterialIcon icon="ExpandMore" />
          </ExpandMore>
        )}
      </CardActions>
      {(enableExpendAction || showAlwaysContent) && (
        <Collapse in={expanded || showAlwaysContent} timeout="auto" unmountOnExit>
          <CardContent>
            {content.map((content, index) => {
              if (typeof content === 'string' || React.isValidElement(content)) {
                return <Typography key={index}>{content}</Typography>;
              }

              if (typeof content === 'object') {
                return (
                  <Button {...content.props} key={index} onClick={content.handleClick}>
                    {content.title}
                  </Button>
                );
              }
            })}
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default forwardRef(InfosCard);
