import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AdjustIcon from '@mui/icons-material/Adjust';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import LayersIcon from '@mui/icons-material/Layers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import EditIcon from '@mui/icons-material/Edit';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UploadIcon from '@mui/icons-material/Upload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PrintIcon from '@mui/icons-material/Print';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import VerifiedIcon from '@mui/icons-material/Verified';
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import FunctionsIcon from '@mui/icons-material/Functions';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import PercentIcon from '@mui/icons-material/Percent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function MaterialIcon({ icon }) {
  // const Icon = lazy(() =>
  //   import(`@mui/icons-material/${icon}`)
  // );

  // return (
  //   <Suspense fallback={<div>Loading...</div>}>
  //     <Icon />
  //   </Suspense>
  // );

  const createIconComponent = () => {
    switch (icon) {
      case 'Search':
        return React.createElement(SearchIcon, {}, null);
      case 'Close':
        return React.createElement(CloseIcon, {}, null);
      case 'Adjust':
        return React.createElement(AdjustIcon, {}, null);
      case 'LocationSearching':
        return React.createElement(LocationSearchingIcon, {}, null);
      case 'Layers':
        return React.createElement(LayersIcon, {}, null);
      case 'ArrowBack':
        return React.createElement(ArrowBackIcon, {}, null);
      case 'KeyboardArrowUp':
        return React.createElement(KeyboardArrowUpIcon, {}, null);
      case 'CenterFocusStrong':
        return React.createElement(CenterFocusStrongIcon, {}, null);
      case 'Edit':
        return React.createElement(EditIcon, {}, null);
      case 'EditLocation':
        return React.createElement(EditLocationIcon, {}, null);
      case 'Delete':
        return React.createElement(DeleteIcon, {}, null);
      case 'AddCircle':
        return React.createElement(AddCircleIcon, {}, null);
      case 'AddLocation':
        return React.createElement(AddLocationIcon, {}, null);
      case 'ShareLocation':
        return React.createElement(ShareLocationIcon, {}, null);
      case 'ExpandMore':
        return React.createElement(ExpandMoreIcon, {}, null);
      case 'ExpandLess':
        return React.createElement(ExpandLessIcon, {}, null);
      case 'AccountCircle':
        return React.createElement(AccountCircleIcon, {}, null);
      case 'Upload':
        return React.createElement(UploadIcon, {}, null);
      case 'FileDownload':
        return React.createElement(FileDownloadIcon, {}, null);
      case 'Add':
        return React.createElement(AddIcon, {}, null);
      case 'FormatListBulleted':
        return React.createElement(FormatListBulletedIcon, {}, null);
      case 'ArrowForwardIos':
        return React.createElement(ArrowForwardIosIcon, {}, null);
      case 'ArrowBackIosNewIcon':
        return React.createElement(ArrowBackIosNewIcon, {}, null);
      case 'Print':
        return React.createElement(PrintIcon, {}, null);
      case 'CameraAlt':
        return React.createElement(CameraAltIcon, {}, null);
      case 'Verified':
        return React.createElement(VerifiedIcon, {}, null);
      case 'WaterDrop':
        return React.createElement(WaterDropIcon, {}, null);
      case 'Functions':
        return React.createElement(FunctionsIcon, {}, null);
      case 'Thermostat':
        return React.createElement(ThermostatIcon, {}, null);
      case 'Air':
        return React.createElement(AirIcon, {}, null);
      case 'Percent':
        return React.createElement(PercentIcon, {}, null);
      case 'CheckBoxOutlineBlank':
        return React.createElement(CheckBoxOutlineBlankIcon, {}, null);
      case 'CheckBox':
        return React.createElement(CheckBoxIcon, {}, null);
      default:
        return React.createElement(ClearIcon, {}, null);
    }
  };

  return createIconComponent();
}
