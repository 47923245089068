import React, { useEffect, useState } from 'react';
import { IconButton, AppBar, Toolbar } from '@mui/material';
import { useMenuToolsStore, unvisibleALLTools } from '../../../stores/menuTools';
import MaterialIcon from '../../MaterialIcon';

export default function SideMenuLaoyout({ ...props }) {
  let { setShowMainMenu } = props;
  const stateMenuToolsStore = useMenuToolsStore((state) => state.tools);
  const [showReturn, setShowReturn] = useState(false);

  // if one feature is show we can display return icon
  useEffect(() => {
    const menuTool = stateMenuToolsStore.filter((o) => (o = o.visible === true));
    if (menuTool.length > 0) {
      setShowReturn(true);
    }else {
      setShowReturn(false);
    }
  }, [stateMenuToolsStore]);

  const CustomToolBar = () => {
    if(showReturn){
      return(
        <Toolbar
        id="back-to-top-anchor"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          onClick={() => {
            unvisibleALLTools()
          }}
          size="large"
          edge="start"
          color="inherit"
        >
          <MaterialIcon icon={'ArrowBack'} />
        </IconButton>
        <IconButton
          onClick={() => {
            unvisibleALLTools();
            setShowMainMenu(false);
          }}
          size="large"
          color="inherit"
        >
          <MaterialIcon icon={'Close'} />
        </IconButton>
      </Toolbar>
      )
    }else {
      return (
        <Toolbar
        id="back-to-top-anchor"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <IconButton
          onClick={() => {
            unvisibleALLTools();
            setShowMainMenu(false);
          }}
          size="large"
          color="inherit"
        >
          <MaterialIcon icon={'Close'} />
        </IconButton>
      </Toolbar>
      )
    }
  }

  return (
    <>
      <AppBar position="static">
        <CustomToolBar />
      </AppBar>
    </>
  );
}
