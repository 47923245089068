import { getArea } from 'ol/sphere';
import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { getAnneeSemisFromCulture } from '../rules/couvert-mellifere';

const createGeometry = ({ feature, data }) => {
  const insertFeature = new Feature();
  const anneeSemis = getAnneeSemisFromCulture(data.culture.code || data.culture);

  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('nom_couvert', data.nomparcelle);
  insertFeature.set('code_planteur', data.codeplanteur.cpcu || '');
  insertFeature.set('code_insee', data.codeinsee);
  insertFeature.set('surf_calc', data.surfcalc);
  insertFeature.set('surf_reelle', parseFloat(data.surfreelle.replace(',', '.')));
  insertFeature.set('code_cultu', data.culture.code || data.culture);
  insertFeature.set('annee_semis', anneeSemis);

  // create feature collection GeoJSON
  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    insertFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometry = async ({ feature }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());
  updateFeature.set('surf_calc', (getArea(feature.getGeometry()) / 10000).toFixed(2));

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometryProperties = async ({ feature, data }) => {
  const updateFeature = new Feature();
  const anneeSemis = getAnneeSemisFromCulture(data.culture);

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.set('nom_couvert', data.nomparcelle);
  updateFeature.set('code_planteur', data.codeplanteur);
  updateFeature.set('code_insee', data.codeinsee);
  updateFeature.set('surf_calc', data.surfcalc);
  updateFeature.set('surf_reelle', parseFloat(data.surfreelle.replace(',', '.')));
  updateFeature.set('code_cultu', data.culture);
  updateFeature.set('annee_semis', anneeSemis);

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

export { createGeometry, updateGeometry, updateGeometryProperties };
