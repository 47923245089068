import { create } from 'zustand';

const useImageDialogStore = create(() => ({
  open: false,
  images: []
}));

const setImageDialog = (open, images) =>
  useImageDialogStore.setState(() => ({
    open: open,
    images: images
  }));

export { useImageDialogStore, setImageDialog };
