import React from 'react';
import { Zoom, Box, useScrollTrigger } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { theme } from '../../config/theme';

const ScrollTop = ({ target, children }) => {
  const trigger = useScrollTrigger({ target });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        sx={{
          position: 'absolute',
          zIndex: theme.zIndex.drawer + 2,
          top: `calc(100% - .5em*2 - 2.375em)`,
          left: !isMobile ? 'unset' : `calc(74%)`,
          right: !isMobile ? `calc(75% - 50px)` : 'unset',
        }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

export default ScrollTop;
