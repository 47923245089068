import { axios } from '../../../lib/axios';
import { CRISTAL_CLOUD_URL } from '../../../config';

const getPictosImages = async () => {
  const { features } = await axios.get(
    `/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=cristalmaps:pictos_images&outputFormat=application/json`
  );
  return features.map((f) => {
    f.id = f.id.replace('pictos_images.', '');
    f.id = parseInt(f.id);
    return f;
  });
};

const getPictosInExtent = async ({ extent }) => {
  const response = await axios.get(
    `/geoserver/wfs?service=WFS&version=1.1.0&request=GetFeature&typeName=cristalmaps:pictos&outputFormat=application/json&bbox=${
      extent.join(',') || ''
    }`
  );
  return response;
};

const getPictosWFSUrl = (extent) =>
  CRISTAL_CLOUD_URL +
  'geoserver/wfs?service=WFS&' +
  'version=1.1.0&request=GetFeature&typename=cristalmaps:pictos&' +
  'outputFormat=application/json&srsname=EPSG:3857&' +
  'bbox=' +
  extent.join(',') +
  ',EPSG:3857';

const sendPicto = async (pictoXml) => {
  const response = await axios.post('/geoserver/wfs', pictoXml, {
    headers: { 'Content-Type': 'text/xml' },
  });
  return response;
};

const getPictosApi = async ({ extent, type }) => {
  const response = await axios.get(`/parcelles-api/pictos?bbox=${extent || ''}&type=${type || ''}`);
  return response;
};

const sendPictosApi = async (picto) => {
  const response = await axios.post('/parcelles-api/pictos', picto);
  return response;
};

const deletePictosApi = async (gid) => {
  const response = await axios.delete(`/parcelles-api/pictos/${gid}`);
  return response;
};

const updatePictosApi = async (picto) => {
  const response = await axios.put('/parcelles-api/pictos', picto);
  return response;
};

const getPictosImagesApi = async () => {
  const { features } = await axios.get(`/parcelles-api/pictos_images`);
  return features;
};

export {
  getPictosImages,
  getPictosInExtent,
  getPictosWFSUrl,
  sendPicto,
  getPictosApi,
  sendPictosApi,
  deletePictosApi,
  updatePictosApi,
  getPictosImagesApi,
};
