import React from "react";
import Lightbox from "yet-another-react-lightbox";
import { setImageDialog, useImageDialogStore } from "../../stores/imageDialog";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const ImageDialog = () => {
  const stateOpen = useImageDialogStore((state) => state.open);
  const stateImages = useImageDialogStore((state) => state.images);

  return (
    <Lightbox
        open={stateOpen}
        close={() => setImageDialog(false, [])}
        slides={stateImages}
        carousel={{finite: true}}
        render={{buttonPrev: () => null, buttonNext: () => null}}
        plugins={[Fullscreen, Zoom]}
      />
  );
};

export default ImageDialog;
