import { Projection } from 'ol/proj';
import { GeoJSON } from 'ol/format';
import VectorTileSource from 'ol/source/VectorTile.js';

////////
// https://openlayers.org/en/latest/examples/geojson-vt.html
////////

const vectorTileSourceFromGeojsonVt = (geojsonVtTileIndex, projection, tileSize) => {
  const format = new GeoJSON({
    // Data returned from geojson-vt is in tile pixel units
    dataProjection: new Projection({
      code: 'TILE_PIXELS',
      units: 'tile-pixels',
      extent: [0, 0, tileSize, tileSize],
    }),
  });
  const vectorSource = new VectorTileSource({
    tileUrlFunction: function (tileCoord) {
      // Use the tile coordinate as a pseudo URL for caching purposes
      return JSON.stringify(tileCoord);
    },
    tileLoadFunction: function (tile, url) {
      const tileCoord = JSON.parse(url);
      const data = geojsonVtTileIndex.getTile(tileCoord[0], tileCoord[1], tileCoord[2]);
      const geojson = JSON.stringify(
        {
          type: 'FeatureCollection',
          features: data ? data.features : [],
        },
        replacer
      );
      const features = format.readFeatures(geojson, {
        extent: vectorSource.getTileGrid().getTileCoordExtent(tileCoord),
        featureProjection: projection,
      });
      tile.setFeatures(features);
    },
  });
  return vectorSource;
};

const replacer = function (_key, value) {
  if (!value || !value.geometry) {
    return value;
  }

  let type;
  const rawType = value.type;
  let geometry = value.geometry;
  if (rawType === 1) {
    type = 'MultiPoint';
    if (geometry.length == 1) {
      type = 'Point';
      geometry = geometry[0];
    }
  } else if (rawType === 2) {
    type = 'MultiLineString';
    if (geometry.length == 1) {
      type = 'LineString';
      geometry = geometry[0];
    }
  } else if (rawType === 3) {
    type = 'Polygon';
    if (geometry.length > 1) {
      type = 'MultiPolygon';
      geometry = [geometry];
    }
  }

  return {
    type: 'Feature',
    geometry: {
      type: type,
      coordinates: geometry,
    },
    properties: value.tags,
  };
};

export { vectorTileSourceFromGeojsonVt };
