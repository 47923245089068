import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';
import FormSelect from '../../../../components/FormSelect';
import {
  Button,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  MenuItem,
  Stack,
  Avatar,
} from '@mui/material';
import { getUsines } from '../../api';
import { props } from '../../../../features/silo';

const defaultValues = {
  codesilo1: '',
  codesilo2: '',
  codesilo3: '',
  codesilo4: '',
  codesilo5: '',
  usine: '',
};

const EncodageCamionForm = ({ onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    control,
    getValues,
    clearErrors,
    setError,
  } = methods;

  const [usines, setUsines] = useState([]);

  // init usines list
  useEffect(() => {
    (async () => {
      const data = await getUsines();
      const mapUsines = data.map((u) => ({ label: u.nom, value: u.code_usine }));
      setUsines(mapUsines);
    })();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Position encodage camion au silo</Typography>
        <Divider />
        <FormSelect name="usine" control={control} label="Usine de livraison" MenuProps={MenuProps}>
          {usines.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <Stack direction="row" justifyContent="space-between" alignContent="center" spacing={3}>
          <FormInputText
            name="codesilo1"
            control={control}
            label="Code silo 1"
            number={true}
            rules={{
              pattern: {
                value: /^[0-9]{7}$/,
                message: 'Veuillez entrer un code silo valide (7 chiffres)',
              },
            }}
          />
          <Avatar sx={{ bgcolor: props.colorsilo1, fontSize: 14 }} variant="square">
            silo 1
          </Avatar>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center" spacing={3}>
          <FormInputText
            name="codesilo2"
            control={control}
            label="Code silo 2"
            number={true}
            rules={{
              pattern: {
                value: /^[0-9]{7}$/,
                message: 'Veuillez entrer un code silo valide (7 chiffres)',
              },
            }}
          />
          <Avatar sx={{ bgcolor: props.colorsilo2, fontSize: 14 }} variant="square">
            silo 2
          </Avatar>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center" spacing={3}>
          <FormInputText
            name="codesilo3"
            control={control}
            label="Code silo 3"
            number={true}
            rules={{
              pattern: {
                value: /^[0-9]{7}$/,
                message: 'Veuillez entrer un code silo valide (7 chiffres)',
              },
            }}
          />
          <Avatar sx={{ bgcolor: props.colorsilo3, fontSize: 14 }} variant="square">
            silo 3
          </Avatar>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center" spacing={3}>
          <FormInputText
            name="codesilo4"
            control={control}
            label="Code silo 4"
            number={true}
            rules={{
              pattern: {
                value: /^[0-9]{7}$/,
                message: 'Veuillez entrer un code silo valide (7 chiffres)',
              },
            }}
          />
          <Avatar sx={{ bgcolor: props.colorsilo4, fontSize: 14 }} variant="square">
            silo 4
          </Avatar>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignContent="center" spacing={3}>
          <FormInputText
            name="codesilo5"
            control={control}
            label="Code silo 5"
            number={true}
            rules={{
              pattern: {
                value: /^[0-9]{7}$/,
                message: 'Veuillez entrer un code silo valide (7 chiffres)',
              },
            }}
          />
          <Avatar sx={{ bgcolor: props.colorsilo5, fontSize: 14 }} variant="square">
            silo 5
          </Avatar>
        </Stack>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const usine = getValues('usine');
            const atLeastOneMandatoryValues =
              getValues(['codesilo1', 'codesilo2', 'codesilo3', 'codesilo4', 'codesilo5']).join('')
                .length > 0;
            if (usine === '' && !atLeastOneMandatoryValues) {
              setError('form', {
                type: 'custom',
                message:
                  "Veuillez sélectionner une usine et renseigner un code silo (jusqu'à 5 codes) avant de valider",
              });
            } else if (usine === '') {
              setError('form', {
                type: 'custom',
                message: 'Veuillez sélectionner une usine avant de valider',
              });
            } else if (!atLeastOneMandatoryValues) {
              setError('form', {
                type: 'custom',
                message: "Veuillez renseigner un code silo (jusqu'à 5 codes) avant de valider",
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'OK'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default EncodageCamionForm;
