import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useItemsStore = create(
  subscribeWithSelector(() => ({
    items: {},
    highlightItem: {},
  }))
);

const addItems = (items) =>
  useItemsStore.setState(() => ({
    items: { ...items },
  }));

const highlightItem = (item) =>
  useItemsStore.setState(() => ({
    highlightItem: { ...item },
  }));

const setItems = (item) => {
  const index = useItemsStore.getState().items.component.name === item.component.name;
  if (index) {
    useItemsStore.setState(() => ({
      items: { ...item },
    }));
  }
};

export { useItemsStore, addItems, highlightItem, setItems };
