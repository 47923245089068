import { create } from 'zustand';

const useWaitDialogStore = create(() => ({
  open: false
}));

const setWaitDialog = (open) =>
  useWaitDialogStore.setState(() => ({
    open: open
  }));

export { useWaitDialogStore, setWaitDialog };
