/**
 * Format given date to dd/MM/yyyy HH:mm:ss
 * @param {Date} date 
 * @returns 
 */
const datetimeToString = (date) => {
  return date.toLocaleString('fr-FR', {dateStyle: 'short', timeStyle: 'short'}).replace(',', '');
};

const dateToString = (date) => {
  return date.toLocaleString('fr-FR', {dateStyle: "long"}).replace(',', '');
};

const as400DateTimeToDate = (date, time) => {
  return new Date(date.substr(0, 4)+'-'+date.substr(4, 2)+'-'+date.substr(6, 2)+'T'+time.substr(0, 2)+':'+time.substr(2, 2)+':00');
}

const as400DateToFormattedString = (date) => {
  return date.substr(6, 2)+'/'+date.substr(4, 2)+'/'+date.substr(0, 4);
}

const as400TimeToFormattedString = (date) => {
  return date.substr(0, 2)+':'+date.substr(2, 2);
}

/**
 * @param {Date} date 
 * @returns {String}
 */
const dateToAs400String = (date) => {
  return date.getFullYear() + (date.getMonth()+1).toString().padStart(2, '0') + date.getDate().toString().padStart(2, '0');
}

/**
 * @param {Date} date 
 * @returns {String}
 */
const timeToAs400String = (date) => {
  return date.getHours().toString().padStart(2, '0') + date.getMinutes().toString().padStart(2, '0');
}

export { datetimeToString, dateToString, as400DateToFormattedString, as400TimeToFormattedString, as400DateTimeToDate, dateToAs400String, timeToAs400String };
