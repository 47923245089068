// Retrun an tab with each preference in list and attribut with number is formated without number
const mapperReadPref = (rowData) => {
  const temp = [];
  const data = [];
  temp.push(rowData);
  temp.map((l) =>
    data.push(
      {
        COUCHECARTO: l.COUCHECARTO1,
        LATPOINTBASCC: parseInt(l.LATPOINTBASCC1),
        LATPOINTHAUTCC: parseInt(l.LATPOINTHAUTCC1),
        LONPOINTBASCC: parseInt(l.LONPOINTBASCC1),
        LONPOINTHAUTCC: parseInt(l.LONPOINTHAUTCC1),
        SELECTIONCC: l.SELECTIONCC1,
        ...l,
      },
      {
        COUCHECARTO: l.COUCHECARTO2,
        LATPOINTBASCC: parseInt(l.LATPOINTBASCC2),
        LATPOINTHAUTCC: parseInt(l.LATPOINTHAUTCC2),
        LONPOINTBASCC: parseInt(l.LONPOINTBASCC2),
        LONPOINTHAUTCC: parseInt(l.LONPOINTHAUTCC2),
        SELECTIONCC: l.SELECTIONCC2,
        ...l,
      },
      {
        COUCHECARTO: l.COUCHECARTO3,
        LATPOINTBASCC: parseInt(l.LATPOINTBASCC3),
        LATPOINTHAUTCC: parseInt(l.LATPOINTHAUTCC3),
        LONPOINTBASCC: parseInt(l.LONPOINTBASCC3),
        LONPOINTHAUTCC: parseInt(l.LONPOINTHAUTCC3),
        SELECTIONCC: l.SELECTIONCC3,
        ...l,
      },
      {
        COUCHECARTO: l.COUCHECARTO4,
        LATPOINTBASCC: parseInt(l.LATPOINTBASCC4),
        LATPOINTHAUTCC: parseInt(l.LATPOINTHAUTCC4),
        LONPOINTBASCC: parseInt(l.LONPOINTBASCC4),
        LONPOINTHAUTCC: parseInt(l.LONPOINTHAUTCC4),
        SELECTIONCC: l.SELECTIONCC4,
        ...l,
      },
      {
        COUCHECARTO: l.COUCHECARTO5,
        LATPOINTBASCC: parseInt(l.LATPOINTBASCC5),
        LATPOINTHAUTCC: parseInt(l.LATPOINTHAUTCC5),
        LONPOINTBASCC: parseInt(l.LONPOINTBASCC5),
        LONPOINTHAUTCC: parseInt(l.LONPOINTHAUTCC5),
        SELECTIONCC: l.SELECTIONCC5,
        ...l,
      }
    )
  );
  return data;
};

const dsinfoprofilFromPrefTech = (prefTech) => {
  return {
    pour500: parseInt(prefTech.POURROUTE500),
    pourscan: parseInt(prefTech.POURSCAN25),
    pourortho: parseInt(prefTech.POURPHOTOAE),
    xpointbas: parseInt(prefTech.XPOINTBAS),
    ypointbas: parseInt(prefTech.YPOINTBAS),
    xpointhaut: parseInt(prefTech.XPOINTHAUT),
    ypointhaut: parseInt(prefTech.YPOINTHAUT),
    echelle: '',
    prefcatpro: prefTech.PREFCATPRO,
    prefnatpro: prefTech.PREFNATPRO,

    couchecarto1: prefTech.COUCHECARTO1,
    selcc1: prefTech.SELECTIONCC1,
    lonpbcc1: parseInt(prefTech.LONPOINTBASCC1),
    latpbcc1: parseInt(prefTech.LATPOINTBASCC1),
    lonphcc1: parseInt(prefTech.LONPOINTHAUTCC1),
    latphcc1: parseInt(prefTech.LATPOINTHAUTCC1),

    couchecarto2: prefTech.COUCHECARTO2,
    selcc2: prefTech.SELECTIONCC2,
    lonpbcc2: parseInt(prefTech.LONPOINTBASCC2),
    latpbcc2: parseInt(prefTech.LATPOINTBASCC2),
    lonphcc2: parseInt(prefTech.LONPOINTHAUTCC2),
    latphcc2: parseInt(prefTech.LATPOINTHAUTCC2),

    couchecarto3: prefTech.COUCHECARTO3,
    selcc3: prefTech.SELECTIONCC3,
    lonpbcc3: parseInt(prefTech.LONPOINTBASCC3),
    latpbcc3: parseInt(prefTech.LATPOINTBASCC3),
    lonphcc3: parseInt(prefTech.LONPOINTHAUTCC3),
    latphcc3: parseInt(prefTech.LATPOINTHAUTCC3),

    couchecarto4: prefTech.COUCHECARTO4,
    selcc4: prefTech.SELECTIONCC4,
    lonpbcc4: parseInt(prefTech.LONPOINTBASCC4),
    latpbcc4: parseInt(prefTech.LATPOINTBASCC4),
    lonphcc4: parseInt(prefTech.LONPOINTHAUTCC4),
    latphcc4: parseInt(prefTech.LATPOINTHAUTCC4),

    couchecarto5: prefTech.COUCHECARTO5,
    selcc5: prefTech.SELECTIONCC5,
    lonpbcc5: parseInt(prefTech.LONPOINTBASCC5),
    latpbcc5: parseInt(prefTech.LATPOINTBASCC5),
    lonphcc5: parseInt(prefTech.LONPOINTHAUTCC5),
    latphcc5: parseInt(prefTech.LATPOINTHAUTCC5),

    couchecartt1: prefTech.COUCHECARTT1,
    selct1: prefTech.SELECTIONCT1,
    lonpbct1: parseInt(prefTech.LONPOINTBASCT1),
    latpbct1: parseInt(prefTech.LATPOINTBASCT1),
    lonphct1: parseInt(prefTech.LONPOINTHAUTCT1),
    latphct1: parseInt(prefTech.LATPOINTHAUTCT1),

    couchecartt2: prefTech.COUCHECARTT2,
    selct2: prefTech.SELECTIONCT2,
    lonpbct2: parseInt(prefTech.LONPOINTBASCT2),
    latpbct2: parseInt(prefTech.LATPOINTBASCT2),
    lonphct2: parseInt(prefTech.LONPOINTHAUTCT2),
    latphct2: parseInt(prefTech.LATPOINTHAUTCT2),

    couchecartt3: prefTech.COUCHECARTT3,
    selct3: prefTech.SELECTIONCT3,
    lonpbct3: parseInt(prefTech.LONPOINTBASCT3),
    latpbct3: parseInt(prefTech.LATPOINTBASCT3),
    lonphct3: parseInt(prefTech.LONPOINTHAUTCT3),
    latphct3: parseInt(prefTech.LATPOINTHAUTCT3),

    couchecartt4: prefTech.COUCHECARTT4,
    selct4: prefTech.SELECTIONCT4,
    lonpbct4: parseInt(prefTech.LONPOINTBASCT4),
    latpbct4: parseInt(prefTech.LATPOINTBASCT4),
    lonphct4: parseInt(prefTech.LONPOINTHAUTCT4),
    latphct4: parseInt(prefTech.LATPOINTHAUTCT4),

    couchecartt5: prefTech.COUCHECARTT5,
    selct5: prefTech.SELECTIONCT5,
    lonpbct5: parseInt(prefTech.LONPOINTBASCT5),
    latpbct5: parseInt(prefTech.LATPOINTBASCT5),
    lonphct5: parseInt(prefTech.LONPOINTHAUTCT5),
    latphct5: parseInt(prefTech.LATPOINTHAUTCT5),

    pourscan25t: parseInt(prefTech.POURSCAN25T),
    pourphotoaet: parseInt(prefTech.POURPHOTOAET),
  };
};

const mapperUpdatePref = (prefTech, data, index, viewExtent, state, rScan, rOrtho, r500) => {
  let selcc = '';
  state.vector.forEach((v) => {
    if (v.visible) {
      selcc += `${v.title}|`;
    }
  });
  if (selcc) {
    selcc = selcc.slice(0, -1);
  }

  var dsinfoprofil = dsinfoprofilFromPrefTech(prefTech);
  dsinfoprofil.pourscan = rScan && rScan.visible ? 100 : 0;
  dsinfoprofil.pourortho = rOrtho && rOrtho.visible ? 100 : 0;
  dsinfoprofil.pour500 = r500 && r500.visible ? 100 : 0;
  dsinfoprofil['couchecarto'+(index+1)] = data.label;
  dsinfoprofil['selcc'+(index+1)] = selcc;
  dsinfoprofil['lonpbcc'+(index+1)] = parseInt(viewExtent[0]);
  dsinfoprofil['latpbcc'+(index+1)] = parseInt(viewExtent[1]);
  dsinfoprofil['lonphcc'+(index+1)] = parseInt(viewExtent[2]);
  dsinfoprofil['latphcc'+(index+1)] = parseInt(viewExtent[3]);
  return dsinfoprofil;
};

const mapperUpdateAllPref = (oldPrefTech, newPrefTech) => {
  let dsinfoprofil = dsinfoprofilFromPrefTech(oldPrefTech);

  newPrefTech.prefTech.forEach((item, index) => {
    dsinfoprofil['couchecarto'+(index+1)] = item.COUCHECARTO;
    dsinfoprofil['selcc'+(index+1)] = item.SELECTIONCC;
    dsinfoprofil['lonpbcc'+(index+1)] = parseInt(item.LONPOINTBASCC);
    dsinfoprofil['latpbcc'+(index+1)] = parseInt(item.LATPOINTBASCC);
    dsinfoprofil['lonphcc'+(index+1)] = parseInt(item.LONPOINTHAUTCC);
    dsinfoprofil['latphcc'+(index+1)] = parseInt(item.LATPOINTHAUTCC);
  });
  return dsinfoprofil;
};

export { mapperReadPref, mapperUpdatePref, mapperUpdateAllPref };
