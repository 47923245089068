import React from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import MaterialIcon from '../MaterialIcon';
import { useFormContext } from 'react-hook-form';

const FormSelect = ({
  name,
  label,
  control,
  defaultValue = '',
  helperText,
  children,
  error,
  rules,
  disableClear = false,
  ...props
}) => {
  const labelId = `${name}-label`;
  const { required } = props;
  const methods = useFormContext();

  return (
    <FormControl required={required} error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <Select
            labelId={labelId}
            label={label}
            onChange={onChange}
            value={value}
            endAdornment={
              !disableClear && methods && (value != '' || value.length > 0) ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      if (props.multiple) {
                        methods.setValue(name, []);
                      } else {
                        methods.setValue(name, '');
                      }
                    }}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                    }}
                    edge="end"
                  >
                    <MaterialIcon />
                  </IconButton>
                </InputAdornment>
              ) : null
            }
            sx={{
              '& .MuiSelect-iconOutlined': {
                display:
                  !disableClear && methods && (value != '' || value.length > 0) ? 'none' : '',
              },
            }}
            {...props}
          >
            {typeof children === 'function' ? children(value) : children}
          </Select>
        )}
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
export default FormSelect;
