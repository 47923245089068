import { create } from 'zustand';
import { id as prefTechId, props as prefTechProps } from '../features/pref-tech';
import { id as printId, props as printProps } from '../features/print';

const useMenuToolsStore = create(() => ({
  tools: [
    { id: prefTechId, ...prefTechProps },
    { id: printId, ...printProps }
  ],
}));

const visibleTool = (tool) => {
  const index = useMenuToolsStore.getState().tools.findIndex((l) => l.id === tool.id);
  useMenuToolsStore.setState((state) => ({
    tools: state.tools.map((l, i) => (i === index ? { ...l, ...tool, visible: true } : l)),
  }));
};

const unvisibleALLTools = () => {
  const index = useMenuToolsStore.getState().tools.findIndex((l) => l.visible === true);
  useMenuToolsStore.setState((state) => ({
    tools: state.tools.map((l, i) => (i === index ? { ...l, visible: false } : l)),
  }));
};

export { useMenuToolsStore, visibleTool, unvisibleALLTools };
