import { Close } from '@mui/icons-material';
import React from 'react';
import './style.css';

const MarkerPopup = ({ onClick, children }) => {
  return (
    <>
      <a href="#" className="ol-popup-closer" onClick={onClick}><Close fontSize="small" /></a>
      {children}
    </>
  );
};

export default MarkerPopup;
