import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import MaterialIcon from '../MaterialIcon';
import { useDocumentStore, removeDocument } from '../../stores/document';
import { axios } from '../../lib/axios';

const getPdfFile = async (params) => {
  let queryParams = '';

  if (params) {
    queryParams = Object.keys(params)
      .map((key) => key + '=' + params[key])
      .join('&');
  }
  const data = await axios.get(`/stats-sia/file?${queryParams}`, {
    headers: { Accept: 'application/pdf' },
    responseType: 'arraybuffer',
  });

  return data;
};

const PDFMultiPagesViewer = () => {
  const [pdf, setPdf] = useState();
  const [numPages, setNumPages] = useState(null);
  const [open, setOpen] = useState(false);
  const document = useDocumentStore((state) => state.doc);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleClose = () => {
    setOpen(false);
    removeDocument();
  };

  useEffect(() => {
    if (document && Object.keys(document).length > 0) {
      (async () => {
        const pdfBytes = await getPdfFile({
          ...document,
          swcache: false,
        });

        const file = new Blob([pdfBytes], { type: 'application/pdf;base64' });
        setPdf(file);
        setOpen(true);
      })();
    }
  }, [document]);

  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <MaterialIcon icon="Close" />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Visualiser un document
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Document
            file={pdf}
            loading={<CircularProgress sx={{ mt: 10 }} color="inherit" size={60} />}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page scale={1.6} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </Box>
      </Dialog>
    </>
  );
};

export default PDFMultiPagesViewer;
