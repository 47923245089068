const typeAire = [
  { value: 'B', label: 'Bout de champ' },
  { value: 'E', label: 'Enherbée' },
  { value: 'G', label: 'Hangar homologué CU' },
  { value: 'H', label: 'Hangar' },
  { value: 'M', label: 'Meule' },
  { value: 'S', label: 'Aire stabilisée' },
  { value: '', label: 'Inconnu(e)' },
];

const typeAcces = [
  { value: '1', label: 'Bord de route' },
  { value: '2', label: 'Circuit champ accès tout temps' },
  { value: '3', label: 'Circuit accès difficile' },
  { value: '', label: 'Inconnu(e)' },
];

const typeRoute = [
  { value: '1', label: 'Route hors gel' },
  { value: '2', label: '12 T' },
  { value: '3', label: '7.5 T' },
  { value: '4', label: '3.5 T' },
  { value: '', label: 'Inconnu(e)' },
];

const emplSecu = [
  { value: 'O', label: 'Oui' },
  { value: 'N', label: 'Non' },
  { value: 'I', label: 'Interdit' },
  { value: '', label: 'Inconnu(e)' },
];

const typeVoie = [
  { value: 'P', label: 'Voie parallèle' },
  { value: 'C', label: 'Voie de contournement' },
  { value: 'L', label: 'L de 1/2 tour' },
  { value: 'T', label: 'T de 1/2 tour' },
  { value: '', label: 'Inconnu(e)' },
];

export { typeAire, typeAcces, typeRoute, emplSecu, typeVoie };
