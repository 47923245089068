import React from 'react';
import { nanoid } from 'nanoid';
import {
  setAireStockageApi,
  getInfoAireStockages,
  getAiresStockagesApi,
  updateAireStockageCoordsApi,
} from './api';
import { style } from './style';
import { setVectorLayer } from '../../stores/layers';
import { addNotification } from '../../stores/notifications';
import { addItems, useItemsStore, setItems } from '../../stores/menuItems';
import { useInteractionsStore, addInteraction, removeInteraction } from '../../stores/interactions';
import { getViewZoom, getViewExtent } from '../../stores/view';
import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import CreateForm from './components/CreateForm';
import { typeAire, typeAcces, typeRoute, emplSecu, typeVoie } from './enums';
import { GeoJSON } from 'ol/format';
import {
  updateGeometry as updateGeometryEntryAireStockage,
  createGeometry as createGeometryEntryAireStockage,
} from './bindings/geometry-entry';
import { highlightFeature } from '../../utils/mapView';
import { imageBasePath } from '../../utils/image';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import { isMobile } from 'react-device-detect';
import { toggleSideBar } from '../../stores/sidebar';

const id = nanoid();
const props = {
  title: 'Aire stockage',
  type: 'Vector',
  visible: false,
  color: '#FF5E33', // Pour changer la couleur liée à la feature
};

const show = async (extent) => {
  enableAreaSearch(id, 'Rechercher les aires dans cette zone', show);

  const source = new VectorSource();

  if (getViewZoom() > 12) {
    addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
    const cluster = new Cluster({ source });
    const response = await getAiresStockagesApi({ extent });
    const features = new GeoJSON().readFeatures(response);
    source.addFeatures(features);

    setVectorLayer({ id, style, source: cluster, visible: true });
    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    setVectorLayer({ id, style, source, visible: true });
  }
};

const hide = () => {
  disableAreaSearch(id);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const popup = (feature) => `${feature.codeInsee} ${feature.numeroAire} (${feature.nom})`;

const drawFeature = async ({ feature }) => {
  let coords = feature.getGeometry().getCoordinates();
  const communeInfos = await getInfoAireStockages(coords);
  communeInfos.latitude = coords[0];
  communeInfos.longitude = coords[1];
  const idF = nanoid();
  feature.setId(idF);
  feature.set('codeInsee', communeInfos.codeInsee.substring(0, 5));
  feature.set('numeroAire', 'En cours de création');
  feature.set('nom', 'En cours de création');

  //permet de mettre à jour le formulaire de création
  const items = useItemsStore.getState().items;
  items.props.communeInfos = communeInfos;
  setItems(items);
};

const create = async ({ feature }) => {
  const items = useItemsStore.getState().items;
  //Permet de récupérer les derniéres info du formulaires
  items.props.lastOnSubmit = async (data) => {
    const createGeometryAireStockage = await createGeometryEntryAireStockage({
      data,
      feature,
    });

    const response = await setAireStockageApi(createGeometryAireStockage);
    return response;
  };
  setItems(items);
};

const menuItems = () => [
  {
    key: nanoid(),
    text: 'Créer une aire de stockage',
    icon: 'LocationSearching',
    handleClick: () =>
      addItems({
        component: CreateForm,
        props: {
          onSubmit: () => {
            const items = useItemsStore.getState().items;
            items.props.disabledProps = true;
            setItems(items);
            addNotification({
              message: "Placer l'aire de stockage sur la carte pour la créer",
              variant: 'default',
            });
            //On retourne une promise sans le resolve pour bloqué le ok sur le formulaire et avoir le spinner
            return new Promise(() => {
              const intId = nanoid();
              addInteraction({
                id: intId,
                type: 'Draw',
                layerId: id,
                geomType: 'Point',
                handleDrawend: async (e) => {
                  removeInteraction({ id: intId });
                  const f = e.feature;
                  await drawFeature({ feature: f });
                  return new Promise((resolve, reject) => {
                    const intModifyId = nanoid();
                    const interaction = useInteractionsStore
                      .getState()
                      .interactions.find((item) => item.id === intModifyId);
                    if (!interaction) {
                      const notifActions = [
                        {
                          title: 'Confirmer',
                          handleClick: async () => {
                            removeInteraction({ id: intModifyId });
                            try {
                              addNotification({
                                message: "Création de l'aire de stockage",
                                variant: 'success',
                              });
                              //On ne prend pas le data car les valeurs ne sont pas à jours à ce moment la
                              await create({ feature: f });
                              show(getViewExtent());
                              resolve();
                            } catch (error) {
                              addNotification({
                                message: "Impossible de créer l'aire de stockage",
                                variant: 'error',
                              });
                              reject(error);
                            }
                          },
                        },
                        {
                          title: 'Annuler',
                          handleClick: () => {
                            removeInteraction({ id: intModifyId });
                            show(getViewExtent());
                            resolve();
                          },
                        },
                      ];
                      addNotification({
                        message: "Finaliser la création de l'aire de stockage",
                        variant: 'info',
                        persist: true,
                        actions: notifActions,
                      });
                      addInteraction({
                        id: intModifyId,
                        type: 'Modify',
                        features: new Collection([f]),
                        handleModifyend: async () => {
                          await drawFeature({ feature: f });
                        },
                      });
                    }
                  });
                },
              });
            });
          },
        },
        children: null,
      }),
  },
]; // icons list: https://mui.com/components/material-icons/

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  const aire = typeAire.find((i) => i.value === fProps.type_aire);
  const acces = typeAcces.find((i) => i.value === fProps.type_acces);
  const route = typeRoute.find((i) => i.value === fProps.type_route);
  const voie = typeVoie.find((i) => i.value === fProps.type_voie);
  const secu = emplSecu.find((i) => i.value === fProps.empl_secu);
  const intModifyId = nanoid();

  return {
    itemId: fProps.nanoid,
    titleHeader: fProps.codeInsee.substring(2) + '-' + fProps.numeroAire,
    subHeader: fProps.nom,
    avatar:
      fProps.codeInsee !== undefined ? (
        <img src={imageBasePath() + 'img/stockage/CU_stockage.svg'} />
      ) : null,
    content: [
      'Commune: ' + fProps.nomCommune,
      'Type aire: ' + aire?.label,
      'Type accès: ' + acces?.label,
      'Type route: ' + route?.label,
      "Type voie d'accès: " + voie?.label,
      'Emplacement sécurisé: ' + secu?.label,
      'Ordre: ' + fProps.ordre_enlevt,
    ],
    actions: [
      {
        icon: 'CenterFocusStrong',
        title: 'Centrer',
        handleClick: () => {
          if (isMobile) {
            toggleSideBar(false);
          }
          highlightFeature(id, feature);
        },
      },
      {
        icon: 'EditLocation',
        title: 'Déplacer',
        handleClick: () => {
          return new Promise((resolve, reject) => {
            const interaction = useInteractionsStore
              .getState()
              .interactions.find((item) => item.id === intModifyId);
            if (!interaction) {
              const notifActions = [
                {
                  title: 'Confirmer',
                  handleClick: async () => {
                    removeInteraction({ id: intModifyId });
                    try {
                      await edit(feature);
                      addNotification({
                        message: 'Aire de stockage déplacée',
                        variant: 'success',
                      });
                      show(getViewExtent());
                      resolve();
                    } catch (error) {
                      addNotification({
                        message: "Impossible de déplacer l'aire de stockage",
                        variant: 'error',
                      });
                      reject(error);
                    }
                  },
                },
                {
                  title: 'Annuler',
                  handleClick: () => {
                    removeInteraction({ id: intModifyId });
                    show(getViewExtent());
                    resolve();
                  },
                },
              ];
              addNotification({
                message: "Déplacer l'aire de stockage",
                variant: 'info',
                persist: true,
                actions: notifActions,
              });

              addInteraction({
                id: intModifyId,
                type: 'Modify',
                features: new Collection([feature]),
                handleModifyend: () => {},
              });

              if (isMobile) {
                toggleSideBar(false);
              }
            }
          });
        },
      },
    ],
  };
};

const edit = async (feature) => {
  const updateGeometryAireStockage = await updateGeometryEntryAireStockage({
    feature,
  });
  const response = await updateAireStockageCoordsApi(updateGeometryAireStockage);
  return response;
};

export { id, props, show, hide, popup, mapItem, listItem, menuItems };
