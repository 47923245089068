import { nanoid } from 'nanoid';
import { getPrefs, updatePrefs } from './api';
import { addNotification } from '../../stores/notifications';
import { addToolsItems } from '../../stores/menuToolsItems';
import EditForm from './components/EditForm';
import EditList from './components/EditList';
import { getViewExtent, useViewStore } from '../../stores/view';
import { useLayerStore, setRasterLayer } from '../../stores/layers';
import { getCenter, createEmpty, extend } from 'ol/extent';
import { usePrefTechStore, updatePrefTech } from '../../stores/prefTech';
import * as features from '../index';
import { mapperReadPref, mapperUpdateAllPref, mapperUpdatePref } from './bindings/prefTech';
import { fromExtent as polygonFromExtent } from 'ol/geom/Polygon';
import { useMapStore } from '../../stores/map';
import { visibleTool, unvisibleALLTools } from '../../stores/menuTools';

const id = nanoid();

const props = {
  title: 'Mes préférences',
  type: 'Tool',
  visible: false,
  icon: 'AccountCircle',
};

const init = async () => {
  const response = await getPrefs();
  if (response) {
    updatePrefTech(response);

    await setRaster(response);

    //On init la première préférence
    if (
      response.LONPOINTBASCC1 != '0' ||
      response.LATPOINTBASCC1 != '0' ||
      response.LONPOINTHAUTCC1 != '0' ||
      response.LATPOINTHAUTCC1 != '0'
    ) {
      const firstExtent = new createEmpty();
      extend(firstExtent, [
        parseInt(response.LONPOINTBASCC1),
        parseInt(response.LATPOINTBASCC1),
        parseInt(response.LONPOINTHAUTCC1),
        parseInt(response.LATPOINTHAUTCC1),
      ]);
      setTimeout(() => {
        setCenter(firstExtent);
        setLoadPref(firstExtent, response.SELECTIONCC1);
      });
    } else if (
      response.XPOINTBAS != '0' ||
      response.YPOINTBAS != '0' ||
      response.XPOINTHAUT != '0' ||
      response.YPOINTHAUT != '0'
    ) {
      const extent = new createEmpty();
      extend(extent, [
        parseInt(response.XPOINTBAS),
        parseInt(response.YPOINTBAS),
        parseInt(response.XPOINTHAUT),
        parseInt(response.YPOINTHAUT),
      ]);
      setTimeout(() => {
        setCenter(extent);
      });
    }
  }
};

const show = async () => {
  addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
  const response = usePrefTechStore.getState().prefs;
  const data = mapperReadPref(response);
  visibleTool({ id, data: data, visible: true });
  addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
};

const mapItem = (tool) => {
  tool.nanoid = nanoid();
  return tool;
};

const savePrefs = async (dsinfoprofil) => {
  const response = await updatePrefs({ dsinfoprofil });
  if (response.errormsg !== '') {
    addNotification({ message: props.title + ' - ' + 'Préférence non modifiée', variant: 'error' });
  } else {
    addNotification({ message: props.title + ' - ' + 'Préférence modifiée', variant: 'success' });
    const updatePref = await getPrefs();
    updatePrefTech(updatePref);
  }
  unvisibleALLTools();
};

const menuItems = () => [
  {
    key: nanoid(),
    text: "Mettre à jour l'ordre des préférences",
    icon: 'FormatListBulleted',
    handleClick: () =>
      addToolsItems({
        component: EditList,
        props: {
          onSubmit: async (data) => {
            const oldPref = usePrefTechStore.getState().prefs;
            const newPref = mapperUpdateAllPref(oldPref, data);
            await savePrefs(newPref);
            return Promise.resolve();
          },
          prefTech: mapperReadPref(usePrefTechStore.getState().prefs),
        },
        children: null,
      }),
  },
]; // icons list: https://mui.com/components/material-icons/

const listItem = (tool, index) => {
  const prefTechProps = tool;
  const indexPrefTech = index;
  const actions = [];
  if (prefTechProps.COUCHECARTO !== '') {
    actions.push({
      icon: 'FileDownload',
      title: 'Appliquer',
      handleClick: async () => {
        if (prefTechProps.COUCHECARTO !== '') {
          addNotification({
            message: props.title + ' - ' + 'Préférence en cours de chargement',
            variant: 'default',
          });
          // On init la première préférence
          const extent = new createEmpty();
          extend(extent, [
            parseInt(tool.LONPOINTBASCC),
            parseInt(tool.LATPOINTBASCC),
            parseInt(tool.LONPOINTHAUTCC),
            parseInt(tool.LATPOINTHAUTCC),
          ]);
          await setRaster(tool);
          await setCenter(extent);
          await setLoadPref(extent, tool.SELECTIONCC);
          addNotification({
            message: props.title + ' - ' + 'Préférence chargée',
            variant: 'success',
          });
          unvisibleALLTools();
        } else {
          addNotification({
            message: props.title + ' - ' + 'Préférence non chargée',
            variant: 'error',
          });
          unvisibleALLTools();
        }
      },
    });
  }
  actions.push({
    icon: prefTechProps.COUCHECARTO ? 'Edit' : 'AddCircle',
    title: prefTechProps.COUCHECARTO ? 'Modifier' : 'Ajouter',
    handleClick: async () => {
      addToolsItems({
        component: EditForm,
        props: {
          onSubmit: async (data) => {
            const viewExtent = getViewExtent();

            const state = useLayerStore.getState();
            const rScan = state.raster.find((item) => item.title === 'Scan25tour');
            const rOrtho = state.raster.find((item) => item.title === 'Satellite');
            const r500 = state.raster.find((item) => item.title === 'Hybride');

            const dsinfoprofil = mapperUpdatePref(
              prefTechProps,
              data,
              indexPrefTech,
              viewExtent,
              state,
              rScan,
              rOrtho,
              r500
            );
            await savePrefs(dsinfoprofil);
          },
          prefTechProps: prefTechProps,
        },
        children: null,
      });
    },
  });
  return {
    key: prefTechProps.nanoid,
    titleHeader: prefTechProps.COUCHECARTO ? prefTechProps.COUCHECARTO : 'Préférence non définie',
    subHeader: '',
    content: ['Couche: ' + prefTechProps.SELECTIONCC.split('|').join(', ')],
    actions: actions,
    enableAllActionOnCenter: true,
    enableExpendAction: prefTechProps.COUCHECARTO ? true : false,
  };
};

// Méthode d'interraction avec la carte
const setCenter = async (extent) => {
  //positionnement sur la carte
  const map = useMapStore.getState().map;
  const view = map.getView();

  // update map position by using view.fit() method
  view.fit(polygonFromExtent(extent), {
    size: map.getSize(),
  });

  const zoom = parseInt(view.getZoom()) + 1;
  useViewStore.getState().setViewZoom(zoom);
  useViewStore.getState().setViewCenter(getCenter(extent));
  useViewStore.getState().setViewExtent(extent);
};

const setRaster = async (prefs) => {
  //Permet de changer le raster de la carte
  const state = useLayerStore.getState();
  // Chargement du raster,
  if (prefs.POURROUTE500 === '100') {
    state.raster.forEach((raster) => {
      let r = { ...raster };
      r.visible = r.title === 'Hybride';
      setRasterLayer(r);
    });
  } else if (prefs.POURSCAN25 === '100') {
    state.raster.forEach((raster) => {
      let r = { ...raster };
      r.visible = r.title === 'Scan25tour';
      setRasterLayer(r);
    });
  } else if (prefs.POURPHOTOAE === '100') {
    state.raster.forEach((raster) => {
      let r = { ...raster };
      r.visible = r.title === 'Satellite';
      setRasterLayer(r);
    });
  }
};

const setLoadPref = async (extent, selcc) => {
  //Permet d'initialiser les features à afficher
  const keys = Object.keys(features);
  keys.map(async (key) => {
    if (features[key].props.type === 'Vector' && selcc.includes(features[key].props.title)) {
      await features[key].show(extent);
      return Promise.resolve();
    } else {
      if (features[key].props.type === 'Vector') {
        await features[key].hide();
        return Promise.resolve();
      }
    }
  });
};

export { id, props, init, show, menuItems, mapItem, listItem };
