const FILTER_A_VALIDER = 'a_valider';
const FILTER_VALIDE = 'valide';
const FILTER_REFUSE = 'refuse';

const statutValues = [
  {value: FILTER_A_VALIDER, label: 'En attente de validation'},
  {value: FILTER_VALIDE, label: 'Validé'},
  {value: FILTER_REFUSE, label: 'Refusé'},
];

const getLabelGps = (ravageur) => {
  let gpsState = 'Statut GPS inconnu';
  if(ravageur.gpsok == 'O') gpsState = 'GPS ok';
  if(ravageur.gpsok == 'N') gpsState = 'Pas de GPS';
  if(ravageur.gpsok == 'R') gpsState = 'Position via ERC';
  return gpsState;
}

export { statutValues, FILTER_A_VALIDER, FILTER_VALIDE, FILTER_REFUSE, getLabelGps };
