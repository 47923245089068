import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import FormSelect from '../../../../components/FormSelect';
import FormInputText from '../../../../components/FormInputText';
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import FormInputRadioGroup from '../../../../components/FormInputRadioGroup';
import {
  getPlanteurByNom,
  getPlanteurByCode,
  getCommuneInfosFromXY,
  getParcellesFromSIA,
} from '../../api';
import { typeBio } from '../../enums';

const defaultValues = {
  commune: '',
  codeinsee: '',
  nomplanteur: '',
  codeplanteur: '',
  nomparcelle: '',
  numilot: '',
  culture: '',
  typebio: '',
  surfreelle: '',
  surfcalc: '',
  selparcelle: '',
};

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

const CreateForm = ({ onSubmit, parcelleProps, culturesArray, siaErrors = [] }) => {
  const { centroid = null, area = 0 } = parcelleProps;
  defaultValues.surfcalc = area;

  const methods = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    watch,
    getValues,
    setValue,
    clearErrors,
    setError,
    control,
  } = methods;

  const [commune, setCommune] = useState('');
  const [cultures, setCultures] = useState([]);
  const [parcelles, setParcelles] = useState([]);
  //const [parcellesIndex, setParcellesIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const watchNomPlanteur = watch('nomplanteur');
  const watchCodePlanteur = watch('codeplanteur');

  /*const LoadMoreParcelles = () => {
    setParcellesIndex((prev) => prev + 10);
  };*/

  // init parcelles list, commune name and code insee
  useEffect(() => {
    (async () => {
      if (centroid) {
        setLoading(true);
        const { code, nom } = await getCommuneInfosFromXY(centroid);
        const parcelles = await getParcellesFromSIA({ commune: code });
        const mapParcelles = parcelles.map((e) => ({
          label: `${e.cpcu} - ${e.nomcpcu} - ${e.parcelle} - ${e.nomparcelle}`,
          value: e,
        }));
        setValue('commune', nom, true);
        setValue('codeinsee', code, true);
        setParcelles(mapParcelles);
        setCommune(nom);
        if (area != 0) {
          setValue('surfcalc', area);
        }
        setLoading(false);
      }
    })();
  }, []);

  // init cultures
  useEffect(() => {
    const mapCultures = culturesArray.map((e) => ({
      label: e.libelle,
      value: e,
    }));
    setCultures(mapCultures);
  }, []);

  useEffect(() => {
    const codeplanteurObj = getValues('codeplanteur') || '';
    const codeInsee = getValues('codeinsee');

    if (watchNomPlanteur && watchNomPlanteur.cpcu !== codeplanteurObj.cpcu) {
      // update parcelles list
      (async () => {
        const parcelles = await getParcellesFromSIA({
          commune: codeInsee,
          planteur: watchNomPlanteur.cpcu,
        });
        const mapParcelles = parcelles.map((e) => ({
          label: `${e.cpcu} - ${e.nomcpcu} - ${e.parcelle} - ${e.nomparcelle}`,
          value: e,
        }));
        setParcelles(mapParcelles);
      })();

      setValue('codeplanteur', watchNomPlanteur); // object
    }
  }, [watchNomPlanteur, getValues, setValue]);

  useEffect(() => {
    const nomplanteurObj = getValues('nomplanteur') || '';
    const codeInsee = getValues('codeinsee');

    if (watchCodePlanteur && watchCodePlanteur.cpcu !== nomplanteurObj.cpcu) {
      // update parcelles list
      (async () => {
        const parcelles = await getParcellesFromSIA({
          commune: codeInsee,
          planteur: watchCodePlanteur.cpcu,
        });
        const mapParcelles = parcelles.map((e) => ({
          label: `${e.cpcu} - ${e.nomcpcu} - ${e.parcelle} - ${e.nomparcelle}`,
          value: e,
        }));
        setParcelles(mapParcelles);
      })();

      setValue('nomplanteur', watchCodePlanteur); // object
    }
  }, [watchCodePlanteur, getValues, setValue]);

  useEffect(() => {
    if (siaErrors.length > 0) {
      let message = '';
      siaErrors.map((error) => {
        message += error.message + '\n';
        setError(error.nomzone);
      });
      setError('form', { type: 'custom', message: message });
    }
  }, [setError, siaErrors]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Déclarer une parcelle</Typography>
        <Divider />
        <Typography variant="subtitle1">Emplacement</Typography>
        <FormInputText
          name="commune"
          control={control}
          label="Commune"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
        <FormInputText
          name="codeinsee"
          control={control}
          label="Code insee"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
        <Typography variant="subtitle1">Infos planteur</Typography>
        <FormInputAutocomplete
          name="nomplanteur"
          control={control}
          label="Nom planteur"
          placeholder="Tapez un nom planteur"
          displayProp="nom"
          equalityProp="cpcu"
          source={getPlanteurByNom}
        />
        <FormInputAutocomplete
          name="codeplanteur"
          control={control}
          label="Code planteur"
          placeholder="Tapez un code planteur"
          displayProp="cpcu"
          equalityProp="cpcu"
          source={getPlanteurByCode}
        />
        <Typography variant="subtitle1">Infos parcelle</Typography>
        <FormInputText
          name="nomparcelle"
          control={control}
          label="Nom parcelle"
          inputProps={{ maxLength: 30 }}
        />
        <FormInputText
          name="numilot"
          control={control}
          label="Numéro d'ilot"
          rules={{
            pattern: {
              value: /^[0-9]{3}$/,
              message: 'Veuillez entrer un numéro ilot valide (3 chiffres)',
            },
          }}
        />
        <FormSelect
          name="culture"
          control={control}
          label="Type de culture"
          renderValue={(selected) => `${selected.libelle}`}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {cultures.map((option) => (
            <MenuItem key={nanoid()} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText
          name="surfcalc"
          control={control}
          label="Surface calculée"
          InputProps={{
            readOnly: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
              </React.Fragment>
            ),
          }}
        />
        <FormInputText
          name="surfreelle"
          control={control}
          label="Surface réelle"
          number={true}
          format={(val) => {
            let int = limit(val.substring(0, 3), '999');
            let dec = limit(val.substring(3, 5), '99');

            return (
              (int.length ? int.padEnd(3, '_') : '___') +
              (dec.length ? ',' + dec.padEnd(2, '_') : ',__') +
              'ha'
            );
          }}
          allowLeadingZeros={true}
          allowNegative={false}
        />
        <FormInputRadioGroup name="typebio" control={control} label="Type BIO" options={typeBio} />
        <Typography variant="subtitle1">
          Parcelle(s) existante(s) sur{' '}
          {loading ? <CircularProgress color="inherit" size={20} /> : commune}:
        </Typography>
        <FormSelect
          name="selparcelle"
          control={control}
          label="Parcelles"
          renderValue={(selected) =>
            `${selected.cpcu} - ${selected.nomcpcu} - ${selected.parcelle} - ${selected.nomparcelle}`
          }
          MenuProps={{
            PaperProps: {
              /*onScroll: (e) => {
                if (e.target.scrollTop * 2.5 >= e.target.scrollHeight) {
                  //user is at the end of the list so load more items
                  LoadMoreParcelles();
                }
              },*/
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {
            // TODO consider implements https://mui.com/components/autocomplete/#virtualization
            //parcelles.slice(0, parcellesIndex + 10).map((option) => (
            parcelles.map((option) => (
              <MenuItem key={nanoid()} value={option.value}>
                {option.label}
              </MenuItem>
            ))
          }
        </FormSelect>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const culture = getValues('culture');
            if (culture.natpro === 'C') {
              clearErrors();
              return handleSubmit(onSubmit)();
            } else {
              const codeplanteur = getValues('codeplanteur');
              const nomplanteur = getValues('nomplanteur');
              const nomparcelle = getValues('nomparcelle');
              const selparcelle = getValues('selparcelle');

              if ((codeplanteur && nomplanteur && nomparcelle) !== '' || selparcelle !== '') {
                clearErrors();
                return handleSubmit(onSubmit)();
              } else {
                setError('form', {
                  type: 'manual',
                  message:
                    'Veuillez soit saisir un planteur et un nom de parcelle ou sélectionner une parcelle avant de valider',
                });
              }
            }
          }}
          variant="contained"
          disabled={isSubmitting || loading}
        >
          {isSubmitting || loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Valider la parcelle'
          )}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default CreateForm;
