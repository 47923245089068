import React from 'react';
import { Box, List, Pagination, ListItemButton } from '@mui/material';
import InfosCard from '../../InfosCard';

const LayerListItems = ({
  menuListItems,
  page,
  setPage,
  noOfPages,
  itemsPerPage,
  layerName,
  getColor,
}) => {
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <List component="div">
        {menuListItems.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((item) => {
          return (
            <ListItemButton
              sx={{
                p: 0,
                m: 0,
                cursor: 'auto',
                backgroundColor: 'transparent !important',
                '&:hover': {
                  backgroundColor: 'transparent !important',
                },
              }}
              component="div"
              disableRipple={true}
              disableTouchRipple={true}
              key={item.itemId}
              id={'card-' + item.itemId + '-anchor'}
            >
              <InfosCard
                key={item.itemId}
                layerName={layerName}
                color={getColor.bind(this, item)}
                {...item}
              />
            </ListItemButton>
          );
        })}
      </List>
      {noOfPages > 1 && (
        <Pagination
          sx={{ paddingBottom: '20px' }}
          count={noOfPages}
          page={page}
          onChange={handlePageChange}
          defaultPage={1}
          color="primary"
        />
      )}
    </Box>
  );
};

export default LayerListItems;
