import React from 'react';
import { Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const FormInputCheckBox = ({ name, control, label, rules }) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: props }) => <Checkbox checked={props.value} {...props} />}
        />
      }
      label={label}
    />
  );
};

export default FormInputCheckBox;
