import { axios } from '../../../lib/axios';

const getAireStockages = async ({ extent }) => {
  const { divers } = await axios.get(
    `/divers/airestockage/list?Xmin=${extent[0] || ''}&Ymax=${extent[3] || ''}&Xmax=${
      extent[2] || ''
    }&Ymin=${extent[1] || ''}`
  );
  return divers;
};

const updateAireStockageCoords = async ({ codeInsee, numeroAire, posX, posY }) => {
  const response = await axios.put(
    `/divers/airestockage/position?codeInsee=${codeInsee || ''}&numeroAire=${
      numeroAire || ''
    }&posX=${posX || ''}&posY=${posY || ''}&etiqPosX=0&etiqPosY=0`
  );
  return response;
};

const setAireStockages = async (info) => {
  const bodyFormData = new FormData();

  // TODO consider using this code
  // for (const [key, value] of Object.entries(info)) {
  //   bodyFormData.append(key, value);
  // }

  bodyFormData.append('code_insee', info.codeinsee);
  bodyFormData.append('kms', info.kmsusl);
  bodyFormData.append('empl_securite', info.emplasecu);
  bodyFormData.append('type_voie', info.typeaccesvoie);
  bodyFormData.append('tonnage', info.tonnage);
  bodyFormData.append('ordre_enlev', info.ordreenlev);
  bodyFormData.append('posX', info.latitude);
  bodyFormData.append('posY', info.longitude);
  bodyFormData.append('typeStockage', info.typeaire);
  bodyFormData.append('typeAcces', info.typeacces);
  bodyFormData.append('typeEmplacement', info.typeroute);
  bodyFormData.append('numeroAire', '');
  bodyFormData.append('libelleAire', info.nom);

  const { divers } = await axios.post(`/divers/airestockage`, bodyFormData);
  return divers;
};

const getInfoAireStockages = async (posGeo) => {
  const { divers } = await axios.get(
    `/divers/airestockage/infos?posX=${posGeo[0] || ''}&posY=${posGeo[1] || ''}`
  );
  return divers;
};

const getAiresStockagesApi = async ({ extent }) => {
  const response = await axios.get(`/parcelles-api/aire-stockage?bbox=${extent || ''}`);
  return response;
};

const updateAireStockageCoordsApi = async (airestockage) => {
  const response = await axios.put(`/parcelles-api/aire-stockage`, airestockage);
  return response;
};

const setAireStockageApi = async (airestockage) => {
  const response = await axios.post(`/parcelles-api/aire-stockage`, airestockage);
  return response;
};

export {
  getAireStockages,
  updateAireStockageCoords,
  setAireStockages,
  getInfoAireStockages,
  getAiresStockagesApi,
  updateAireStockageCoordsApi,
  setAireStockageApi,
};
