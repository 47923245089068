import { create } from 'zustand';

const useFunctionRecorderStore = create(() => ({
  functions: [],
}));

const addfunctionRecorder = (functionId, layerId, notifId, functionName, params) => {
  /**
   * Dans un premier temps on cherche si l'id de la fonction ce trouve dans la liste functions
   */
  const index = useFunctionRecorderStore
    .getState()
    .functions.findIndex((fun) => fun.functionId === functionId);

  /**
   * Ici on viens ajouter soit une nouvelle fonction / ou modifier la fonction si déjà présente
   */
  if (index == -1) {
    /**
     * Permet d'ajouter une fonctions dans ce cas, sans supprimer les existente
     */
    useFunctionRecorderStore.setState((state) => ({
      functions: [
        ...state.functions,
        {
          functionId,
          layerId,
          notifId,
          functionName,
          params,
        },
      ],
    }));
  } else {
    /**
     * Permet de modifier une fonction (idNotif et les params)
     */
    useFunctionRecorderStore.setState((state) => ({
      functions: state.functions.map((fun, i) => (i === index ? { ...fun, notifId, params } : fun)),
    }));
  }
};

const getFunctionRecorderById = (functionId) => {
  const functionById = useFunctionRecorderStore
    .getState()
    .functions.filter((fun) => fun.functionId === functionId);
  return functionById;
};

const getFunctionRecorderByLayerId = (layerId) => {
  const functionById = useFunctionRecorderStore
    .getState()
    .functions.filter((fun) => fun.layerId === layerId);
  return functionById;
};

const removeFunctionRecorderById = (functionId) => {
  useFunctionRecorderStore.setState((state) => ({
    functions: state.functions.filter((fun) => fun.functionId !== functionId),
  }));
};

const removeFunctionRecorderByLayerId = (layerId) => {
  useFunctionRecorderStore.setState((state) => ({
    functions: state.functions.filter((fun) => fun.layerId !== layerId),
  }));
};

export {
  useFunctionRecorderStore,
  addfunctionRecorder,
  getFunctionRecorderById,
  getFunctionRecorderByLayerId,
  removeFunctionRecorderById,
  removeFunctionRecorderByLayerId,
};
