import React, { useState, useEffect } from 'react';
import { Drawer, ListItem, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import SideMenuHeader from '../SideMenuHeader';
import SideMenuList from '../SideMenuList';
import { useMenuToolsStore, unvisibleALLTools } from '../../../stores/menuTools';
import { useMenuToolsItemsStore } from '../../../stores/menuToolsItems';
import * as tools from '../../../features/tools';
import can from '../../../utils/duckTyping';
import MaterialIcon from '../../MaterialIcon';
import { isMobile } from 'react-device-detect';

export default function SideMenuLaoyout({ ...props }) {
  let { anchor, showMainMenu, setShowMainMenu, style } = props;
  const [showMenuTools, setMenuShowTools] = useState(true);
  const [showTool, setShowTool] = useState(false);
  const [showToolListItems, setShowToolListItems] = useState(false);
  const [menuTools, setMenuTools] = useState([]);
  const [menuToolsItemsList, setMenuToolsItemsList] = useState([]);
  const stateMenuTools = useMenuToolsStore((state) => state.tools);
  const stateMenuToolsItems = useMenuToolsItemsStore((state) => state.items);

  // hook for trigger if the feature become visible
  useEffect(() => {
    const menuTool = stateMenuTools.filter((o) => (o = o.visible === true));

    if (menuTool.length > 0) {
      menuTool.map((o) => {
        const items = buildMenuTools(o);
        const listItems = buildMenuToolsItemsList(o);
        setMenuTools(items);
        setMenuToolsItemsList(listItems);
      });

      setMenuShowTools(false);
      setShowTool(true);
      setShowToolListItems(true);
    } else {
      setMenuShowTools(true);
      setShowToolListItems(false);
      setShowTool(false);
    }
  }, [stateMenuTools]);

  // setup menu items that come from store
  useEffect(() => {
    if (stateMenuToolsItems && Object.keys(stateMenuToolsItems).length === 3) {
      let children = null;

      if (stateMenuToolsItems.children) {
        children = stateMenuToolsItems.children.map((c) => {
          return React.createElement(c.component, c.props, c.children);
        });
      }

      const component = React.createElement(
        stateMenuToolsItems.component,
        stateMenuToolsItems.props,
        children
      );
      setShowToolListItems(false);
      setMenuTools(component);
    }
  }, [stateMenuToolsItems]);

  const buildMenuTools = (tool) => {
    const keys = Object.keys(tools);
    const key = keys.filter((key) => tools[key].id === tool.id);

    if (can(tools[key], 'menuItems')) {
      const items = tools[key].menuItems();
      return items.map((i) => {
        return (
          <ListItem button key={i.key} onClick={i.handleClick}>
            <ListItemIcon>
              <MaterialIcon icon={i.icon} />
            </ListItemIcon>
            <ListItemText primary={i.text} />
          </ListItem>
        );
      });
    } else {
      return null;
    }
  };

  const buildMenuToolsItemsList = (tool) => {
    const keys = Object.keys(tools);
    const key = keys.filter((key) => tools[key].id === tool.id);
    if (can(tools[key], 'mapItem') && can(tools[key], 'listItem')) {
      const listItems = tool.data
        .map((f) => tools[key].mapItem(f))
        .map((f, i) => {
          const item = tools[key].listItem(f, i);
          return item;
        });
      return listItems;
    } else {
      return null;
    }
  };

  if (!isMobile) {
    return (
      <Drawer
        anchor={anchor}
        open={showMainMenu}
        onClose={() => {
          unvisibleALLTools();
          setShowMainMenu(false);
        }}
        className="toolsDrawer" //Ajout d'une classe pour cacher lors de l'impression
        sx={style}
        PaperProps={{
          sx: {
            zIndex: 3,
            display: 'flex',
            position: 'absolute',
            bottom: `calc(100% - .5em*2 - 2.375em)`,
            width: !isMobile ? `calc(20%)` : `calc(75%)`,
          },
        }}
      >
        <SideMenuHeader setShowMainMenu={setShowMainMenu} />
        <SideMenuList
          stateMenuTools={stateMenuTools}
          showMenuTools={showMenuTools}
          showTool={showTool}
          showToolListItems={showToolListItems}
          menuTools={menuTools}
          menuToolsItemsList={menuToolsItemsList}
        />
      </Drawer>
    );
  } else {
    const drawerBleeding = 45;
    return (
      <SwipeableDrawer
        anchor={anchor}
        open={showMainMenu}
        className="toolsDrawer" //Ajout d'une classe pour cacher lors de l'impression
        onClose={() => {
          unvisibleALLTools();
          setShowMainMenu(false);
        }}
        onOpen={() => {
          setShowMainMenu(true);
        }}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={true}
        PaperProps={{
          sx: {
            zIndex: 3,
            display: 'flex',
            position: 'absolute',
            bottom: `calc(100% - .5em*2 - 2.375em)`,
            width: !isMobile ? `calc(20%)` : `calc(75%)`,
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <SideMenuHeader setShowMainMenu={setShowMainMenu} />
        <SideMenuList
          stateMenuTools={stateMenuTools}
          showMenuTools={showMenuTools}
          showTool={showTool}
          showToolListItems={showToolListItems}
          menuTools={menuTools}
          menuToolsItemsList={menuToolsItemsList}
        />
      </SwipeableDrawer>
    );
  }
}
