import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';

const updateGeometry = async ({ feature }) => {
  const updateFeature = new Feature();
  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  updateFeature.set(
    'codeInsee',
    feature.getProperties().codeInsee + feature.getProperties().lieudit.toString().padStart(2, '0')
  );
  updateFeature.set('numeroAire', feature.getProperties().numeroAire);
  updateFeature.set('posX', feature.getGeometry().getCoordinates()[0]);
  updateFeature.set('posY', feature.getGeometry().getCoordinates()[1]);

  const featureCollection = new GeoJSON().writeFeaturesObject([updateFeature]);

  return featureCollection;
};

const createGeometry = async ({ data, feature }) => {
  const createFeature = new Feature();
  createFeature.setId(feature.getId());
  createFeature.setGeometry(feature.getGeometry());
  createFeature.set('posX', feature.getGeometry().getCoordinates()[0]);
  createFeature.set('posY', feature.getGeometry().getCoordinates()[1]);
  createFeature.set('code_insee', data?.codeinsee);
  createFeature.set('kms', data?.kmsusl);
  createFeature.set('tonnage', data?.tonnage);
  createFeature.set('ordre_enlev', data?.ordreenlev);
  createFeature.set('empl_securite', data?.emplasecu);
  createFeature.set('type_voie', data?.typeaccesvoie);
  createFeature.set('typeStockage', data?.typeaire);
  createFeature.set('typeAcces', data?.typeacces);
  createFeature.set('typeEmplacement', data?.typeroute);
  createFeature.set('numeroAire', '');
  createFeature.set('libelleAire', data?.nom);

  const featureCollection = new GeoJSON().writeFeaturesObject([createFeature]);

  return featureCollection;
};

export { updateGeometry, createGeometry };
