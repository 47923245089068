const isNumber = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

const format = (data, suffix, decimalPos) => {
  var decimalSeparator = ',';
  var thousandSeparator = '.';

  if (!isNumber(data)) {
    return '';
  }

  if (!decimalPos && !suffix) {
    return parseInt(data);
  }

  decimalPos = decimalPos || 0;
  data = parseFloat(data);

  if (data == 0) {
    return '0 ' + suffix;
  }
  data = toDecimalString(data, decimalPos);

  var decimalPlaces = data.substring(data.length - decimalPos);
  var integerPlaces = data.substring(0, data.length - decimalPos - 1); // -1 in order to delete decimalseparator
  integerPlaces = integerPlaces.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator); // add thousandseparator

  var number;

  if (decimalPlaces == '') {
    number = integerPlaces;
  } else {
    number = integerPlaces + decimalSeparator + decimalPlaces; // rebuild the formatted number
  }

  return number + ' ' + suffix;
};

const toDecimalString = (data, decimalPos) => {
  data = data + '';
  var size = data.length;

  //Division
  data = parseInt(data); //Faire attention a ne pas envoye de valeur du type 0xxx (considere comme octal)
  data = data / Math.pow(10, decimalPos);
  data = data.toFixed(decimalPos); // add leading zeros if any

  data = data + '';

  // Cas de division sur nombre rond, alors pas de virgule
  if (data.indexOf('.') == -1) {
    data = data + '.';
    data = data.padEnd(size + 1, '0');
  }

  return data;
};

const between = (value, first, last) => {
  let lower = Math.min(first, last),
    upper = Math.max(first, last);
  return value >= lower && value <= upper;
};

export { format, between, isNumber, toDecimalString };
