import { produce } from 'immer';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useViewStore = create(
  subscribeWithSelector((set) => ({
    zoom: 5, // use to update map zoom in useView hook
    center: [187016.744, 5898926.086], // use to update map center in useView hook
    extent: [-542125.921, 5210540.747, 1037176.3861428804, 6646095.572], // use to update map center in useView hook
    setCenter: (center) => set({ center }),
    setZoom: (zoom) => set({ zoom }),
    setExtent: (extent) => set({ extent }),
    view: {
      extent: [-542125.921, 5210540.747, 1037176.3861428804, 6646095.572],
      center: [187016.744, 5898926.086],
      zoom: 5,
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835, 0.2985821417, 0.1492910709, 0.0746455354,
      ],
    },
    setViewZoom: (zoom) =>
      set(
        produce((state) => {
          state.view.zoom = zoom;
        })
      ),
    setViewCenter: (center) =>
      set(
        produce((state) => {
          state.view.center = center;
        })
      ),
    setViewExtent: (extent) =>
      set(
        produce((state) => {
          state.view.extent = extent;
        })
      ),
  }))
);

const getViewZoom = () => useViewStore.getState().view.zoom;
const getViewCenter = () => useViewStore.getState().view.center;
const getViewExtent = () => useViewStore.getState().view.extent;

export { useViewStore, getViewZoom, getViewCenter, getViewExtent };
