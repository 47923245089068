import { React, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { Button } from '@mui/material';
import {
  useNotificationStore,
  removeNotification,
  clearNotifications,
  closeNotification,
} from '../stores/notifications';
import { useSnackbar } from 'notistack';

const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const stateNotifications = useNotificationStore((state) => state.notifications);
  const stateClearNotifications = useNotificationStore((state) => state.clear);
  const stateCloseNotification = useNotificationStore((state) => state.close);

  useEffect(() => {
    stateNotifications.map((n) => {
      const { id, message, actions, snackbarid, persist, ...rest } = n;

      if(snackbarid) return; // don't add a notification that had already been enqueued

      if (actions) {
        const actionsJsx = (key) => (
          <>
            {actions.map((i) => (
              <Button
                key={nanoid()}
                variant="filled"
                onClick={() => {
                  i.handleClick();
                  closeSnackbar(key);
                }}
              >
                {i.title}
              </Button>
            ))}
          </>
        );

        const snackbarid = enqueueSnackbar(message, {
          ...rest,
          persist: persist,
          action: actionsJsx,
          onClose: (event, reason) => { 
            if(!persist || reason != 'clickaway') {
              removeNotification(id);
            }
          },
        });
        n.snackbarid = snackbarid;
      } else {
        const snackbarid = enqueueSnackbar(message, {
          ...rest,
          persist: persist,
          onClose: (event, reason) => { 
            if(!persist || reason != 'clickaway') {
              removeNotification(id);
            }
          },
        });
        n.snackbarid = snackbarid;
      }
    });
  }, [enqueueSnackbar, closeSnackbar, stateNotifications]);

  useEffect(() => {
    if (stateClearNotifications) {
      closeSnackbar();
      clearNotifications();
    }
  }, [closeSnackbar, stateClearNotifications]);

  useEffect(() => {
    stateCloseNotification.map(notif => {
      closeSnackbar(notif.snackbarid);
    });  
    closeNotification(null);
  }, [closeSnackbar, stateCloseNotification]);

  return null;
};

export default useNotifications;
