import { newParcelleCouvertMellifereToAPI, updateParcelleCouvertMellifereToAPI } from '../api';
import {
  createGeometry as createGeometryCouvertMellifere,
  updateGeometry as updateGeometryCouvertMellifere,
  updateGeometryProperties as updateGeometryPropertiesCouvertMellifere,
} from '../bindings/couvert-mellifere-geometry-entry';
import { natureProduitCode } from '../enums';

let dsculpacCouvertMellifere = [];

const init = (cultures) => {
  if (dsculpacCouvertMellifere.length === 0) {
    dsculpacCouvertMellifere = cultures.filter(
      (c) => c.natpro === natureProduitCode.couvertMellifere
    );
  }
};

const getPopupContent = ({ code_cultu }) => {
  const defaultValue = '';
  const info = dsculpacCouvertMellifere.filter((i) => i.code === code_cultu);

  if (info.length > 0) {
    return info[0].libelle || defaultValue;
  }

  return defaultValue;
};

const getColorCode = (code_cultu) => {
  const defaultValue = '000000';
  const info = dsculpacCouvertMellifere.filter((i) => i.code === code_cultu);

  if (info.length > 0) {
    return '#' + (info[0].couleur || defaultValue);
  }

  return '#' + defaultValue;
};

const newParcelle = async (feature, data) => {
  const createGeometryEntry = await createGeometryCouvertMellifere({
    feature,
    data,
  });
  const response = await newParcelleCouvertMellifereToAPI(createGeometryEntry);
  return response;
};

const updateGeometryParcelle = async (feature) => {
  const updateGeometryEntry = await updateGeometryCouvertMellifere({
    feature,
  });
  const response = await updateParcelleCouvertMellifereToAPI(updateGeometryEntry);
  return response;
};

const updateInfosParcelle = async (feature, data) => {
  const updateGeometryEntry = await updateGeometryPropertiesCouvertMellifere({
    feature,
    data,
  });
  const response = await updateParcelleCouvertMellifereToAPI(updateGeometryEntry);
  return response;
};

const getAnneeSemisFromCulture = (culture) => {
  const digitCode = culture.match(/\d+/g);
  return 2021 + (parseInt(digitCode) || 0);
};

const getAnneeSemisList = () => {
  const startYear = 2021;
  const array = [];
  let i = 0;

  while (i < 10) {
    array.push({ label: startYear + i, value: startYear + i });
    i++;
  }

  return array;
};

export {
  init,
  getPopupContent,
  getColorCode,
  newParcelle,
  updateGeometryParcelle,
  updateInfosParcelle,
  getAnneeSemisFromCulture,
  getAnneeSemisList,
};
