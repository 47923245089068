import { axios } from '../../../lib/axios';

const getCircuitChampApi = async ({ type, silo, grue, extent }) => {
  const response = await axios.get(
    `/parcelles-api/circuit-champ?type=${type || ''}&silo=${silo || ''}&grue=${grue || ''}&bbox=${
      extent || ''
    }`
  );
  return response;
};

const sendCircuitChampApi = async (circuit) => {
  const response = await axios.post('/parcelles-api/circuit-champ', circuit);
  return response;
};

const deleteCircuitChampApi = async (gid) => {
  const response = await axios.delete(`/parcelles-api/circuit-champ/${gid}`);
  return response;
};

const updateCircuitChampApi = async (circuit) => {
  const response = await axios.put('/parcelles-api/circuit-champ', circuit);
  return response;
};

const getGrue = async (usl) => {
  const { livraisonsReceptions } = await axios.get(`/livraisons-receptions/grue?usl=${usl}`);
  return livraisonsReceptions;
};

const getSiloGrue = async (usl, grue) => {
  const { planning } = await axios.get(`/planning-betteraves/silo/grue?usl=${usl}&grue=${grue}`);
  return planning;
};

export {
  getCircuitChampApi,
  sendCircuitChampApi,
  deleteCircuitChampApi,
  updateCircuitChampApi,
  getGrue,
  getSiloGrue,
};
