import { create } from 'zustand';

const useDocumentStore = create(() => ({
  doc: {},
}));

const showDocument = (doc) =>
  useDocumentStore.setState(() => ({
    doc: { ...doc },
  }));

const removeDocument = () =>
  useDocumentStore.setState(() => ({
    doc: null,
  }));

export { useDocumentStore, showDocument, removeDocument };
