import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useTimelineStore = create(
  subscribeWithSelector(() => ({
    visible: false,
    layerIds: [],
    datesForLayerId: {},
    isIntervalForLayerId: {},
    valuesForLayerId: {}
  }))
);

const setTimelineVisible = (visible) => {
  useTimelineStore.setState(() => ({visible}));
}

const showTimeline = (layerId, startDate, endDate, isInterval, initValue) => {
  let layerIds = useTimelineStore.getState().layerIds;
  if (layerIds.indexOf(layerId) < 0) {
    layerIds.push(layerId);
  }

  const datesForLayerId = {...useTimelineStore.getState().datesForLayerId};
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);
  datesForLayerId[layerId] = [startDate, endDate];

  const isIntervalForLayerId = {...useTimelineStore.getState().isIntervalForLayerId};
  isIntervalForLayerId[layerId] = isInterval;

  const valuesForLayerId = {...useTimelineStore.getState().valuesForLayerId};
  valuesForLayerId[layerId] = initValue;

  useTimelineStore.setState(() => ({
    layerIds, 
    datesForLayerId,
    isIntervalForLayerId,
    valuesForLayerId
  }));
}

const removeTimeline = (layerId) => {
  let layerIds = useTimelineStore.getState().layerIds;
  const index = layerIds.indexOf(layerId);
  if (index >= 0) {
    layerIds.splice(index, 1);
  }
  useTimelineStore.setState(() => ({layerIds}));
}

const setTimelineValues = (layerId, values) => {
  const valuesForLayerId = {...useTimelineStore.getState().valuesForLayerId};
  valuesForLayerId[layerId] = values;
  useTimelineStore.setState(() => ({valuesForLayerId}));
}


export { useTimelineStore, setTimelineVisible, showTimeline, removeTimeline, setTimelineValues };