import React from 'react';
import { nanoid } from 'nanoid';
import { getCodifRavageur, getImage, getRavageur, setRavageur } from './api';
import { setVectorLayer, useLayerStore } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { style } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import Cluster from 'ol/source/Cluster';
import { setImageDialog } from '../../stores/imageDialog';
import { addItems } from '../../stores/menuItems';
import SearchForm from './component/SearchForm';
import { isMobile } from 'react-device-detect';
import { Feature } from 'ol';
import Point from 'ol/geom/Point';
import { as400DateTimeToDate, dateToString } from '../../utils/date';
import { fileBufferToBase64 } from '../../utils/image';
import { setWaitDialog } from '../../stores/waitDialog';
import EditForm from './component/EditForm';
import { usePrefTechStore } from '../../stores/prefTech';
import { FILTER_A_VALIDER, FILTER_REFUSE, FILTER_VALIDE, getLabelGps } from './enums';
import snailMatrix from '../../utils/snail';
import { distance } from 'ol/coordinate';
import { toggleSideBar } from '../../stores/sidebar';
import { RavageurIcon } from './icon/ravageurIcon';
import { NotInterested } from '@mui/icons-material';
import { common } from '@mui/material/colors';
import { fitToFeatures, highlightFeature } from '../../utils/mapView';
import { removeTimeline, showTimeline, useTimelineStore } from '../../stores/timeline';
import { format } from 'date-fns';

const id = nanoid();
const props = {
  title: 'CRISTAL Vigie',
  type: 'Vector',
  visible: false,
  color: '#ff0000',// Pour changer la couleur liée à la feature
};

const currentYear = format(new Date(), 'yyyy');
let lastApiResponse = null;
let typeEtchronoRavageurSelected = [];
// eslint-disable-next-line no-unused-vars
let paramsRavageurSelected = null;
let filteredRavageurs = [];
let filteredRavageursByDateInterval = [];
let codifRavageur = [];
let codifObservations = [];
let timelineValuesObserver = null;

const show = async () => {
  const loadNotifId = addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
  try {
    if(codifRavageur.length == 0) {
      codifRavageur = await getCodifRavageur();
      codifRavageur = codifRavageur.sort((a, b) => a.zonetri.localeCompare(b.zonetri));
      codifRavageur.forEach(codif => {
        codif.id = codif.type+'#'+codif.chrono;
      });
    }
  
    await loadAndFilter(true);

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  } finally {
    closeNotification(loadNotifId);
  }
};

const loadAndFilter = async (refreshFromApi, setExtent) => {
  const source = new VectorSource();
  const features = await search(refreshFromApi);
  source.addFeatures(features);
  setVectorLayer({ id, style, source: new Cluster({source}), visible: true });

  if (setExtent) {
    fitToFeatures(features);
  }
};

const search = async (refreshFromApi) => {
  let startDate = new Date(currentYear, 0, 1);
  let endDate = new Date(currentYear, 11, 31);

  if(paramsRavageurSelected?.debut && paramsRavageurSelected?.fin) {
    startDate = new Date(paramsRavageurSelected.debut);
    endDate = new Date(paramsRavageurSelected.fin);
  }

  if (lastApiResponse == null || refreshFromApi) {
    lastApiResponse = await getRavageur(format(startDate, 'yyyyMMdd'), format(endDate, 'yyyyMMdd'));
  }

  codifObservations = lastApiResponse.dsCagCod.sort((a, b) => a.zonetri.localeCompare(b.zonetri));
  codifObservations.forEach(codif => {
    codif.id = codif.type+'#'+codif.chrono;
  });
  const matrix = snailMatrix(lastApiResponse.dsObservations.length, 25);
  
  filteredRavageurs = lastApiResponse.dsObservations
  .sort((a, b) => parseInt(a.longitude ? a.longitude : 0) - parseInt(b.longitude ? b.longitude : 0))
  .map((ravageur, index, arr) => {
    const prevRavageur = arr[index - 1];
    let offset = { moveX: 0, moveY: 0 };

    if (prevRavageur) {
      const distanceBetween = distance([prevRavageur.longitude, prevRavageur.latitude], [ravageur.longitude, ravageur.latitude]);
      if(distanceBetween <= 10) {
        offset = matrix.shift();
      }
    }

    const codif_infos = [];
    let codif_chronos = ravageur.codif.match(/.{1,8}/g);
    let codif_lib = '';
    if(codif_chronos) {
      codif_chronos = codif_chronos.map(chrono => {
        const infoCodif = codifObservations.find(c => c.chrono == chrono && c.type == ravageur.type);
        if(infoCodif) {
          codif_infos.push(infoCodif);
          if(codif_lib.length > 0) codif_lib += ', ';
          codif_lib += infoCodif.lib;
        }
        return ravageur.type+'#'+chrono;
      });
    }
    if(codif_lib.length == 0) codif_lib = 'Non déterminé';

    ravageur.codif_list_infos = codif_infos;
    ravageur.codif_list_typeEtchrono = codif_chronos ? codif_chronos : [];
    ravageur.codif_lib = codif_lib;
    ravageur.dateheurephoto = as400DateTimeToDate(ravageur.datephoto, ravageur.heurephoto);
    ravageur.nanoid = ravageur.chrono;

    if(ravageur.longitude && ravageur.latitude && ravageur.datesup == '00000000' && ravageur.profilsup == '') {
      const feature = new Feature({ 
        geometry: new Point([
          parseInt(ravageur.longitude ? ravageur.longitude : 0) + offset.moveX,
          parseInt(ravageur.latitude ? ravageur.latitude : 0) + offset.moveY,
        ]),
        ...ravageur
      });
      ravageur.feature = feature;
    }

    return ravageur;
  });

  if(typeEtchronoRavageurSelected.length > 0) {
    filteredRavageurs = filteredRavageurs.filter(r => {
      let found = false;
      typeEtchronoRavageurSelected.forEach(type => {
        if (!found && r.codif_list_typeEtchrono.indexOf(type) >= 0) {
          found = true;
        }
      })
      return found;
    });
  }
  if(paramsRavageurSelected?.statut == FILTER_A_VALIDER) {
    filteredRavageurs = filteredRavageurs.filter(r => r.dateval == '00000000' && r.profilval == '' && r.datesup == '00000000' && r.profilsup == '');
  }
  if(paramsRavageurSelected?.statut == FILTER_VALIDE) {
    filteredRavageurs = filteredRavageurs.filter(r => r.dateval != '00000000' && r.profilval != '' && r.datesup == '00000000' && r.profilsup == '');
  }
  if(paramsRavageurSelected?.statut == FILTER_REFUSE) {
    filteredRavageurs = filteredRavageurs.filter(r => r.datesup != '00000000' && r.profilsup != '');
  }
  if(paramsRavageurSelected?.typeEtchrono && paramsRavageurSelected?.typeEtchrono.length > 0) {
    filteredRavageurs = filteredRavageurs.filter(r => r.codif_list_typeEtchrono.filter((typeEtchrono) => paramsRavageurSelected.typeEtchrono.indexOf(typeEtchrono) >= 0).length > 0);
  }
  if(paramsRavageurSelected?.commune) {
    filteredRavageurs = filteredRavageurs.filter(r => r.communeinsee && r.communeinsee.substring(0, 5) == paramsRavageurSelected.commune.codeInsee.substring(0, 5));
  }
  if(paramsRavageurSelected?.planteur) {
    filteredRavageurs = filteredRavageurs.filter(r => r.planteur == paramsRavageurSelected.planteur.cpcu);
  }
  if(paramsRavageurSelected?.usine) {
    filteredRavageurs = filteredRavageurs.filter(r => r.usg == paramsRavageurSelected.usine);
  }

  let startDateMs = null;
  let endDateMs = null;

  filteredRavageursByDateInterval = filteredRavageurs.map(ravageur => {
    if (ravageur.feature != null) {
      startDateMs = startDateMs == null ? ravageur.dateheurephoto.getTime() : Math.min(startDateMs, ravageur.dateheurephoto.getTime());
      endDateMs = endDateMs == null ? ravageur.dateheurephoto.getTime() : Math.max(endDateMs, ravageur.dateheurephoto.getTime());
    }
    return ravageur;
  });

  if (startDateMs == null) {
    startDateMs = startDate.getTime();
  }
  if (endDateMs == null) {
    endDateMs = endDate.getTime();
  }

  showTimeline(id, new Date(startDateMs), new Date(endDateMs), true, [new Date(startDateMs), new Date(endDateMs)]);

  if (!timelineValuesObserver) {
    timelineValuesObserver = useTimelineStore.subscribe(
      state => state.valuesForLayerId,
      valuesForLayerId => {
        if (useLayerStore.getState().activeLayer.get('id') == id) {
          const values = valuesForLayerId[id];
          filterFromTimeline(values[0], values[1]);
        }
      }
    );
  }
  
  return filteredRavageursByDateInterval.filter(r => r.feature != null).map(r => r.feature);
}

const filterFromTimeline = (startDate, endDate) => {
  filteredRavageursByDateInterval = filteredRavageurs.filter(
    (r) =>
      r.dateheurephoto.getTime() >= startDate.getTime() &&
      r.dateheurephoto.getTime() <= endDate.getTime()
  );

  const source = new VectorSource();
  const features = filteredRavageursByDateInterval.filter(r => r.feature != null).map(r => r.feature);
  source.addFeatures(features);
  setVectorLayer({ id, style, source: new Cluster({source}), visible: true });
}

const updateRavageur = async (ravageur, editFormData, valid) => {
  const prefTech = usePrefTechStore.getState().prefs;
  const { LOGIN } = prefTech;

  const type = editFormData.typeEtchrono.map(typeEtChrono => codifRavageur.filter(c => c.id == typeEtChrono)[0].type)[0];
  const codif = editFormData.typeEtchrono.map(typeEtChrono => codifRavageur.filter(c => c.id == typeEtChrono)[0].chrono).join('');

  const data = {
    typemaj: valid ? 'M' : 'S',
    parmwindows: LOGIN,
    parmravage: {
      chrono: ravageur.chrono,
      communeinsee: editFormData.commune ? editFormData.commune.codeInsee : '',
      planteur: editFormData.planteur ? editFormData.planteur.cpcu : '',
      longitude: ravageur.longitude,
      latitude: ravageur.latitude,
      type: type,
      codif: codif,
    }
  };
  await setRavageur(data);
}

const hide = () => {
  removeTimeline(id);
  if (timelineValuesObserver) {
    timelineValuesObserver();
    timelineValuesObserver = null;
  }

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const popup = (feature) => {
  if(feature.dateval == '00000000' && feature.profilval == '' && feature.datesup == '00000000' && feature.profilsup == '') {
    return `Ravageur à valider - ${dateToString(feature.dateheurephoto)}`;
  } else {
    return `${feature.codif_lib} - ${dateToString(feature.dateheurephoto)}`;
  }
}

const sortRavageurs = (ravageurs, usineTechFirst) => {
  const urlParams = new URLSearchParams(window.location.search);
  if(urlParams.get('sort') == 'chrono') { // Hack pour pouvoir trier par chrono pour debug
    return ravageurs.sort((a, b) => b.chrono.localeCompare(a.chrono));
  }

  if(!usineTechFirst) {
    return ravageurs.sort((a, b) => b.dateheurephoto.getTime() - a.dateheurephoto.getTime());
  }

  const prefTech = usePrefTechStore.getState().prefs;
  const { USL } = prefTech;

  return ravageurs.sort((a, b) => {
    const aUSG = a.usg == '0000' ? USL : a.usg;
    const bUSG = b.usg == '0000' ? USL : b.usg;

    // on fait en sorte d'afficher en premier les observations de l'usine du technicien et les observations où l'usine n'a pas été déterminée
    // le tout trié par date/heure
    if(aUSG == USL && bUSG != USL) {
      return -1;
    } else if ((aUSG == USL && bUSG == USL) || (aUSG != USL && bUSG != USL)) {
      return b.dateheurephoto.getTime() - a.dateheurephoto.getTime();
    } else {
      return 1;
    }
  });
}

const itemsList = () => {
  let items = [];
  let ravageursAValider = sortRavageurs(filteredRavageursByDateInterval.filter(r => r.dateval == '00000000' && r.profilval == '' && r.datesup == '00000000' && r.profilsup == ''), true);
  let ravageursValides = sortRavageurs(filteredRavageursByDateInterval.filter(r => r.dateval != '00000000' && r.profilval != '' && r.datesup == '00000000' && r.profilsup == ''), false);
  let ravageursSupprimes = sortRavageurs(filteredRavageursByDateInterval.filter(r => r.datesup != '00000000' && r.profilsup != ''), false);

  items.push({
    section: 'En attente de validation',
    itemId: 'section_ravageur_a_valider',
    open: true,
    showBadge: true,
    badgeColor: '#ff0000',
    childs: ravageursAValider.map(r => {
      return listItem(r, '#ff0000');
    })
  });

  items.push({
    section: 'Validés',
    itemId: 'section_ravageur_valides',
    open: false,
    showBadge: true,
    badgeColor: '#1F8E3F',
    childs: ravageursValides.map(r => {
      return listItem(r, '#1F8E3F');
    })
  });

  items.push({
    section: 'Supprimés',
    itemId: 'section_ravageur_refuses',
    open: false,
    showBadge: true,
    badgeColor: '#000000',
    childs: ravageursSupprimes.map(r => {
      return listItem(r, '#000000');
    })
  });

  return items;
};

const listItem = (ravageur, color) => {
  const actions = [];

  if(ravageur.feature) {
    actions.push({
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        if(isMobile) {
          toggleSideBar(false);
        }
        highlightFeature(id, ravageur.feature);
      },
    });
  }

  actions.push({
    icon: 'CameraAlt',
    title: 'Afficher la photo',
    handleClick: async () => {
      setWaitDialog(true);
      getImage(ravageur)
      .then(imageData => fileBufferToBase64(imageData, ravageur.photo))
      .then(base64 => {
        setImageDialog(true, [{
          src: base64,
          title: dateToString(ravageur.dateheurephoto)
        }]);
      })
      .finally(() => setWaitDialog(false));
    },
  });

  actions.push({
    icon: 'Edit',
    title: 'Modifier',
    handleClick: () => {
      addItems({
        component: EditForm,
        props: {
          onValid: async (data) => {
            await updateRavageur(ravageur, data, true);
            await show();
            return Promise.resolve();
          },
          onRefuse: async (data) => {
            await updateRavageur(ravageur, data, false);
            await show();
            return Promise.resolve();
          },
          ravageur,
          codifRavageur
        },
        children: null,
      })
    },
  });

  return {
    itemId: ravageur.nanoid,
    titleHeader: <div title={'Identifiant observation: '+ravageur.chrono}>{dateToString(ravageur.dateheurephoto)} - {(ravageur.nomusine ? ravageur.nomusine : 'Usine inconnue')}</div>,
    subHeader: (ravageur.nomcommune ? ravageur.nomcommune : 'Commune inconnue')+' - '+getLabelGps(ravageur),
    enableAllActionOnCenter: true,
    enableExpendAction: true,
    showAlwaysContent: false,
    color: color,
    avatar: getIcon(ravageur),
    content: [
      'Date: ' + dateToString(ravageur.dateheurephoto),
      'Usine: ' + (ravageur.nomusine ? ravageur.nomusine : 'inconnue'),
      'Commune: ' + (ravageur.nomcommune ? ravageur.nomcommune : 'inconnue'),
      'Planteur: ' + (ravageur.nomplanteur ? ravageur.nomplanteur + ' (' + ravageur.planteur + ')' : 'inconnu'),
      'Parcelle: ' + (ravageur.nomparcelle ? ravageur.nomparcelle : 'inconnue'),
      'Type: ' + ravageur.codif_lib
    ],
    actions: actions,
  };
};

const getIcon = (ravageur) => {
  if(ravageur.datesup != '00000000' && ravageur.profilsup != '') {
    return (<NotInterested color={common['black']} />)
  }
  if(ravageur.dateval == '00000000' && ravageur.profilval == '') {
    return (<RavageurIcon imageColor="#ff0000" pointColor="" />);
  } else {
    const codifInfos = ravageur.codif_list_infos;
    let imageColor = codifInfos && codifInfos.length > 0 && codifInfos[0].couleur ? '#'+codifInfos[0].couleur : '#000000';
    let pointColor = codifInfos && codifInfos.length > 0 && codifInfos[0].couleurr ? '#'+codifInfos[0].couleurr : '';
    return (<RavageurIcon imageColor={imageColor} pointColor={pointColor} />)
  }
}

const menuItems = () => {
  const actions = [{
    key: nanoid(),
    text: 'Voir toutes les observations ('+currentYear+')',
    icon: 'Search',
    selected: paramsRavageurSelected == null && typeEtchronoRavageurSelected.length == 0,
    handleClick: () => {
      paramsRavageurSelected = null;
      typeEtchronoRavageurSelected = [];
      show();
    }
  },{
    key: nanoid(),
    text: 'Recherche avancée',
    icon: 'Search',
    selected: paramsRavageurSelected != null,
    handleClick: () =>
      addItems({
        component: SearchForm,
        props: {
          onSubmit: async (data) => {
            paramsRavageurSelected = data;
            typeEtchronoRavageurSelected = [];
            await loadAndFilter(true, true);
            return Promise.resolve();
          },
          codifRavageur,
          startDate: new Date(currentYear, 0, 1),
          endDate: new Date(currentYear, 11, 31)
        },
        children: null,
      }),
  }];

  let famillesInfos = [];
  let famillesCodes = [];
  codifObservations.forEach(codif => {
    if (famillesCodes.indexOf(codif.type) == -1) {
      famillesCodes.push(codif.type);
      famillesInfos.push(codif);
    }
  });
  
  famillesInfos.forEach(famille => {

    let childs = [{
      key: nanoid(),
      text: 'Voir toutes les observations ('+famille.libtype+')',
      icon: 'Search',
      handleClick: () =>{
        typeEtchronoRavageurSelected = codifObservations
          .filter(type => type.type == famille.type)
          .map(type => type.id);
        loadAndFilter(false);
      }
    }];

    let nbSelected = 0;

    codifObservations
    .filter(type => type.type == famille.type)
    .forEach(type => {
      let selected = typeEtchronoRavageurSelected.indexOf(type.id) >= 0;
      if (selected) {
        nbSelected++;
      }
      let imageColor = type.couleur ? '#'+type.couleur : '#000000';
      let pointColor = type.couleurr ? '#'+type.couleurr : '';
      const avatar = <RavageurIcon imageColor={imageColor} pointColor={pointColor} />
      childs.push({
        key: nanoid(),
        text: 'Voir les ' + type.lib.toLowerCase(),
        icon: selected ? 'CheckBox' : 'CheckBoxOutlineBlank',
        avatar: avatar,
        selected,
        handleClick: () => {
          if (typeEtchronoRavageurSelected.indexOf(type.id) >= 0) {
            typeEtchronoRavageurSelected.splice(typeEtchronoRavageurSelected.indexOf(type.id), 1);
          } else {
            typeEtchronoRavageurSelected.push(type.id);
          }
          loadAndFilter(false);
        },
      });
    });

    actions.push({
      key: 'famille_'+famille.type,
      text: <span>Filtres {famille.libtype}
        {nbSelected > 0 ? <strong> ({nbSelected} actif{nbSelected > 1 ? 's' : ''})</strong> : ''}</span>,
      open: false,
      childs,
    });
  });

  return actions;
};

export { id, props, show, hide, popup, itemsList, listItem, menuItems };
