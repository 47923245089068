import { create } from 'zustand';
import { nanoid } from 'nanoid';

const useInteractionsStore = create(() => ({
  interactions: [{ id: nanoid(), type: 'DragRotateAndZoom', state: 'ADD' }],
}));

const addInteraction = (interaction) =>
  useInteractionsStore.setState((state) => ({
    interactions: [...state.interactions, { ...interaction, state: 'ADD' }],
  }));

const addedInteraction = (interaction) =>
  useInteractionsStore.setState((state) => ({
    interactions: state.interactions.map((i) =>
      interaction.id === i.id ? { ...i, state: 'ADDED' } : i
    ),
  }));

const setInteraction = (interaction) => {
  const index = useInteractionsStore
    .getState()
    .interactions.findIndex((i) => i.id === interaction.id);

  useInteractionsStore.setState((state) => ({
    interactions: state.interactions.map((item, i) =>
      i === index ? { ...item, ...interaction, state: 'SET' } : item
    ),
  }));
};

const removeInteraction = (interaction) => {
  const index = useInteractionsStore
    .getState()
    .interactions.findIndex((i) => i.id === interaction.id);

  useInteractionsStore.setState((state) => ({
    interactions: state.interactions.map((item, i) =>
      i === index ? { ...item, ...interaction, state: 'REMOVE' } : item
    ),
  }));
};

const removedInteraction = (interaction) =>
  useInteractionsStore.setState((state) => ({
    interactions: state.interactions.filter((item) => item.id !== interaction.id),
  }));

export {
  useInteractionsStore,
  addInteraction,
  addedInteraction,
  setInteraction,
  removeInteraction,
  removedInteraction,
};
