import { create } from 'zustand';

const usePrefTechStore = create(() => ({
  prefs: {},
}));

const updatePrefTech = (prefs) =>
  usePrefTechStore.setState(() => ({
    prefs: { ...prefs },
  }));

export { usePrefTechStore, updatePrefTech };
