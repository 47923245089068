const statutNiv = [
  {
    code: '01',
    text: 'Silo non arraché à niveler mécaniquement',
    src: 'img/nivbach/CU_silo_jaunebarre.svg',
    background: false,
  },
  {
    code: '02',
    text: 'Silo non arraché à ne pas niveler mécaniquement',
    src: 'img/nivbach/CU_silo_rougebarre.svg',
    background: false,
  },
  {
    code: '03',
    text: 'Silo arraché à niveler mécaniquement',
    src: 'img/nivbach/CU_silo_classique.svg',
    background: false,
  },
  {
    code: '04',
    text: 'Silo arraché à ne pas niveler mécaniquement',
    src: 'img/nivbach/CU_silo_classiqueBetterave_interdit.svg',
    background: false,
  },
  {
    code: '05',
    text: 'Silo arraché à niveler mécaniquement + vu par un technicien',
    src: 'img/nivbach/CU_silo_classiqueBetterave_checkRRC.svg',
    background: false,
  },
  {
    code: '06',
    text: 'Silo arraché à ne pas niveler mécaniquement + vu par un technicien',
    src: 'img/nivbach/CU_silo_rouge_check.svg',
    background: false,
  },
  {
    code: '07',
    text: 'Silo arraché à niveler mécaniquement + vu par un technicien mais impossible à faire',
    src: 'img/nivbach/CU_silo_classiqueBetterave_check_interdit.svg',
    background: false,
  },
  {
    code: '08',
    text: 'Silo arraché à niveler mécaniquement mais impossible à faire',
    src: 'img/nivbach/CU_silo_classiqueBetterave_interdit.svg',
    background: false,
  },
  { code: '09', text: 'Silo nivelé', src: 'img/nivbach/CU_silo_classiqueBetterave_nivele.svg', background: true },
  { code: '20', text: 'Silo nivelé et bâché', src: 'img/nivbach/CU_silo_nivele_bache.svg', background: false },
  { code: '21', text: 'Silo débâché', src: 'img/nivbach/CU_silo_debache.svg', background: false },
  { code: '26', text: 'Silo bâché', src: 'img/nivbach/CU_silo_bache.svg', background: true },
];

const statutBach = [
  {
    code: '11',
    text: 'Silo non arraché à bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_jaunebarre.svg',
    background: false,
  },
  {
    code: '12',
    text: 'Silo non arraché à ne pas bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_rougebarre.svg',
    background: false,
  },
  {
    code: '13',
    text: 'Silo arraché à bâcher mécaniquement mais pas encore nivelé',
    src: 'img/nivbach/CU_silo_jaune.svg',
    background: false,
  },
  {
    code: '14',
    text: 'Silo arraché à ne pas bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_rouge.svg',
    background: false,
  },
  {
    code: '15',
    text: 'Silo arraché à bâcher mais pas encore nivelé + vu par un technicien',
    src: 'img/nivbach/CU_silo_jaune_check.svg',
    background: false,
  },
  {
    code: '16',
    text: 'Silo arraché à ne pas bâcher mécaniquement + vu par un technicien',
    src: 'img/nivbach/CU_silo_rouge_check.svg',
    background: false,
  },
  {
    code: '17',
    text: 'Silo nivelé à bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_classiqueBetterave_nivele.svg',
    background: false,
  },
  {
    code: '18',
    text: 'Silo nivelé à ne pas bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_nivele_rouge.svg',
    background: false,
  },
  {
    code: '19',
    text: 'Silo nivelé à bâcher mécaniquement mais impossible à faire',
    src: 'img/nivbach/CU_silo_classiqueBetterave_nivele_interdit.svg',
    background: false,
  },
  { code: '20', text: 'Silo nivelé et bâché', src: 'img/nivbach/CU_silo_nivele_bache.svg', background: false },
  { code: '21', text: 'Silo débâché', src: 'img/nivbach/CU_silo_debache.svg', background: false },
  {
    code: '22',
    text: 'Silo arraché à bâcher mécaniquement vu par un technicien',
    src: 'img/nivbach/CU_silo_classiqueBetterave_checkRRC.svg',
    background: false,
  },
  {
    code: '23',
    text: 'Silo arraché à bâcher mécaniquement',
    src: 'img/nivbach/CU_silo_classique.svg',
    background: false,
  },
  {
    code: '24',
    text: 'Silo arraché à bâcher mécaniquement vu par un technicien mais impossible à faire',
    src: 'img/nivbach/CU_silo_classiqueBetterave_check_interdit.svg',
    background: false,
  },
  {
    code: '25',
    text: 'Silo arraché à bâcher mécaniquement mais impossible à faire',
    src: 'img/nivbach/CU_silo_classiqueBetterave_interdit.svg',
    background: false,
  },
  { code: '26', text: 'Silo bâché', src: 'img/nivbach/CU_silo_bache.svg', background: true },
];

export { statutNiv, statutBach };
