import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const METEO_TYPE_HYGRO = 'hygro';
const METEO_TYPE_PRECIP = 'precip';
const METEO_TYPE_VENT = 'vent';
const METEO_TYPE_TEMP = 'temp';

const useMeteoStore = create(
  subscribeWithSelector(() => ({
    type: METEO_TYPE_PRECIP,
    //date: format(new Date(), 'yyyyMMdd'),
    date: '20230728',
    cumul: false,
    dateDebutCumul: '20230728',
    maxPrecipCumul: 100
  }))
);

const setMeteoType = (type) => {
  useMeteoStore.setState(() => ({ type }));
};

const setMeteoDate = (date) => {
  useMeteoStore.setState(() => ({ date }));
};

const setMeteoCumul = (cumul) => {
  useMeteoStore.setState(() => ({ cumul }));
};

const setMaxPrecipCumul = (maxPrecipCumul) => {
  useMeteoStore.setState(() => ({ maxPrecipCumul }));
};

const setDateDebutCumul = (dateDebutCumul) => {
  useMeteoStore.setState(() => ({ dateDebutCumul }));
};

export {
  useMeteoStore,
  setMeteoType,
  setMeteoDate,
  setMeteoCumul,
  setMaxPrecipCumul,
  setDateDebutCumul,
  METEO_TYPE_HYGRO,
  METEO_TYPE_PRECIP,
  METEO_TYPE_VENT,
  METEO_TYPE_TEMP,
};
