import React from "react";
import { useWaitDialogStore } from "../../stores/waitDialog";
import { Backdrop, CircularProgress } from "@mui/material";

const WaitDialog = () => {
  const stateOpen = useWaitDialogStore((state) => state.open);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={stateOpen}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default WaitDialog;
