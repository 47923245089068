import { Fill, Style, Text, Icon, Stroke } from 'ol/style';
import { imageBasePath } from '../../../utils/image';

const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';

const style = (feature) => {
  // single feature
  let styleUsine = new Style({
    image: new Icon({
      src: imageBasePath() + 'img/usine/CU_usine.svg',
      scale: 1
    }),

    text: new Text({
      text: feature.get('code_usine'),
      fill: new Fill({
        color: '#000000',
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 3
      }),
      offsetY: -24,
      font: font,
    }),
  });
  return styleUsine;
};

export { style };
