import React, { useEffect, useRef, useState } from 'react';
import { useMap, useLayers, useWindowSize } from '../../hooks';
import { isMobile } from 'react-device-detect';
import LayerSwitcher from '../react-geo/LayerSwitcher/LayerSwitcher';
import debounce from '../../utils/debounce';
import 'ol/ol.css';
import './style.css';
import { useMapStore } from '../../stores/map';
import { useCallback } from 'react';
import { theme } from '../../config/theme';

const Map = () => {
  const size = useWindowSize();
  const [style, setStyle] = useState({ height: size.height, width: size.width });
  const layerSwitcherRef = useRef();
  const [popupRef] = useMap();
  const { rasterLayers } = useLayers();
  const map = useMapStore(state => state.map);

  const mapRefCallback = useCallback(ref => {
    if (!map) {
      return;
    }
    if (ref !== null) {
      setTimeout(() => map.setTarget(ref));
    }
  }, [map]);

  useEffect(() => {
    setStyle({ height: size.height, width: size.width });
    const debounced = debounce(() => {
      if (map) {
        map.updateSize();
      }
    }, 500);
    debounced();
  }, [size, map]);

  useEffect(() => {
    if (map && rasterLayers) {
      layerSwitcherRef.current = (
        <LayerSwitcher
          style={{
            position: 'absolute',
            bottom: isMobile ? '23px' : '35px',
            right: isMobile ? '5px' : '50px',
            zIndex: theme.zIndex.drawer - 2,
          }}
          map={map}
          layers={rasterLayers}
        />
      );
    }
  }, [map, rasterLayers]);

  return (
    <>
      <div id="Map" ref={mapRefCallback} style={style}>
        {layerSwitcherRef.current}
      </div>
      <div id="Popup" className="ol-popup" ref={popupRef}></div>
    </>
  );
};

export default Map;
