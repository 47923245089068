import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { id as layerMeteoId } from '../..';
import './style.css';
import { useLayerStore } from '../../../../stores/layers';
import { METEO_TYPE_HYGRO, METEO_TYPE_PRECIP, METEO_TYPE_TEMP, METEO_TYPE_VENT, useMeteoStore } from '../../../../stores/meteo';
import { getScale } from '../../scale';

const Scale = () => {
  const activeLayer = useLayerStore((state) => state.activeLayer);
  const meteoType = useMeteoStore((state) => state.type);
  const meteoCumul = useMeteoStore((state) => state.cumul);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(activeLayer && activeLayer.get('id') == layerMeteoId);
  }, [activeLayer]);

  const buildScale = (meteoType, meteoCumul) => {
    const scale = getScale(meteoType);

    let unit = 'mm';
    if (meteoType == METEO_TYPE_HYGRO) {
      unit = '%';
    }
    if (meteoType == METEO_TYPE_VENT) {
      unit = 'km/h';
    }
    if (meteoType == METEO_TYPE_TEMP) {
      unit = '°C';
    }

    var dom = scale.domain(),
      dmin = Math.min(dom[0], dom[dom.length - 1]),
      dmax = Math.max(dom[dom.length - 1], dom[0]);

    if (meteoType == METEO_TYPE_PRECIP) {
      dmin = 1;
    }

    const items = [
      <div className="weather-scale-unit" key="unit">
        {unit}
      </div>,
    ];
    for (var i = 16; i >= 0; i--) {
      const color = scale(dmin + (i / 16) * (dmax - dmin));
      items.push(
        <div className="weather-scale-step" key={('step'+i)} style={{ backgroundColor: color }}></div>
      );
    }

    if (meteoType == METEO_TYPE_PRECIP && meteoCumul) {
      dmax = useMeteoStore.getState().maxPrecipCumul;
    }

    for (i = 4; i >= 0; i--) {
      const value = Math.round(dmin + (i / 4) * (dmax - dmin));
      let top = '18px';
      if (i == 3) {
        top = '90px';
      }
      if (i == 2) {
        top = '162px';
      }
      if (i == 1) {
        top = '234px';
      }
      if (i == 0) {
        top = '306px';
      }

      items.push(
        <div className="weather-scale-value" key={('value'+i)} style={{ top: top }}>{value}</div>
      );
    }

    return items;
  };

  return (
    <Box
      className="weather-scale"
      sx={{
        position: 'absolute',
        width: '40px',
        height: '324px',
        top: isMobile ? 'calc(50% - 200px)' : 'calc(50% - 170px)',
        left: isMobile ? '5px' : 'unset',
        right: isMobile ? 'unset' : '10px',
        display: !visible ? 'none' : 'block',
      }}
    >
      {buildScale(meteoType, meteoCumul)}
    </Box>
  );
};

export default Scale;
