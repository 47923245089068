import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  Box,
  List,
  Card,
  CardHeader,
  Avatar,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { nanoid } from 'nanoid';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

const EditForm = ({ onSubmit, prefTech }) => {
  const defaultValues = {
    prefTech: [],
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
  } = methods;
  const [dataList, setDataList] = useState([]);

  // hook for give nanoid for render in list
  useEffect(() => {
    if (prefTech.length > 0) {
      prefTech.map((p) => {
        p.key = nanoid();
      });
      setDataList(prefTech);
    }
  }, [prefTech]);

  const handleEnd = (result) => {
    if (!result.destination) return; //if no destination exits(cancel event), exit this function
    const items = Array.from(dataList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDataList(items);
    setValue('prefTech', items); // Important: use replace from useFieldArray instead, update entire field array with setValue will be removed in the next major version.
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <Typography variant="h6">Mettre à jour l&apos;ordre des préférences</Typography>
      <Divider />
      <Box>
        <DragDropContext name="list" onDragEnd={handleEnd}>
          <Droppable droppableId="prefTech-list">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {dataList.map((item, index) => (
                  <Draggable key={item.key} draggableId={item.key} index={index}>
                    {(provided, snapshot) => (
                      <Card
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        key={item.key}
                        className={snapshot.isDragging ? 'selected' : 'not-selected'}
                        sx={{ maxWidth: 420, mt: 1, cursor: 'grab' }}
                      >
                        <CardHeader
                          avatar={<Avatar sx={{ bgcolor: blue[500] }}>{index + 1}</Avatar>}
                          title={item.COUCHECARTO ? item.COUCHECARTO : 'Préférence non définie'}
                        />
                      </Card>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={isSubmitting}>
        {isSubmitting ? <CircularProgress color="inherit" size={20} /> : "MODIFIER L'ORDRE"}
      </Button>
    </Paper>
  );
};

export default EditForm;
