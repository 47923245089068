import React from 'react';
import { nanoid } from 'nanoid';
import {
  getSilosApi,
  getSilosGrueApi,
  getSiloPositionEncodageApi,
  updateSiloCoordsApi,
  updateSiloPropsApi,
  getSiloByEntreprise,
  getCodeInseeFromXY,
} from './api';
import { style, styleEncodage, styleNivBach } from './style';
import { setVectorLayer } from '../../stores/layers';
import { getViewExtent, getViewZoom } from '../../stores/view';
import { addNotification, closeNotification } from '../../stores/notifications';
import { useInteractionsStore, addInteraction, removeInteraction } from '../../stores/interactions';
import { addItems } from '../../stores/menuItems';
import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import { transformExtent } from 'ol/proj';
import SearchForm from './components/SearchForm';
import EditForm from './components/EditForm';
import EncodageCamionForm from './components/EncodageCamionForm';
import NivelageBachageForm from './components/NivelageBachageForm';
import { parse, format } from 'date-fns';
import { format as numberFormat } from '../../utils/number';
import { GeoJSON } from 'ol/format';
import {
  updateGeometryProperties as updateGeometryPropertiesEntrySilos,
  updateGeometry as updateGeometryEntrySilos,
  deleteGeometry as deleteGeometryEntrySilos,
  createSiloLieGeometry as createSiloLieGeometryEntrySilos,
} from './bindings/geometry-entry';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import snail from '../../utils/snail';
import { checkCoordinateinExtent } from '../../utils/postionCheck';
import { fitToFeatures, highlightFeature } from '../../utils/mapView';
import { hideAlertDialog, openAlertDialog } from '../../stores/alertDialog';
import { toWGS84 } from '../../utils/webmercator';
import { setWaitDialog } from '../../stores/waitDialog';
import { statutBach, statutNiv } from './enums';
import { Avatar } from '@mui/material';
import delay from '../../utils/delay';
import { imageBasePath } from '../../utils/image';
import { siloImageSrc } from './icon';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import { isMobile } from 'react-device-detect';
import { toggleSideBar } from '../../stores/sidebar';

const id = nanoid();
const props = {
  title: 'Silo',
  type: 'Vector',
  visible: false,
  color: '#3399CC', // Pour changer la couleur liée à la feature
  colorsilo1: '#ff0000',
  colorsilo2: '#0000ff',
  colorsilo3: '#00ff00',
  colorsilo4: '#cccc00',
  colorsilo5: '#c7006b',
};

let searchData = null;
let isSearchByPlanDeChargement = false;
let currentFeatures = [];
let isNivelageBachage = false;
let isPositionEncodage = false;

const refresh = async () => {
  if (searchData != null) {
    let notif = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'default',
    });
    try {
      await search({ ...searchData, setExtent: false });
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    } finally {
      closeNotification(notif);
    }
  } else {
    await show(getViewExtent());
  }
};

const show = async (extent) => {
  searchData = null;
  isNivelageBachage = false;
  isPositionEncodage = false;

  enableAreaSearch(id, 'Rechercher les silos dans cette zone', show);

  if (getViewZoom() > 13) {
    const loadingNotif = addNotification({
      message: props.title + ' - ' + 'Chargement en cours',
      variant: 'default',
      persist: true,
    });
    try {
      // In order to use IGN Geoserver WFS,
      // we must convert extent from Web Mercator to WGS84 and flip extent Y axis (lon/lat to lat/lon)
      const WGS84Extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
      const flippedExtent = [WGS84Extent[1], WGS84Extent[0], WGS84Extent[3], WGS84Extent[2]];

      await search({ extent: flippedExtent });
      addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    } finally {
      closeNotification(loadingNotif);
    }
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    currentFeatures = [];
    setVectorLayer({ id, style, source: new VectorSource(), visible: true });
  }
};

const hide = () => {
  //searchData = null;
  disableAreaSearch(id);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const search = async (data) => {
  const source = new VectorSource();
  let response = {};

  if (data.usine && data.codegrue) {
    // plan de chargement
    response = await getSilosGrueApi({
      ...data,
    });
    isSearchByPlanDeChargement = true;

    disableAreaSearch(id);
  } else {
    response = await getSilosApi({
      ...data,
      codeplanteur: data.codeplanteur ? data.codeplanteur.padStart(7, '0') : '',
      secteur: data?.secteur?.secteurGrue,
      commune:
        data?.techcommunes?.length > 0
          ? data.techcommunes.map((c) => c.value).join(',')
          : data?.commune?.codeInsee,
      bbox: data?.extent,
      passage: data.passage && data.passage.length > 0 ? data.passage.join(',') : '',
    });
    isSearchByPlanDeChargement = false;
    if (searchData) {
      disableAreaSearch(id);
    }
  }

  let features = new GeoJSON().readFeatures(response);

  if (isSearchByPlanDeChargement) {
    features.sort((a, b) => parseInt(a.get('ordre')) - parseInt(b.get('ordre')));
  }

  currentFeatures = features;
  source.addFeatures(features);

  const cluster = new Cluster({
    source,
  });

  if (data.setExtent) {
    if (source.getFeatures().length > 0) {
      fitToFeatures(source.getFeatures());
    }
    addNotification({ message: props.title + ' - ' + 'Recherche terminée', variant: 'success' });
  }
  setVectorLayer({ id, style, source: cluster, visible: true });
};

const editSiloCoords = async (feature, updateKms) => {
  const updateGeometry = updateGeometryEntrySilos({ feature, updateKms });

  const saveNotif = addNotification({
    message: props.title + ' - ' + 'Sauvegarde de la position',
    variant: 'default',
    persist: true,
  });
  try {
    await updateSiloCoordsApi(updateGeometry);
    await delay(1000 * 2); // délai pour laisser le temps à la DATQ de traiter les modifs de plans de chargement
  } finally {
    closeNotification(saveNotif);
  }
};

const edit = async (feature, data) => {
  if (data) {
    const updateGeometryPropertiesSilos = updateGeometryPropertiesEntrySilos({
      feature,
      data,
    });
    await updateSiloPropsApi(updateGeometryPropertiesSilos);
  } else {
    // Est-ce que le silo a changé de commune ?
    const centroid = toWGS84(
      feature.getGeometry().getCoordinates()[0],
      feature.getGeometry().getCoordinates()[1]
    );
    const { codeInsee, nomCommune } = await getCodeInseeFromXY(centroid);

    // Non c'est toujours la même commune
    if (codeInsee == feature.get('comEmps')) {
      await editSiloCoords(feature, false);
      return;
    }

    // La commune a changé, on demande si il faut conserver les kms de l'ancienne commune ou prendre ceux de la nouvelle
    return new Promise((resolve) => {
      openAlertDialog(
        'Votre silo est placé entre 2 communes',
        'Souhaitez-vous prendre les kms de la commune ' +
          nomCommune +
          ' ou conserver les kms de la commune ' +
          feature.get('nomCommune') +
          ' ?',
        [
          {
            title: 'MODIFIER',
            handler: async () => {
              hideAlertDialog();
              await editSiloCoords(feature, true);
              resolve();
            },
          },
          {
            title: 'CONSERVER',
            handler: async () => {
              hideAlertDialog();
              await editSiloCoords(feature, false);
              resolve();
            },
          },
        ]
      );
    });
  }
};

const positionEncodage = async ({ usine, siloId, silocolor = '' }) => {
  const response = await getSiloPositionEncodageApi({ usine, siloId });
  const features = new GeoJSON().readFeatures(response);

  if (silocolor != '') {
    features.map((f) => f.set('encodagecolor', silocolor));
    return features;
  } else {
    await displayPositionEncodage(features);
  }
};

const displayPositionEncodage = async (features) => {
  isPositionEncodage = true;

  const source = new VectorSource();
  const cluster = new Cluster({
    source,
  });

  currentFeatures = features;
  source.addFeatures(features);
  if (source.getFeatures().length > 0) {
    fitToFeatures(source.getFeatures());
    addNotification({
      message: props.title + ' - ' + 'Recherche encodage terminée',
      variant: 'success',
    });
  } else {
    addNotification({
      message: props.title + ' - ' + "Pas d'encodage trouvé",
      variant: 'warning',
    });
  }

  setVectorLayer({ id, style: styleEncodage, source: cluster, visible: true });
};

const displayNivBach = async (data, setExtent) => {
  addNotification({
    message: props.title + ' - ' + 'Recherche Nivelage/Bachâge en cours',
    variant: 'default',
  });
  // const source = new VectorSource();
  let type;
  let nomEnt;
  let filtres = [];
  //let typeLabel;

  if (data.bachage != '') {
    type = 'B';
    nomEnt = data.bachage;
    filtres = data.statutBach;
    disableAreaSearch(id);
    //typeLabel = 'bachâge';
  } else if (data.nivelage != '') {
    type = 'N';
    nomEnt = data.nivelage;
    filtres = data.statutNiv;
    disableAreaSearch(id);
    //typeLabel = 'nivelage';
  }

  let { dssilos } = await getSiloByEntreprise(nomEnt, type);
  if (filtres.length > 0) {
    dssilos = dssilos.filter((silo) => filtres.indexOf(silo.statut) != -1);
  }
  let allFeatures = [];
  let matrix = null;
  dssilos
    .sort((a, b) => {
      return parseInt(a.gpslo) - parseInt(b.gpslo); // order items by gpslo
    })
    .map((item, index, arr) => {
      const prevItem = arr[index - 1];
      let offset = { moveX: 0, moveY: 0 };
      if (prevItem && parseInt(item.gpslo) === parseInt(prevItem.gpslo)) {
        if (matrix == null) {
          matrix = snail(arr.length, 100);
        }
        offset = matrix.shift();
      } else {
        matrix = null;
      }

      const f = new Feature({
        geometry: new Point([
          parseInt(item.gpslo) + offset.moveX,
          parseInt(item.gpsla) + offset.moveY,
        ]),
        ...item,
        nivbach: true,
      });

      // silo doublon
      if (parseInt(item.longitudesilo) !== 0 && parseInt(item.latitudesilo) !== 0) {
        const { longitudesilo, latitudesilo, ...rest } = item;
        const fDoublon = new Feature({
          ...rest,
          geometry: new Point([parseInt(longitudesilo), parseInt(latitudesilo)]),
          longitudesilo: 0,
          latitudesilo: 0,
          nivbach: true,
          doublon: true,
        });
        allFeatures.push(fDoublon);
      }
      allFeatures.push(f);
    });

  if (setExtent) {
    allFeatures = allFeatures.filter((feature) => {
      if (checkCoordinateinExtent(getViewExtent(), feature.getGeometry().getCoordinates())) {
        return feature;
      }
    });
  }

  isNivelageBachage = true;

  const source = new VectorSource();
  const cluster = new Cluster({
    source,
  });
  currentFeatures = allFeatures;
  source.addFeatures(allFeatures);

  if (source.getFeatures().length > 0) {
    fitToFeatures(source.getFeatures());
  }

  addNotification({
    message: props.title + ' - ' + 'Recherche Nivelage/Bachâge terminée',
    variant: 'success',
  });
  setVectorLayer({ id, style: styleNivBach, source: cluster, visible: true });
};

const popup = (props) => {
  if (props.encodage) {
    return `${props.numtransaction}`;
  } else if (props.nivbach) {
    return (
      <>
        <div>{props.cpcu}</div>
        <div>N° Silo: {props.silo}</div>
      </>
    );
  } else {
    return (
      <>
        <div>{props.codePlanteur}</div>
        <div>
          N° Silo: <strong>{props.id}</strong>
        </div>
        {props.ordre && parseInt(props.ordre) > 0 && (
          <div>
            N° Ordre: <strong>{parseInt(props.ordre)}</strong>
          </div>
        )}
      </>
    );
  }
};

const menuItems = () => [
  {
    key: nanoid(),
    text: 'Voir tous les silos',
    icon: 'Search',
    selected: searchData == null && !isNivelageBachage && !isPositionEncodage,
    handleClick: async () => show(getViewExtent()),
  },
  {
    key: nanoid(),
    text: 'Recherche avancée',
    icon: 'Search',
    selected: searchData != null,
    handleClick: async () => {
      addItems({
        component: SearchForm,
        props: {
          onSubmit: async (data) => {
            let notif = addNotification({
              message: props.title + ' - ' + 'Recherche en cours',
              variant: 'default',
            });
            try {
              searchData = data;
              await search(data);
            } finally {
              closeNotification(notif);
            }
            return Promise.resolve();
          },
        },
        children: null,
      });
    },
  },
  {
    key: nanoid(),
    text: 'Position encodage camion au silo',
    icon: 'Search',
    selected: isPositionEncodage,
    handleClick: () => {
      addItems({
        component: EncodageCamionForm,
        props: {
          onSubmit: async (data) => {
            addNotification({
              message: props.title + ' - ' + 'Recherche Encodage camion en cours',
              variant: 'default',
            });
            let allFeatures = [];

            if (data.codesilo1 != '') {
              const feature = await positionEncodage({
                usine: data.usine,
                siloId: data.codesilo1,
                silocolor: props.colorsilo1,
              });
              allFeatures.push(...feature);
            }
            if (data.codesilo2 != '') {
              const feature = await positionEncodage({
                usine: data.usine,
                siloId: data.codesilo2,
                silocolor: props.colorsilo2,
              });
              allFeatures.push(...feature);
            }
            if (data.codesilo3 != '') {
              const feature = await positionEncodage({
                usine: data.usine,
                siloId: data.codesilo3,
                silocolor: props.colorsilo3,
              });
              allFeatures.push(...feature);
            }
            if (data.codesilo4 != '') {
              const feature = await positionEncodage({
                usine: data.usine,
                siloId: data.codesilo4,
                silocolor: props.colorsilo4,
              });
              allFeatures.push(...feature);
            }
            if (data.codesilo5 != '') {
              const feature = await positionEncodage({
                usine: data.usine,
                siloId: data.codesilo5,
                silocolor: props.colorsilo5,
              });
              allFeatures.push(...feature);
            }
            allFeatures.map((feature, index) => feature.setId(index)); //Permet de changer les id des features pour pouvoir toutes les affiches
            await displayPositionEncodage(allFeatures);

            return Promise.resolve();
          },
        },
        children: null,
      });
    },
  },
  {
    key: nanoid(),
    text: 'Nivelage / Bachage',
    icon: 'Search',
    selected: isNivelageBachage,
    handleClick: () =>
      addItems({
        component: NivelageBachageForm,
        props: {
          onSubmit: async (data) => {
            await displayNivBach(data, false);
          },
        },
        children: null,
      }),
  },
]; // icons list: https://mui.com/components/material-icons/

const mapItem = (feature) => {
  // add, update or remove feature's properties here
  // in order to use them in listItem()
  // feature.set('mynewattribute', 'whatever');

  feature.set('nanoid', nanoid(), true);
  return feature;
};

const itemsList = () => {
  return currentFeatures.map((feature) => {
    mapItem(feature);
    return listItem(feature);
  });
};

const listItem = (feature) => {
  // id prop is the numsilo
  // use nanoid prop for an id
  const fProps = feature.getProperties();
  const intModifyId = nanoid();
  // Center action must be the first declared
  // in order to be always shown on items
  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        if (isMobile) {
          toggleSideBar(false);
        }
        highlightFeature(id, feature);
      },
    },
  ];

  if (!fProps.nivbach && !fProps.encodage) {
    actions.push({
      icon: 'EditLocation',
      title: 'Déplacer',
      handleClick: () => {
        return new Promise((resolve, reject) => {
          const interaction = useInteractionsStore
            .getState()
            .interactions.find((item) => item.id === intModifyId);
          if (!interaction) {
            const notifActions = [
              {
                title: 'Confirmer',
                handleClick: async () => {
                  removeInteraction({ id: intModifyId });
                  try {
                    await edit(feature);
                    addNotification({
                      message: 'Silo déplacé',
                      variant: 'success',
                    });
                    refresh();
                    resolve();
                  } catch (error) {
                    addNotification({
                      message: 'Impossible de déplacer le silo',
                      variant: 'error',
                    });
                    reject(error);
                  }
                },
              },
              {
                title: 'Annuler',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  refresh();
                  resolve();
                },
              },
            ];

            addNotification({
              message: 'Déplacer le silo',
              variant: 'info',
              persist: true,
              actions: notifActions,
            });

            addInteraction({
              id: intModifyId,
              type: 'Modify',
              features: new Collection([feature]),
              handleModifyend: () => {},
            });

            if (isMobile) {
              toggleSideBar(false);
            }
          }
        });
      },
    });
  }

  if (!fProps.doublon && !fProps.nivbach && !fProps.encodage) {
    actions.push({
      icon: 'Edit',
      title: 'Modifier',
      handleClick: async () => {
        let siloModif = {};
        if (isSearchByPlanDeChargement) {
          try {
            setWaitDialog(true);
            const silos = await getSilosApi({ codesilo: fProps.id });
            const features = new GeoJSON().readFeatures(silos);
            if (features.length > 0) {
              siloModif = features[0].getProperties();
            }
          } catch (error) {
            addNotification({
              message: 'Impossible de récupérer les informations du silo',
              variant: 'error',
            });
          } finally {
            setWaitDialog(false);
          }
        } else {
          siloModif = fProps;
        }
        if (Object.keys(siloModif).length > 0) {
          return new Promise((resolve, reject) => {
            addItems({
              component: EditForm,
              props: {
                onSubmit: async (data) => {
                  try {
                    await edit(feature, data);
                    addNotification({
                      message: 'Infos du silo modifiées',
                      variant: 'success',
                    });
                    refresh();
                    resolve();
                  } catch (err) {
                    addNotification({
                      message: 'Impossible de modifier les infos du silo',
                      variant: 'error',
                    });
                    reject(err);
                  }
                },
                siloProps: siloModif,
              },
              children: null,
            });
          });
        } else {
          addNotification({
            message: 'Impossible de modifier le silo',
            variant: 'error',
          });
        }
      },
    });
  }

  if (fProps.etatSilo === 'C' && !fProps.nivbach && !fProps.encodage) {
    actions.push({
      icon: 'ShareLocation',
      title: 'Encodage position',
      handleClick: async () => {
        await positionEncodage({ usine: fProps.usl, siloId: fProps.id });
      },
    });
  }

  if (fProps.doublon && !fProps.nivbach && !fProps.encodage) {
    actions.push({
      icon: 'Delete',
      title: 'Supprimer',
      handleClick: async () => {
        try {
          const deleteGeometry = await deleteGeometryEntrySilos({
            feature,
          });
          await updateSiloCoordsApi(deleteGeometry);
          addNotification({
            message: 'Silo lié supprimé',
            variant: 'success',
          });
          refresh();
        } catch (error) {
          addNotification({
            message: 'Impossible de supprimer le silo lié',
            variant: 'error',
          });
        }
      },
    });
  }

  if (!fProps.doublon && !fProps.nivbach && !fProps.encodage) {
    const intId = nanoid();
    actions.push({
      icon: 'AddCircle',
      title: 'Positionner un silo lié',
      handleClick: () => {
        addNotification({
          message: 'Placer le silo lié sur la carte pour finaliser la création',
          variant: 'default',
        });
        const interaction = useInteractionsStore
          .getState()
          .interactions.find((item) => item.id === intId);
        if (!interaction) {
          return new Promise((resolve, reject) => {
            addInteraction({
              id: intId,
              type: 'Draw',
              geomType: 'Point',
              layerId: id,
              handleDrawend: async (e) => {
                removeInteraction({ id: intId });

                const f = e.feature;
                // eslint-disable-next-line no-unused-vars
                const { geometry, nanoid, posX, posY, ...rest } = fProps;

                // setup feature doublon properties
                f.setProperties({
                  ...rest,
                  doublon: true,
                  posX: parseInt(f.getGeometry().getCoordinates()[0]),
                  posY: parseInt(f.getGeometry().getCoordinates()[1]),
                  etiqPosX: 0,
                  etiqPosY: 0,
                });

                // update feature properties for the doublon
                feature.setProperties({
                  etiqPosX: parseInt(f.getGeometry().getCoordinates()[0]),
                  etiqPosY: parseInt(f.getGeometry().getCoordinates()[1]),
                });

                const createGeometry = await createSiloLieGeometryEntrySilos({
                  f,
                  fProps,
                });
                try {
                  await updateSiloCoordsApi(createGeometry);
                  addNotification({
                    message: 'Silo lié créé',
                    variant: 'success',
                  });
                  refresh();
                  resolve();
                } catch (error) {
                  addNotification({
                    message: 'Impossible de créer le silo lié',
                    variant: 'error',
                  });
                  refresh();
                  reject(error);
                }
              },
            });

            if (isMobile) {
              toggleSideBar(false);
            }
          });
        }
      },
    });
  }
  if (fProps.nivbach) {
    const mapIcon = [...statutBach, ...statutNiv];
    const imageSrc = mapIcon.find((i) => i.code === feature.get('statut'));
    return {
      itemId: fProps.nanoid,
      titleHeader: fProps.cpcu + ' ' + fProps.nomcpcu,
      subHeader: 'N° Silo : ' + fProps.silo,
      avatar: imageSrc ? <Avatar alt={imageSrc.text} src={imageBasePath() + imageSrc.src} /> : null,
      content: [
        fProps.usl ? 'Usine: ' + fProps.usl : '',
        fProps.nomcommune ? 'Nom commune: ' + fProps.nomcommune : '',
        fProps.nomparc ? 'Nom de la parcelle: ' + fProps.nomparc : '',
        fProps.silo
          ? 'Passage: ' + fProps.silo.substring(fProps.silo.length - 2, fProps.silo.length - 1)
          : '',
        fProps.surfp
          ? 'Surface prévue: ' +
            numberFormat(fProps.surfp, fProps.surfp_u, fProps.surfp_f.split(',')[1])
          : '',

        fProps.surfp
          ? 'Tonnage utile prévu: ' +
            numberFormat(fProps.tonp, fProps.tonp_u, fProps.tonp_f.split(',')[1])
          : '',
        fProps.statut ? 'Statut: ' + imageSrc.text : '',
      ],
      actions,
    };
  }
  if (fProps.encodage) {
    return {
      itemId: fProps.nanoid,
      avatar: <Avatar src={siloImageSrc([feature])} />,
      titleHeader:
        format(parse(fProps.htg, 'HHmmss', new Date()), 'HH:mm:ss') +
        ' - ' +
        fProps.ordrechargement,
      subHeader: fProps.numtransaction,
      content: [
        fProps.numerosilo ? fProps.numerosilo : '',
        fProps.codegrue ? 'Grue: ' + fProps.codegrue : '',
        fProps.cpcu || fProps.nomcpcu ? 'Planteur: ' + fProps.cpcu + ' ' + fProps.nomcpcu : '',
        fProps.nomcommune ? 'Commune: ' + fProps.nomcommune : '',
        fProps.nomparcelle ? 'Parcelle: ' + fProps.nomparcelle : '',
        fProps.nbtransactions
          ? 'Nb transactions (en cours): ' + fProps.nbtransactions
          : '' + fProps.encours
          ? ' (' + fProps.encours + ')'
          : '',
        fProps.numtransaction ? 'Num. transation: ' + fProps.numtransaction : '',
        fProps.ordrechargement ? 'Ordre chargement: ' + fProps.ordrechargement : '',
        fProps.numcamion ? 'Num camion: ' + fProps.numcamion : '',
        fProps.dtg
          ? 'Date: ' + format(parse(fProps.dtg, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')
          : '' + ' ' + fProps.htg
          ? format(parse(fProps.htg, 'HHmmss', new Date()), 'HH:mm:ss')
          : '',
        'Fin du silo: ' + (fProps.findesilo !== '' ? 'Oui' : 'Non'),
        'Mixte: ' + (fProps.mixte !== '' ? 'Oui' : 'Non'),
        fProps.poidsdumixte
          ? 'Poids du mixte: ' +
            numberFormat(
              fProps.poidsdumixte,
              fProps.poidsdumixte_u,
              fProps.poidsdumixte_f.split(',')[1]
            )
          : '',
        'Déterrage: ' + (fProps.deterrage !== '' ? 'Oui' : 'Non'),
      ],
      actions,
    };
  }
  return {
    itemId: fProps.nanoid,
    titleHeader: fProps.codePlanteur,
    subHeader:
      'N° Silo : ' +
      fProps.id +
      (fProps.ordre && parseInt(fProps.ordre) > 0 ? ' - N° Ordre: ' + parseInt(fProps.ordre) : ''),
    avatar: <Avatar src={siloImageSrc([feature])} />,
    content: [
      fProps.ordre && parseInt(fProps.ordre) > 0 ? 'N° Ordre: ' + parseInt(fProps.ordre) : '',
      fProps.siloencours == 'O' ? 'Statut: silo en cours' : '',
      fProps.siprochainsilo == 'O' ? 'Statut: prochain silo' : '',
      fProps.usl ? 'Usine: ' + fProps.usl : '',
      fProps.nomParcelle ? 'Parcelle: ' + fProps.nomParcelle : '',
      fProps.libEmps ? 'Emplacement: ' + fProps.libEmps : '',
      fProps.id
        ? 'Passage: ' + fProps.id.substring(fProps.id.length - 2, fProps.id.length - 1)
        : '',
      fProps.surfacePrevue ? 'Surface prévue: ' + fProps.surfacePrevue : '',
      fProps.tonnageUtilePrevu ? 'Tonnage utile prévu: ' + fProps.tonnageUtilePrevu : '',
      fProps.typeNivelage ? 'Type de nivelage: ' + fProps.typeNivelage : '',
      fProps.typeBachage ? 'Type de bachage: ' + fProps.typeBachage : '',
      'Vu par un technicien: ' + (fProps.vuParTechnicien === 'O' ? 'Oui' : 'Non'),
      fProps.codesilomaitre ? 'Code silo maitre: ' + fProps.codesilomaitre : '',
    ],
    actions,
  };
};

export { id, props, show, hide, popup, mapItem, itemsList, menuItems };
