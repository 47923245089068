import Axios from 'axios';
import { CRISTAL_CLOUD_URL, GEO_API_GOUV_URL, WXS_IGN_URL, UID, BEARER, BASIC } from '../config';
import { addNotification } from '../stores/notifications';
import storage from '../utils/storage';

const authRequestInterceptor = async (config) => {
  let token = null;

  if (config.headers.authorization === 'Basic') {
    token = { token_type: 'Basic', access_token: BASIC };
    config.headers.authorization = `${token.token_type} ${token.access_token}`;
  } else {
    if (process.env.NODE_ENV === 'development' || BEARER) {
      token = { token_type: 'bearer', access_token: BEARER };
    } else {
      token = await storage.getToken();
    }

    if (token) {
      config.headers.authorization = `${token.token_type} ${token.access_token}`;
    } else {
      window.location.href = `${CRISTAL_CLOUD_URL}login-sap/?uid=${UID}`;
    }
  }

  if(!config.returnsFile) {
    config.headers.Accept = config.headers.Accept || 'application/json';
  }
  
  return config;
};

const axios = Axios.create({
  baseURL: CRISTAL_CLOUD_URL,
});

const geoApiGouv = Axios.create({
  baseURL: GEO_API_GOUV_URL,
});

const wxsIgn = Axios.create({
  baseURL: WXS_IGN_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    if (response.data.error_description || response.data.errormsg) {
      const message = response.data.error_description || response.data.errormsg;
      addNotification({
        variant: 'error',
        message,
      });

      return Promise.reject(message);
    }

    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = `${CRISTAL_CLOUD_URL}login-sap/?uid=${UID}`;
    } else {
      const message = error.response?.data?.message || error.message;
      addNotification({
        variant: 'error',
        message,
      });

      return Promise.reject(error);
    }
  }
);

geoApiGouv.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    addNotification({
      variant: 'error',
      message,
    });

    return Promise.reject(error);
  }
);

wxsIgn.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    addNotification({
      variant: 'error',
      message,
    });

    return Promise.reject(error);
  }
);

export { axios, geoApiGouv, wxsIgn };
