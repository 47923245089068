import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { format } from 'date-fns';

const createGeometry = async ({ feature, data }) => {
  const insertFeature = new Feature();

  insertFeature.setGeometry(feature.getGeometry());
  insertFeature.set('type_circuit', data.typecircuit ? data.typecircuit : null);
  insertFeature.set('code_silo', data.silo ? data.silo : null);
  insertFeature.set('code_grue', data.grue ? data.grue : null);
  insertFeature.set('end_display', data.enddisplay ? format(data.enddisplay, 'yyyy-MM-dd') : null);
  insertFeature.set('extras', data.extras ? data.extras : null);
  insertFeature.set('supprimable_grue', data.supprimable ? 'N' : 'O');

  // create feature collection GeoJSON
  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    insertFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometry = async ({ feature }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

const updateGeometryProperties = async ({ feature, data }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());
  updateFeature.set('type_circuit', data.typecircuit ? data.typecircuit : null);
  updateFeature.set('code_silo', data.silo ? data.silo : null);
  updateFeature.set('code_grue', data.grue ? data.grue : null);
  updateFeature.set('end_display', data.enddisplay ? format(data.enddisplay, 'yyyy-MM-dd') : null);
  updateFeature.set('extras', data.extras ? data.extras : null);
  updateFeature.set('supprimable_grue', data.supprimable ? 'N' : 'O');

  const featureCollection = new GeoJSON({ dataProjection: 'EPSG:3857' }).writeFeaturesObject([
    updateFeature,
  ]);

  // add CRS object for each feature geometry
  featureCollection.features.forEach((f) => {
    f.geometry.crs = {
      type: 'name',
      properties: { name: 'EPSG:3857' },
    };
  });

  return featureCollection;
};

export { createGeometry, updateGeometry, updateGeometryProperties };
