import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import {
  Button,
  Paper,
  Typography,
  Divider,
  CircularProgress,
  MenuItem,
  Avatar,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { getEntreprise } from '../../api';
import { statutBach, statutNiv } from '../../enums';
import { imageBasePath } from '../../../../utils/image';

const defaultValues = {
  bachage: '',
  nivelage: '',
  statutBach: [],
  statutNiv: [],
};

const NivelageBachageForm = ({ onSubmit }) => {
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    watch,
  } = methods;

  const watchNivelage = watch('nivelage');
  const watchBachage = watch('bachage');
  const watchStatutNiv = watch('statutNiv');
  const watchStatutBach = watch('statutBach');

  const [entBachage, setEntBachage] = useState([]);
  const [entNivelage, setEntNivelage] = useState([]);
  const [statutBachage, setStatutBachage] = useState([]);
  const [statutNivelage, setStatutNivelage] = useState([]);

  // init usines list
  useEffect(() => {
    (async () => {
      const dataBachage = await getEntreprise('B');
      const mapBachage = dataBachage.map((b) => ({ label: b.entreprise, value: b.entreprise }));
      const dataNivelage = await getEntreprise('N');
      const mapNivelage = dataNivelage.map((n) => ({ label: n.entreprise, value: n.entreprise }));
      const mapStatutBach = statutBach.map((sb) => ({
        label: sb.text,
        value: sb.code,
        img: sb.src,
      }));
      const mapStatutNiv = statutNiv.map((sn) => ({ label: sn.text, value: sn.code, img: sn.src }));
      setEntBachage(mapBachage);
      setEntNivelage(mapNivelage);
      setStatutBachage(mapStatutBach);
      setStatutNivelage(mapStatutNiv);
    })();
  }, []);

  useEffect(() => {
    if (watchNivelage != '' || watchStatutNiv.length > 0) {
      setValue('statutBach', []);
      setValue('bachage', '');
    }
  }, [setValue, watchNivelage, watchStatutNiv]);

  useEffect(() => {
    if (watchBachage != '' || watchStatutBach.length > 0) {
      setValue('statutNiv', []);
      setValue('nivelage', '');
    }
  }, [setValue, watchBachage, watchStatutBach]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Liste des entreprises de nivelage / bachage</Typography>
        <Divider />
        <Typography variant="subtitle1">Nivelage</Typography>
        <FormSelect
          name="nivelage"
          control={control}
          label="Entreprise de nivelage"
          MenuProps={MenuProps}
          loading="lazy"
        >
          {entNivelage.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="statutNiv"
          control={control}
          label="Statut de nivelage"
          multiple
          loading="lazy"
          renderValue={(selected) =>
            statutNivelage
              .filter((option) => selected.indexOf(option.value) > -1)
              .map((option) => option.label)
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          {(
            props // thanks to 'children(value)' line in FormSelect code
          ) =>
            statutNivelage.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={props.indexOf(option.value) > -1} />
                <Avatar
                  alt={option.label}
                  src={imageBasePath() + option.img}
                  sx={{
                    ml: -0.5,
                    mr: 1,
                  }}
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))
          }
        </FormSelect>
        <Typography variant="subtitle1">Bâchage</Typography>
        <FormSelect
          name="bachage"
          control={control}
          label="Entreprise de bâchage"
          MenuProps={MenuProps}
          loading="lazy"
        >
          {entBachage.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="statutBach"
          control={control}
          label="Statut de bâchage"
          multiple
          loading="lazy"
          renderValue={(selected) =>
            statutBachage
              .filter((option) => selected.indexOf(option.value) > -1)
              .map((option) => option.label)
              .join(', ')
          }
          MenuProps={MenuProps}
        >
          {(
            props // thanks to 'children(value)' line in FormSelect code
          ) =>
            statutBachage.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <Checkbox checked={props.indexOf(option.value) > -1} />
                <Avatar
                  alt={option.label}
                  src={imageBasePath() + option.img}
                  sx={{
                    ml: -0.5,
                    mr: 1,
                  }}
                />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))
          }
        </FormSelect>
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const nivelage = getValues('nivelage');
            const bachage = getValues('bachage');
            if (nivelage === '' && bachage === '') {
              setError('form', {
                type: 'manual',
                message:
                  'Veuillez sélectionner une entreprise de nivelage ou de bâchage avant de valider',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'OK'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default NivelageBachageForm;
