import React from 'react';
import { nanoid } from 'nanoid';
import { getUsines } from './api';
import { setVectorLayer } from '../../stores/layers';
import VectorSource from 'ol/source/Vector';
import { style } from './style';
import { addNotification } from '../../stores/notifications';
import { GeoJSON } from 'ol/format';
import { highlightFeature } from '../../utils/mapView';
import { imageBasePath } from '../../utils/image';
import { isMobile } from 'react-device-detect';
import { toggleSideBar } from '../../stores/sidebar';

const id = nanoid();
const props = {
  title: 'Usine',
  type: 'Vector',
  visible: false,
  color: '#696969', // Pour changer la couleur liée à la feature
};

const show = async () => {
  addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
  const source = new VectorSource();
  const response = await getUsines();
  const features = new GeoJSON().readFeatures(response);
  source.addFeatures(features);
  addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
  setVectorLayer({ id, style, source: source, visible: true });
};

const hide = () => {
  const source = new VectorSource();

  setVectorLayer({ id, source, visible: false });
};

const popup = (feature) => `${feature.nom} (${feature.code_usine})`;

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  return {
    itemId: fProps.nanoid,
    titleHeader: fProps.nom,
    subHeader: fProps.code_usine,
    avatar: <img src={imageBasePath() + 'img/usine/CU_usine.svg'} />,
    actions: [
      {
        icon: 'CenterFocusStrong',
        title: 'Centrer',
        handleClick: () => {
          if (isMobile) {
            toggleSideBar(false);
          }
          highlightFeature(id, feature);
        },
      },
    ],
  };
};

export { id, props, show, hide, popup, mapItem, listItem };
