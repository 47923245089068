import { create } from 'zustand';

const useMapMoveEventStore = create(() => ({
  eventName: '',
}));

const addMapMoveEvent = (eventName) =>
  useMapMoveEventStore.setState(() => ({
    eventName,
  }));

export { useMapMoveEventStore, addMapMoveEvent };
