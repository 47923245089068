import VectorSource from 'ol/source/Vector';
import { positionStyle } from './style';
import { addNotification, closeNotification } from '../../stores/notifications';
import { useMapStore } from '../../stores/map';
import { Feature, Geolocation } from 'ol';
import { Point } from 'ol/geom';
import VectorLayer from 'ol/layer/Vector';
import { fitToFeature } from '../../utils/mapView';

let geolocation;
let locationFound = false;
let positionFeature = null;
let accuracyFeature = null;
let layerLocation = null;

const showLocation = (center) => {
  if(layerLocation && locationFound) {
    fitToFeature(positionFeature);
    return;
  }

  if(!layerLocation) {
    accuracyFeature = new Feature();
    positionFeature = new Feature();
    positionFeature.setStyle(positionStyle);
    layerLocation = new VectorLayer({
      map: useMapStore.getState().map,
      source: new VectorSource({
        features: [accuracyFeature, positionFeature],
      }),
    });
  }

  let notif = addNotification({ message: 'Localisation en cours', variant: 'default', persist: true });

  geolocation = new Geolocation({
    trackingOptions: {
      enableHighAccuracy: true,
    },
    projection: useMapStore.getState().map.getView().getProjection(),
  });
  
  geolocation.on('change:accuracyGeometry', function () {
    accuracyFeature.setGeometry(geolocation.getAccuracyGeometry());
  });

  geolocation.on('change:position', function () {
    closeNotification(notif);
    notif = null;

    const coordinates = geolocation.getPosition();
    positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);

    if(!locationFound) {
      if (center) {
        fitToFeature(positionFeature);
      }
      locationFound = true;
    }
  });

  geolocation.on('error', function (error) {
    closeNotification(notif);
    notif = null;

    if(error && error.code == 1) {
      addNotification({ message: 'Vous devez autoriser l\'accès à votre position depuis votre navigateur', variant: 'error' });
    } else {
      addNotification({ message: 'Récupération de votre position impossible', variant: 'error' });
    }
  });

  geolocation.setTracking(true);
};

export { showLocation };
