import React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { setLayerSelectorOpened, useLayerStore } from '../../stores/layers';
import { useLayers } from '../../hooks';
import { getViewExtent } from '../../stores/view';
import * as features from '../../features';
import can from '../../utils/duckTyping';
import { isMobile } from 'react-device-detect';
import LayersIcon from '@mui/icons-material/Layers';
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from '@mui/base';

export default function Layers() {
  const { vectorLayers } = useLayers();
  const stateLayerSelectorOpened = useLayerStore((state) => state.selectorOpened);
  const keys = Object.keys(features);

  return (
    <ClickAwayListener onClickAway={setLayerSelectorOpened.bind(this, false)}>
      <Box
        sx={{
          zIndex: 2,
          '& > :not(style)': { m: '8px 8px 0 8px' },
          position: 'absolute',
          top: !isMobile ? '7px' : '-2px',
          right: 0,
          textAlign: 'end',
          display: !isMobile ? 'inline-flex' : 'flex',
          flexDirection: !isMobile ? 'unset' : 'column',
          alignItems: !isMobile ? 'center' : 'flex-end',
        }}
      >
        {isMobile && (
          <Fab
            size="medium"
            sx={{ position: 'relative' }}
            //color={open ? 'secondary' : 'primary'}
            aria-label="add"
            onClick={() => setLayerSelectorOpened(!stateLayerSelectorOpened)}
          >
            {!stateLayerSelectorOpened ? <LayersIcon /> : <CloseIcon />}
          </Fab>
        )}
        {vectorLayers.map((l) => {
          return keys.map((key) => {
            if (features[key].id === l.get('id') && (stateLayerSelectorOpened || !isMobile)) {
              return (
                <Fab
                  sx={{ textTransform: 'none' }}
                  key={l.get('id')}
                  variant="extended"
                  size="small"
                  color={l.get('visible') ? 'secondary' : 'primary'}
                  onClick={() => {
                    if (l.get('visible') && can(features[key], 'hide')) {
                      features[key].hide();
                    } else if (!l.get('visible') && can(features[key], 'show')) {
                      features[key].show(getViewExtent());
                    }
                  }}
                >
                  {l.get('name')}
                </Fab>
              );
            }
          });
        })}
      </Box>
    </ClickAwayListener>
  );
}
