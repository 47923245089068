import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Paper, Typography, Divider } from '@mui/material';
import FormInputText from '../../../../components/FormInputText';
import { getCommuneInfosFromXY } from '../../api';
import CircularProgress from '@mui/material/CircularProgress';

const defaultValues = {
  commune: '',
  codeinsee: '',
  surfcalc: '',
};

const DrawParcelleForm = ({ parcelleProps }) => {
  const { centroid = null, area = 0 } = parcelleProps;
  defaultValues.surfcalc = area;
  const methods = useForm({ defaultValues });
  const { control, setValue } = methods;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (centroid) {
        const { code, nom } = await getCommuneInfosFromXY(centroid);
        setValue('commune', nom, true);
        setValue('codeinsee', code, true);
        if (area != 0) {
          setValue('surfcalc', area, true);
        }
        setLoading(false);
      }
    })();
  }, [centroid, area, setValue]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <Typography variant="h6">Dessiner une parcelle</Typography>
      <Divider />
      <Typography variant="subtitle1">Infos parcelle</Typography>
      <FormInputText
        name="commune"
        control={control}
        label="Commune"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
            </React.Fragment>
          ),
        }}
      />
      <FormInputText
        name="codeinsee"
        control={control}
        label="Code insee"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
            </React.Fragment>
          ),
        }}
      />
      <FormInputText
        name="surfcalc"
        control={control}
        label="Surface calculée"
        InputProps={{
          readOnly: true,
        }}
      />
    </Paper>
  );
};

export default DrawParcelleForm;
