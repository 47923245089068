import { nanoid } from 'nanoid';
import { unvisibleALLTools, visibleTool } from '../../stores/menuTools';
import { addToolsItems } from '../../stores/menuToolsItems';
import ChoiseForm from './components/ChoiseForm';

const id = nanoid();
const props = {
  title: 'Impression',
  type: 'Tool',
  visible: false,
  icon: 'Print',
};

const setPageSize = (orientation)  => {
  const style = document.createElement('style');
  style.innerHTML = `@page {size: ${orientation}}`;
  style.id = 'page-orientation';
  document.head.appendChild(style);
}

window.addEventListener("afterprint", () => {
  const style = document.getElementById('page-orientation');
  if(style != null){
    style.parentNode.removeChild(style);
  }
});

const show = async () => {
  visibleTool({ id, data: [], visible: true });
  addToolsItems({
    component: ChoiseForm,
    props: {
      onSubmit: async (data) => {
        setPageSize(data.typeprint);
        window.focus();
        window.print();
        unvisibleALLTools();
      },
    },
    children: null,
  });
};

const menuItems = () => []; // icons list: https://mui.com/components/material-icons/

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = () => {};


export { id, props, show, mapItem, menuItems, listItem};
