import React from 'react';
import Fab from '@mui/material/Fab';
import { isMobile } from 'react-device-detect';
import { showLocation } from '../../features/geolocation';
import { MyLocation } from '@mui/icons-material';
import { useTimelineStore } from '../../stores/timeline';

export default function GeolocationButton() {
  const isTimelineVisible = useTimelineStore((state) => state.visible);

  if (isMobile) {
    return (
      <Fab
        variant="circular"
        size="medium"
        sx={{
          textTransform: 'none',
          bgColor: 'white',
          position: 'absolute',
          zIndex: 2,
          bottom: isTimelineVisible ? '170px' : '90px',
          left: 'unset',
          right: '8px',
        }}
        onClick={() => {
          showLocation(true);
        }}
      >
        <MyLocation />
      </Fab>
    );
  } else {
    return null;
  }
}
