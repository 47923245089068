import { nanoid } from 'nanoid';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useNotificationStore = create(
  subscribeWithSelector(() => ({
    notifications: [],
    clear: false,
    close: [],
  }))
);

const addNotification = (notification) => {
  const notifId = nanoid();
  useNotificationStore.setState((state) => ({
    notifications: [...state.notifications, { id: notifId, ...notification }],
  }));
  return notifId;
};

const removeNotification = (id) =>
  useNotificationStore.setState((state) => ({
    notifications: state.notifications.filter((notification) => notification.id !== id),
    close: state.close.filter((notification) => notification.id !== id),
  }));

const closeNotification = (id) => {
  const notifToClose = useNotificationStore
    .getState()
    .notifications.find((notification) => notification.id === id);
  if (notifToClose) {
    useNotificationStore.setState((state) => ({
      close: [...state.close, notifToClose],
    }));
  }
};

const clearNotifications = () =>
  useNotificationStore.setState((state) => ({
    clear: !state.clear,
  }));

export {
  useNotificationStore,
  addNotification,
  closeNotification,
  removeNotification,
  clearNotifications,
};
