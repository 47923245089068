import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { usePictoStore } from '../stores';
import { props } from '../../../features/picto';

const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';
const fontCluster = 'bold' + ' ' + '14px' + '/' + '1' + ' ' + 'Arial';

const style = (feature) => {
  const features = feature.get('features');

  if (features.length > 1) {
    // cluster feature
    return new Style({
      image: new CircleStyle({
        radius: 14,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: props.color ? props.color : '#047756',
        }),
      }),
      text: new Text({
        text: features.length.toString(),
        offsetY: 1,
        font: fontCluster,
        fill: new Fill({
          color: '#D62828',
        }),
      }),
    });
  } else {
    // single feature
    const pictos = usePictoStore.getState().pictos;
    const pictoDataUri = pictos.find((img) => img.id === features[0].get('id'));

    return new Style({
      image: new Icon({
        src: 'data:image/png;base64,' + pictoDataUri.properties.data,
      }),
      text: new Text({
        text: features[0].get('label'),
        fill: new Fill({
          color: '#D62828',
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3
        }),
        offsetY: -24,
        font: font,
      }),
    });
  }
};

export { style };
