import { containsCoordinate, containsExtent } from 'ol/extent';

const franceExtent = [-542125.921, 5210540.747, 916159.406, 6646095.572];

const checkInFrance = (coordinate) => {
  return containsCoordinate(franceExtent, coordinate);
};

const checkExtentInFrance = (extent) => {
  return containsExtent(franceExtent, extent);
};

const checkCoordinateinExtent = (extent, coordinate) => {
  return containsCoordinate(extent, coordinate);
};

export { checkInFrance, checkExtentInFrance, checkCoordinateinExtent };
