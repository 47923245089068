import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

const useAreaSearchStore = create(
  subscribeWithSelector(() => ({
    areaSearchLayers: [],
  }))
);

/**
 * Active le bouton de recherche par zone pour le layer donné
 * @param {String} layerId idenfiant (nanoid) du layer
 * @param {String} message message spécifique à afficher dans le bouton si seul un layer est actif
 * @param {Function} onAreaSearch fonction (avec un paramètre Extent) qui sera exécutée lors du clic sur le bouton de recherche par zone
 */
const enableAreaSearch = (layerId, message, onAreaSearch) => {
  const layers = useAreaSearchStore.getState().areaSearchLayers;
  const newLayers = layers.filter(b => b.layerId != layerId);
  newLayers.push({
    layerId, message, onAreaSearch
  });
  useAreaSearchStore.setState(() => ({ areaSearchLayers: newLayers }));
};

/**
 * Désactive le bouton de recherche par zone pour le layer donné
 * @param {String} layerId idenfiant (nanoid) du layer
 */
const disableAreaSearch = (layerId) => {
  const layers = useAreaSearchStore.getState().areaSearchLayers;
  const newLayers = layers.filter(b => b.layerId != layerId);
  useAreaSearchStore.setState(() => ({ areaSearchLayers: newLayers }));
};

export {
  useAreaSearchStore,
  enableAreaSearch,
  disableAreaSearch
};
