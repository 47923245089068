import { create } from 'zustand';

const useAlertDialogStore = create(() => ({
  open: false,
  title: '',
  content: '',
  buttons: [],
}));

/**
 * Ouvre l'alert dialog avec le titre, le contenu et les boutons renseignés
 * @param {String} title Titre de la dialogue
 * @param {String} content Contenu de la dialogue
 * @param {Array} buttons Boutons de la dialog. Chaque bouton doit définir les propriétés "title" définissant le titre du bouton et "handler" définissant la fonction a appelé lors du clic
 */
const openAlertDialog = (title, content, buttons) =>
  useAlertDialogStore.setState(() => ({
    open: true,
    title: title,
    content: content,
    buttons: buttons,
  }));

const hideAlertDialog = () => {
  useAlertDialogStore.setState(() => ({
    open: false,
  }));
};

export { useAlertDialogStore, openAlertDialog, hideAlertDialog };
