import { create } from 'zustand';

const useMenuToolsItemsStore = create(() => ({
  items: {},
}));

const addToolsItems = (items) =>
  useMenuToolsItemsStore.setState(() => ({
    items: { ...items },
  }));

export { useMenuToolsItemsStore, addToolsItems };
