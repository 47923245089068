import { useState, useEffect, useCallback } from 'react';
import { useInteractionsStore } from '../stores/interactions';
import { useLayers } from '../hooks';
import { Draw, Modify, Select, Snap, DragRotateAndZoom } from 'ol/interaction';
import Cluster from 'ol/source/Cluster';
import { isMobile } from 'react-device-detect';

const useInteractions = () => {
  const stateInteractions = useInteractionsStore((state) => state.interactions);
  const [interactions, setInteractions] = useState([]); // maintain OpenLayers map interactions array
  const { vectorLayers } = useLayers();

  const toSelect = () => {
    return new Select();
  };

  const toDraw = useCallback(
    ({ interaction }) => {
      const layer = vectorLayers.find((l) => l.get('id') === interaction.layerId);
      const int = new Draw({
        source:
          layer.getSource() instanceof Cluster ? layer.getSource().getSource() : layer.getSource(),
        type: interaction.geomType,
      });
      int.set('id', interaction.id);
      int.set('state', interaction.state);
      int.once('drawend', (e) => interaction?.handleDrawend(e));
      return int;
    },
    [vectorLayers]
  );

  const toModify = ({ interaction }) => {
    const int = new Modify({
      features: interaction.features,
      pixelTolerance: isMobile ? 40 : 10,
    });
    int.set('id', interaction.id);
    int.set('state', interaction.state);
    int.on('modifyend', (e) => interaction?.handleModifyend(e));
    return int;
  };

  const toDragRotateAndZoom = ({ interaction }) => {
    const int = new DragRotateAndZoom();
    int.set('id', interaction.id);
    int.set('state', interaction.state);
    return int;
  };

  const toSnap = () => {
    return new Snap();
  };

  useEffect(() => {
    const newInteractions = stateInteractions
      .filter((interaction) => interaction.state !== 'ADDED')
      .map((interaction) => {
        switch (interaction.type) {
          case 'Select':
            return toSelect();
          case 'Draw':
            return toDraw({ interaction });
          case 'Modify':
            return toModify({ interaction });
          case 'DragRotateAndZoom':
            return toDragRotateAndZoom({ interaction });
          case 'Snap':
            // The snap interaction must be added last,
            // as it needs to be the first to handle the pointermove event.
            return toSnap();
          default:
            return undefined;
        }
      });
    if (newInteractions.length > 0) {
      setInteractions(newInteractions);
    }
  }, [stateInteractions, toDraw]);

  return [interactions];
};

export default useInteractions;
