import { addNotification } from '../stores/notifications';
import { useItemsStore, setItems } from '../stores/menuItems';

const dsControleCheck = (ds) => {
  return new Promise((resolve, reject) => {
    let error = { active: false, message: '' };
    ds.map((e) => {
      if (e.typeerreur === 'E' && !error.active) {
        error.active = true;
        error.message = e.libelle + ` (${e.nomzone})`;
        addNotification({
          variant: 'error',
          message: e.libelle + ` (${e.nomzone})`,
          autoHideDuration: 5000
        });
      }
    });

    if (error.active) {
      return reject(error.message);
    }

    return resolve();
  });
};

const displayDsControle = async (ds) => {
  return new Promise((resolve) => {
    let messageError = [];
    ds.map((e) => {
      if (e.typeerreur === 'E') {
        messageError.push({ message: e.libelle + ` (${e.nomzone}).`, nomzone: e.nomzone });
      }
    });
    if (messageError.length > 0) {
      const items = useItemsStore.getState().items;
      items.props.siaErrors = messageError;
      setItems(items);
      return resolve(true);
    } else {
      return resolve(false);
    }
  });
};

export { dsControleCheck, displayDsControle };
