import { format } from 'date-fns';

const yesNo = [
  { label: 'Oui', value: 'O' },
  { label: 'Non', value: 'N' },
];

const typeBio = [
  { label: 'Bio', value: format(new Date(), 'yy') },
  { label: 'En conversion', value: 'C2' },
  { label: 'Conventionnelle', value: '' },
];

const natureProduitCode = {
  couvertMellifere: 'C',
  luzerne: 'L',
  betterave: 'B',
  planteur: 'T',
  vinasse: 'V'
};

const tableName = {
  T: 'parcelles_planteur',
  B: 'parcelles_betterave',
  L: 'parcelles_luzerne',
  V: 'parcelles_vinasse',
};

const GeometryType = {
  POINT: 'Point',
  LINE_STRING: 'LineString',
  POLYGON: 'Polygon',
  MULTI_POLYGON: 'MultiPolygon',
};

export { yesNo, typeBio, natureProduitCode, GeometryType, tableName };
