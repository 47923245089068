import { create } from 'zustand';
import { id as usineId, props as usineProps } from '../features/usine';
import { id as siloId, props as siloProps } from '../features/silo';
import { id as aireStockageId, props as aireStockageProps } from '../features/aire-stockage';
import { id as pictoId, props as pictoProps } from '../features/picto';
import {
  id as materielAgricoleId,
  props as materielAgricoleProps,
} from '../features/materiel-agricole';
import { id as circuitChampId, props as circuitChampProps } from '../features/circuit-champ';
import { id as parcelleId, props as parcelleProps } from '../features/parcelle';
import { id as ravageurId, props as ravageurProps } from '../features/ravageur';
import {
  id as meteoId,
  props as meteoProps,
  idSelection as meteoSelectionId,
  propsSelection as meteoSelectionProps,
} from '../features/meteo';

const useLayerStore = create(() => ({
  activeLayer: null, // OpenLayers layer instance
  selectorOpened: false,
  raster: [
    {
      id: 'Scan25tour_XYZ',
      title: 'Scan25tour',
      type: 'XYZ',
      url:
        'https://go.cristal-cloud.net/' +
        'mapproxy/wmts/scan25tour_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: true,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
    {
      id: 'Hybride_XYZ',
      title: 'Hybride',
      type: 'XYZ',
      url: 'https://go.cristal-cloud.net/' + 'mapproxy/wmts/hybrid_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: false,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
    {
      id: 'Satellite_XYZ',
      title: 'Satellite',
      type: 'XYZ',
      url: 'https://go.cristal-cloud.net/' + 'mapproxy/wmts/ortho_ign/webmercator/{z}/{x}/{y}.jpeg',
      visible: false,
      state: 'ADD',
      resolutions: [
        156543.033928, 78271.516964, 39135.758482, 19567.879241, 9783.9396205, 4891.96981025,
        2445.98490513, 1222.99245256, 611.496226281, 305.748113141, 152.87405657, 76.4370282852,
        38.2185141426, 19.1092570713, 9.5546285356, 4.7773142678, 2.3886571339, 1.194328567,
        0.5971642835,
      ],
    },
  ],
  vector: [
    { id: meteoId, ...meteoProps, state: 'ADD' },
    { id: meteoSelectionId, ...meteoSelectionProps, state: 'ADD' },
    { id: parcelleId, ...parcelleProps, state: 'ADD' },
    { id: usineId, ...usineProps, state: 'ADD' },
    { id: siloId, ...siloProps, state: 'ADD' },
    { id: aireStockageId, ...aireStockageProps, state: 'ADD' },
    { id: pictoId, ...pictoProps, state: 'ADD' },
    { id: materielAgricoleId, ...materielAgricoleProps, state: 'ADD' },
    { id: circuitChampId, ...circuitChampProps, state: 'ADD' },
    { id: ravageurId, ...ravageurProps, state: 'ADD' },
  ],
}));

const setActiveLayer = (layer) =>
  useLayerStore.setState(() => ({
    activeLayer: layer,
  }));

const addRasterLayer = (layer) =>
  useLayerStore.setState((state) => ({
    raster: [...state.raster, { ...layer, id: `${layer.title}_${layer.type}`, state: 'ADD' }],
  }));

const addedRasterLayer = (layer) => {
  useLayerStore.setState((state) => ({
    raster: state.raster.map((l) => (layer.id === l.id ? { ...l, state: 'ADDED' } : l)),
  }));
};

const setRasterLayer = (layer) => {
  const index = useLayerStore.getState().raster.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    raster: state.raster.map((l, i) => (i === index ? { ...l, ...layer, state: 'SET' } : l)),
  }));
};

const removeRasterLayer = (layer) => {
  const index = useLayerStore.getState().raster.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    raster: state.raster.map((l, i) => (i === index ? { ...l, ...layer, state: 'REMOVE' } : l)),
  }));
};

const removedRasterLayer = (layer) =>
  useLayerStore.setState((state) => ({
    raster: state.raster.filter((item) => item.id !== layer.id),
  }));

const addVectorLayer = (layer) =>
  useLayerStore.setState((state) => ({
    vector: [...state.vector, { ...layer, id: `${layer.title}_${layer.type}`, state: 'ADD' }],
  }));

const addedVectorLayer = (layer) => {
  useLayerStore.setState((state) => ({
    vector: state.vector.map((l) => (layer.id === l.id ? { ...l, state: 'ADDED' } : l)),
  }));
};

const setVectorLayer = (layer) => {
  const index = useLayerStore.getState().vector.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    vector: state.vector.map((l, i) => (i === index ? { ...l, ...layer, state: 'SET' } : l)),
  }));
};

const removeVectorLayer = (layer) => {
  const index = useLayerStore.getState().vector.findIndex((l) => l.id === layer.id);

  useLayerStore.setState((state) => ({
    vector: state.vector.map((l, i) => (i === index ? { ...l, ...layer, state: 'REMOVE' } : l)),
  }));
};

const removedVectorLayer = (layer) =>
  useLayerStore.setState((state) => ({
    vector: state.vector.filter((item) => item.id !== layer.id),
  }));

const setLayerSelectorOpened = (opened) => {
  useLayerStore.setState(() => ({ selectorOpened: opened }));
};

export {
  useLayerStore,
  setActiveLayer,
  addRasterLayer,
  addedRasterLayer,
  setRasterLayer,
  removeRasterLayer,
  removedRasterLayer,
  addVectorLayer,
  addedVectorLayer,
  setVectorLayer,
  removeVectorLayer,
  removedVectorLayer,
  setLayerSelectorOpened
};
