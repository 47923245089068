import { Circle as CircleStyle, Stroke, Fill, Style, Text } from 'ol/style';
import { getColorCode as getColorCodeCouvertMellifere } from '../rules/couvert-mellifere';
import hex2rgba from '../../../utils/hex2rgba';
import { props } from '../index';

const font = 'bold' + ' ' + '15px' + '/' + '1' + ' ' + 'Arial';

const labelStyle = new Style({
  text: new Text({
    font: font,
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

// zIndex: 1 ensure that couvert mellifere features are always on top
const couvertMellifereStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.5)',
  }),
  stroke: new Stroke({
    color: '#319FD3',
    width: 1,
  }),
  zIndex: 1000,
});

const parcelleStyle = new Style({});

const style = (feature) => {
  const features = feature.get('features');

  if (features && features.length > 1) {
    // cluster
    // couvert mellifere only
    const surfSum = features
      .filter((f) => {
        const cultureCode = f.get('culture') || f.get('code_cultu') || '';
        return cultureCode.includes('SC');
      })
      .map((f) => parseFloat(f.get('surf_calc')))
      .reduce((acc, surf) => acc + surf, 0);

    return new Style({
      image: new CircleStyle({
        radius: 20,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: '#FF5E33',
        }),
      }),
      text: new Text({
        text: surfSum.toFixed(2),
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
  }

  // simple feature
  const styles = [labelStyle];
  const nomParc = feature.get('nomparcelle') ? `${feature.get('nomparcelle')}`: '';
  const codeParc = feature.get('codeparcelle') ? `(${feature.get('codeparcelle')})` : '';
  let label = nomParc + " " + codeParc;
  if (feature.get('codeplanteur')) {
    label += "\n" + feature.get('codeplanteur');
  }

  // label différent si vinasse
  if (feature.get('culture') == 'vinasse') {
    label += feature.get('num_cde') + '\n' + feature.get('nom_client_liv'); 
  }

  const cultureCode = feature.get('culture') || feature.get('code_cultu') || '';
  labelStyle.getText().setText(label ? label : '');
  if (cultureCode.includes('SC')) {
    // couvert mellifere
    // const rgba = hex2rgba(getColorCodeCouvertMellifere(cultureCode), 0.5);
    couvertMellifereStyle.setFill(
      new Fill({
        color: hatchTile(getColorCodeCouvertMellifere(cultureCode)),
      })
    );
    styles.push(couvertMellifereStyle);
  } else {
    //  parcelle Style
    if (feature.get('coulculture')) {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : feature.get('coulculture'),
        0.5
      );
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      const colorStroke = feature.get('highlight')
        ? props.highlightColor
        : '#'+feature.get('coulculture');
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    } else if (feature.get('codeplanteur') && feature.get('codeparcelle')) {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.color,
        0.5
      );
      const colorStroke = feature.get('highlight')
        ? props.highlightColor
        : props.color;
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    } else {
      const rgba = hex2rgba(
        feature.get('highlight') ? props.highlightColor : props.defaultGeomColor,
        0.5
      );
      const colorStroke = feature.get('highlight')
        ? props.highlightColor
        : props.defaultGeomColor;
      parcelleStyle.setFill(
        new Fill({
          color: rgba,
        })
      );
      parcelleStyle.setStroke(
        new Stroke({
          color: colorStroke,
          width: 2,
        })
      );
    }
    styles.push(parcelleStyle);
  }

  return styles;
};

const hatchTile = (color) => {
  const tile = document.createElement('canvas');
  tile.width = tile.height = 10;
  const ctx = tile.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, tile.width, tile.height);
  const colorStops = [
    [0, color],
    [0.35, color],
    [0.35, 'white'],
    [0.5, 'white'],
    [0.5, color],
    [0.85, color],
    [0.85, 'white'],
    [1, 'white'],
  ];
  colorStops.forEach((element) => {
    gradient.addColorStop(element[0], element[1]);
  });

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, tile.width, tile.height);

  return ctx.createPattern(tile, 'repeat');
};

export { style };
