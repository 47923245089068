import React, { useState, useEffect, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller } from 'react-hook-form';
import debounce from '../../utils/debounce';

const FormInputAutocomplete = ({
  name,
  control,
  label,
  placeholder,
  displayProp,
  equalityProp,
  source,
  multiple,
  autoSelect,
  filterSelectedOptions,
  isLocalSource,
  noOptionsText,
  startsWithSearch,
  allowUnkownOption
}) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (e) => {
        const value = e.target.value;
        if (!value) {
          setOpen(false);
          setItems([]);
        } else if (typeof source === 'function') {
          try {
            setLoading(true);
            const newOptions = await source({ text: value });
            setOpen(newOptions.length > 0);
            setItems(newOptions);
          } finally {
            setLoading(false);
          }
        } else if (Array.isArray(source)) {
          const newOptions = source.filter((option) => {
            if (startsWithSearch) {
              return option[equalityProp].toUpperCase().startsWith(value.toUpperCase());
            } else {
              return option[equalityProp].toUpperCase().includes(value.toUpperCase());
            }
          });
          if (newOptions.length == 0 && allowUnkownOption) {
            const newOption = {};
            newOption[equalityProp] = value;
            newOption[displayProp] = value;
            source.push(newOption);
            newOptions.push(newOption);
          }
          setOpen(true);
          setItems(newOptions);
        }
      }, isLocalSource ? 100 : 1000),
    [equalityProp, displayProp, source, isLocalSource, startsWithSearch, allowUnkownOption]
  );

  useEffect(() => {
    if (!open) {
      setItems([]);
    }
  }, [open]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          autoHighlight
          autoSelect={autoSelect}
          multiple={multiple}
          filterSelectedOptions={filterSelectedOptions}
          open={open}
          onClose={() => setOpen(false)}
          options={items}
          value={value || (multiple ? [] : null)}
          onChange={(event, data) => onChange(data)}
          getOptionLabel={(option) => (option[displayProp] ? option[displayProp] : '')}
          filterOptions={(optionsToFilter) => optionsToFilter}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === '' || option[equalityProp] === value[equalityProp]
          }
          noOptionsText={noOptionsText || "Aucun élément trouvé"}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!error}
              onChange={debouncedChangeHandler}
              placeholder={placeholder}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};

export default FormInputAutocomplete;
