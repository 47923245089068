import React from 'react';
import { nanoid } from 'nanoid';
import {
  getPictosApi,
  deletePictosApi,
  sendPictosApi,
  updatePictosApi,
  getPictosImagesApi,
} from './api';
import { GeoJSON } from 'ol/format';
import { style } from './style';
import { setVectorLayer } from '../../stores/layers';
import { getViewZoom, getViewExtent } from '../../stores/view';
import { addNotification } from '../../stores/notifications';
import { useInteractionsStore, addInteraction, removeInteraction } from '../../stores/interactions';
import { addItems } from '../../stores/menuItems';
import Collection from 'ol/Collection';
import VectorSource from 'ol/source/Vector';
import Cluster from 'ol/source/Cluster';
import CreateForm from './components/CreateForm';
import EditForm from './components/EditForm';
import { parseISO, format } from 'date-fns';
import { usePictoStore } from './stores';
import {
  createGeometry as createGeometryEntryPictos,
  updateGeometryProperties as updateGeometryPropertiesEntryPictos,
  updateGeometry as updateGeometryEntryPictos,
} from './bindings/geometry-entry';
import { highlightFeature } from '../../utils/mapView';
import { disableAreaSearch, enableAreaSearch } from '../../stores/areaSearch';
import { typepicto } from './enums';
import { isMobile } from 'react-device-detect';
import { toggleSideBar } from '../../stores/sidebar';

const id = nanoid();
const props = {
  title: 'Picto',
  type: 'Vector',
  visible: false,
  color: '#047756', // Pour changer la couleur liée à la feature
};

let typePictoSelected = '';

const show = async (extent) => {
  enableAreaSearch(id, 'Afficher les pictos dans la zone', show);

  const source = new VectorSource();
  const pictos = usePictoStore.getState().pictos;
  if (pictos.length === 0) {
    const pictos = await getPictosImagesApi();
    usePictoStore.setState({ pictos });
  }

  if (getViewZoom() > 12) {
    addNotification({ message: props.title + ' - ' + 'Chargement en cours', variant: 'default' });
    const cluster = new Cluster({
      source,
    });

    const response = await getPictosApi({ extent, type: typePictoSelected });
    const features = new GeoJSON().readFeatures(response);

    source.addFeatures(features);

    addNotification({ message: props.title + ' - ' + 'Chargement terminé', variant: 'success' });
    setVectorLayer({ id, style, source: cluster, visible: true });
  } else {
    addNotification({
      message: props.title + ' - ' + 'Zoomer pour afficher des données',
      variant: 'warning',
    });
    setVectorLayer({ id, style, source, visible: true });
  }
};

const hide = () => {
  disableAreaSearch(id);

  const source = new VectorSource();
  setVectorLayer({ id, source, visible: false });
};

const popup = (props) => `Commentaire: ${props.commentaire || 'aucun'}`;

const create = async (feature, data) => {
  const createGeometryEntry = await createGeometryEntryPictos({
    feature,
    data,
  });
  const response = await sendPictosApi(createGeometryEntry);
  return response;
};

const edit = async (feature, data) => {
  if (data) {
    const updateGeometryPropertiesPictos = await updateGeometryPropertiesEntryPictos({
      feature,
      data,
    });
    const response = await updatePictosApi(updateGeometryPropertiesPictos);
    return response;
  } else {
    const updateGeometryPictos = await updateGeometryEntryPictos({
      feature,
    });
    const response = await updatePictosApi(updateGeometryPictos);
    return response;
  }
};

const menuItems = () => {
  const menuItems = [
    {
      key: nanoid(),
      text: 'Voir tous les pictos',
      icon: 'Search',
      selected: typePictoSelected == '',
      handleClick: () => {
        typePictoSelected = '';
        show(getViewExtent());
      },
    },
  ];

  typepicto.forEach((t) => {
    menuItems.push({
      key: nanoid(),
      text: 'Voir les pictos ' + t.label.toLowerCase(),
      icon: 'Search',
      selected: typePictoSelected == t.value,
      handleClick: () => {
        typePictoSelected = t.value;
        show(getViewExtent());
      },
    });
  });

  menuItems.push({
    key: nanoid(),
    text: 'Placer un picto',
    icon: 'AddCircle',
    handleClick: () =>
      addItems({
        component: CreateForm,
        props: {
          onSubmit: (data) => {
            addNotification({
              message: 'Placer le picto sur la carte pour finaliser la création',
              variant: 'default',
            });
            return new Promise((resolve, reject) => {
              const intId = nanoid();
              addInteraction({
                id: intId,
                type: 'Draw',
                geomType: 'Point',
                layerId: id,
                handleDrawend: async (e) => {
                  removeInteraction({ id: intId });
                  const feature = e.feature;
                  try {
                    const response = await create(feature, data);
                    if (response.gid) {
                      addNotification({
                        message: 'Picto créé',
                        variant: 'success',
                      });
                      show(getViewExtent());
                      resolve();
                    } else {
                      addNotification({
                        message: 'Picto NON créé',
                        variant: 'error',
                      });
                      reject('something wrong');
                    }
                  } catch (err) {
                    show(getViewExtent());
                    reject(err);
                  }
                },
              });
            });
          },
        },
        children: null,
      }),
  });

  return menuItems;
};

const mapItem = (feature) => {
  feature.set('nanoid', nanoid(), true);
  return feature;
};

const listItem = (feature) => {
  const fProps = feature.getProperties();
  const intModifyId = nanoid();
  const actions = [
    {
      icon: 'CenterFocusStrong',
      title: 'Centrer',
      handleClick: () => {
        if (isMobile) {
          toggleSideBar(false);
        }
        highlightFeature(id, feature);
      },
    },
    {
      icon: 'EditLocation',
      title: 'Déplacer',
      handleClick: () => {
        return new Promise((resolve, reject) => {
          const interaction = useInteractionsStore
            .getState()
            .interactions.find((item) => item.id === intModifyId);
          if (!interaction) {
            const notifActions = [
              {
                title: 'Confirmer',
                handleClick: async () => {
                  removeInteraction({ id: intModifyId });
                  try {
                    await edit(feature);
                    addNotification({
                      message: 'Picto modifié',
                      variant: 'success',
                    });
                    show(getViewExtent());
                    resolve();
                  } catch (error) {
                    addNotification({
                      message: 'Impossible de modifier le picto',
                      variant: 'error',
                    });
                    reject(error);
                  }
                },
              },
              {
                title: 'Annuler',
                handleClick: () => {
                  removeInteraction({ id: intModifyId });
                  show(getViewExtent());
                  resolve();
                },
              },
            ];
            addNotification({
              message: 'Modification du picto',
              variant: 'info',
              persist: true,
              actions: notifActions,
            });

            addInteraction({
              id: intModifyId,
              type: 'Modify',
              features: new Collection([feature]),
              handleModifyend: () => {},
            });

            if (isMobile) {
              toggleSideBar(false);
            }
          }
        });
      },
    },
    {
      icon: 'Edit',
      title: 'Modifier les infos',
      handleClick: async () => {
        return new Promise((resolve, reject) => {
          addItems({
            component: EditForm,
            props: {
              onSubmit: async (data) => {
                try {
                  await edit(feature, data);
                  addNotification({
                    message: 'Infos du picto modifiées',
                    variant: 'success',
                  });
                  show(getViewExtent());
                  resolve();
                } catch (err) {
                  addNotification({
                    message: 'Impossible de modifier les infos du picto',
                    variant: 'error',
                  });
                  reject(err);
                }
              },
              pictoProps: fProps,
            },
            children: null,
          });
        });
      },
    },
    {
      icon: 'Delete',
      title: 'Supprimer',
      handleClick: async () => {
        try {
          await deletePictosApi(feature.getId());
          addNotification({
            message: 'Picto supprimé',
            variant: 'success',
          });
          show(getViewExtent());
        } catch (error) {
          addNotification({
            message: 'Impossible de supprimer le picto',
            variant: 'error',
          });
        }
      },
    },
  ];

  const pictos = usePictoStore.getState().pictos;
  const pictoDataUri = pictos.find((img) => img.id === fProps.id);

  return {
    itemId: fProps.nanoid,
    titleHeader: fProps.label,
    subHeader: fProps.commentaire,
    avatar: <img src={'data:image/png;base64,' + pictoDataUri.properties.data} />,
    content: [
      `Date de fin d'affichage: ${
        fProps.datefin !== null ? format(parseISO(fProps.datefin), 'dd/MM/yyyy') : ''
      }`,
    ],
    actions,
  };
};

export { id, props, show, hide, popup, mapItem, listItem, menuItems };
