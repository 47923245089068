import { Fill, Stroke, Style } from 'ol/style';
import { METEO_TYPE_PRECIP, useMeteoStore } from '../../../stores/meteo';
import { getScale, maxPrecipInScale } from '../scale';
import { useItemsStore } from '../../../stores/menuItems';

const baseStyle = new Style({
  fill: new Fill({
    color: 'rgba(255, 255, 255, 0.1)',
  }),
  stroke: new Stroke({
    color: 'rgba(255, 255, 255, 0.1)',
    width: 4, // bordure épaisse pour que les contours simplifiés se touchent dans la majorité des cas
  }),
});

const style = (feature) => {
  let weatherDate = useMeteoStore.getState().date;

  if (useMeteoStore.getState().cumul) {
    weatherDate = '99999999';
  }
  const weatherType = useMeteoStore.getState().type;

  if (!feature.get(weatherDate)) {
    return null;
  }

  let value = feature.get(weatherDate)[weatherType + '_value'];
  if (weatherType == METEO_TYPE_PRECIP) {
    if (useMeteoStore.getState().cumul) {
      value = maxPrecipInScale * value / useMeteoStore.getState().maxPrecipCumul; 
    }
  }

  const scale = getScale(weatherType);
  baseStyle.getFill().setColor(scale(value));
  baseStyle.getStroke().setColor(scale(value));

  return baseStyle;
};

const baseSelectionStyle = new Style({
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.2)',
  }),
  stroke: new Stroke({
    color: 'rgba(0, 0, 0, 0.1)',
    width: 3,
  }),
});

const selectionStyle = (feature) => {
  const selectedFeature = useItemsStore.getState().highlightItem?.feature;
  if (selectedFeature && feature.get('codgeo') == selectedFeature.get('codgeo')) {
    return baseSelectionStyle;
  }
  return null;
}

export { style, selectionStyle };
