import { Stroke, Style } from 'ol/style';
import LineString from 'ol/geom/LineString';
import { distance } from 'ol/coordinate';

const defaultStyle = new Style({
  stroke: new Stroke({
    color: '#ffcc33',
    width: 6,
  }),
  zIndex: 2
});

const borderStyle = new Style({
  stroke: new Stroke({
    color: 'rbga(0,0,0,0.4)',
    width: 8,
  }),
  zIndex: 1
});

const getColor = (feature) => {
  let color = "#ffcc33";
  if(feature.get('type_circuit') == 'L') color = "#044d38";
  if(feature.get('type_circuit') == 'C') color = "#f1931d";
  if(feature.get('extras') && feature.get('extras').color) color = feature.get('extras').color;
  return color;
}

const style = (feature, resolution) => {
  const geometry = feature.getGeometry();
  const styles = [borderStyle];
  let color = getColor(feature);
  const highlight = feature.get('highlight') ? '#3399CC' : color;

  defaultStyle.setStroke(
    new Stroke({
      color: highlight,
      width: 6,
    })
  );
  styles.push(defaultStyle);

  let lastArrowCoordinates = null;

  geometry.forEachSegment(function (start, end) {

    const dx = end[0] - start[0];
    const dy = end[1] - start[1];
    const tan2 = Math.atan2(dy, dx);
    const rotation = tan2 < 0 ? Math.abs(tan2) : Math.PI + (Math.PI - tan2);

    const arrowSize = Math.max(Math.min(25 * resolution, 300), 30); // Taille de la flèche dépendant de la résolution

    // Branche 1 de la flèche
    const arrowSx = arrowSize * Math.cos(rotation + (2 * Math.PI / 12)); // flèche inclinée à 30°
    const arrowSy = arrowSize * Math.sin(rotation + (2 * Math.PI / 12)); // flèche inclinée à 30°
    // Branche 2 de la flèche
    const arrowEx = arrowSize * Math.cos(rotation - (2 * Math.PI / 12)); // flèche inclinée à 30°
    const arrowEy = arrowSize * Math.sin(rotation - (2 * Math.PI / 12)); // flèche inclinée à 30°
    // Pointe de la flèche
    const line = new LineString([start, end]);
    const middle = line.getCoordinateAt(0.5);

    if (lastArrowCoordinates) {
      const dist = distance(middle, lastArrowCoordinates);
      const distWithResolution = dist * 1.0 / resolution;
      if (distWithResolution < 70) {
        return; // Point trop proche du précédent, on n'affiche pas la flèche
      }
    }

    lastArrowCoordinates = middle;

    // arrows
    styles.push(
      new Style({
        geometry: new LineString([[middle[0]-arrowSx, middle[1]+arrowSy], middle, [middle[0]-arrowEx, middle[1]+arrowEy]]),
        stroke: new Stroke({
          color: 'rbga(0,0,0,0.4)',
          width: 6,
        }),
        zIndex: 0
      }), new Style({
        geometry: new LineString([[middle[0]-arrowSx, middle[1]+arrowSy], middle, [middle[0]-arrowEx, middle[1]+arrowEy]]),
        stroke: new Stroke({
          color: highlight,
          width: 4,
        }),
        zIndex: 2
      })
    );
  });

  return styles;
};

export { style, getColor };
