import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormSelect from '../../../../components/FormSelect';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress, ListSubheader, Box, Chip } from '@mui/material';
import { statutValues } from '../../enums';
import FormInputAutocomplete from '../../../../components/FormInputAutocomplete';
import { getCommunes, getPlanteurByNom } from '../../../parcelle/api';
import { getUsines } from '../../../materiel-agricole/api';
import FormInputDate from '../../../../components/FormInputDate';

const defaultValues = {
  typeEtchrono: [],
  statut: '',
  commune: '',
  planteur: '',
  usine: '',
  debut: null,
  fin: null,
};

const SearchForm = ({ onSubmit, codifRavageur, startDate, endDate }) => {

  defaultValues.debut = startDate.getTime();
  defaultValues.fin = endDate.getTime();

  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    control
  } = methods;

  const [usines, setUsines] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  useEffect(() => {
    (async () => {
      const data = await getUsines();
      const mapUsines = data.map((u) => ({ label: u.nom, value: u.code_usine }));
      setUsines(mapUsines);
    })();
  }, []);

  const famillesObservation = [...new Set( codifRavageur.map(codif => codif.libtype)) ];
  const codifListItem = (famille) => {
    return codifRavageur.filter(codif => codif.libtype == famille).map((option) => (
      <MenuItem key={option.id} value={option.id}>{option.lib}</MenuItem>
    ));
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Rechercher des observations</Typography>

        <Divider />

        <FormSelect
          name="typeEtchrono"
          control={control}
          label="Type d'observation"
          MenuProps={MenuProps}
          multiple
          renderValue={(values) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {values.map((value) => {
                const type = codifRavageur.filter(c => c.id == value)[0];
                return (<Chip key={type.id} label={type.lib} />);
              })}
            </Box>
          )}
        >
          {famillesObservation.map(famille => {
              return [
                  <ListSubheader key={famille}>{famille}</ListSubheader>,
                  codifListItem(famille)
                ]
            })}
        </FormSelect>

        <FormSelect name="statut" control={control} label="Statut" MenuProps={MenuProps}>
          {statutValues.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>

        <FormInputDate
          name="debut"
          control={control}
          label="Date de début"
          inputProps={{ readOnly: true }}
        />

        <FormInputDate
          name="fin"
          control={control}
          label="Date de fin"
          inputProps={{ readOnly: true }}
        />

        <FormInputAutocomplete
          name="planteur"
          control={control}
          label="Planteur"
          placeholder="Tapez un nom de planteur"
          displayProp="nom"
          equalityProp="cpcu"
          source={getPlanteurByNom}
        />

        <FormInputAutocomplete
          name="commune"
          control={control}
          label="Commune"
          placeholder="Tapez une commune"
          displayProp="nom"
          equalityProp="codeInsee"
          source={getCommunes}
        />

        <FormSelect name="usine" control={control} label="Usine de livraison" MenuProps={MenuProps}>
          {usines.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>

        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            return handleSubmit(onSubmit)();
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Rechercher'}
        </Button>
        <Button onClick={() => reset()} variant="outlined" disabled={isSubmitting}>
          Effacer
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default SearchForm;
