import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';
import FormInputDate from '../../../../components/FormInputDate';
import FormSelect from '../../../../components/FormSelect';
import FormInputCheckBox from '../../../../components/FormInputCheckBox';
import { Button, Paper, Typography, Divider, MenuItem, CircularProgress } from '@mui/material';
import { getPictosImagesApi } from '../../api';
import { parse, parseISO, format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { usePrefTechStore } from '../../../../stores/prefTech';
import { getGrue } from '../../../circuit-champ/api';
import { nanoid } from 'nanoid';
import { typepicto } from '../../enums';

const EditForm = ({ onSubmit, pictoProps }) => {
  const parsedTime =
    pictoProps.datefin !== null
      ? parse(format(parseISO(pictoProps.datefin), 'ddMMyyyy'), 'ddMMyyyy', new Date(), {
          locale: fr,
        })
      : null;
  const defaultValues = {
    pictoId: pictoProps.id,
    label: pictoProps.label,
    grue: pictoProps.code_grue != null ? pictoProps.code_grue : '',
    typepicto: pictoProps.type_picto != null ? pictoProps.type_picto : '',
    comment: pictoProps.commentaire,
    enddisplaydate: parsedTime,
    supprimable: pictoProps.supprimable_grue === 'O' ? false : true,
  };
  const methods = useForm({ defaultValues: defaultValues });
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    control,
    watch,
  } = methods;

  const watchGrue = watch('grue');
  const watchTypePicto = watch('typepicto');

  const [pictos, setPictos] = useState([]);
  const [grues, setGrues] = useState([]);
  const [typepictos] = useState(typepicto);
  const [disabledGrue, setDisabledGrue] = useState(false);
  // const onSubmit = (data) => console.log(data);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // init pictos list
  useEffect(() => {
    (async () => {
      const data = await getPictosImagesApi();
      const mapPictos = data.map((item) => ({
        id: item.id,
        src: item.properties.data,
        label: item.properties.name,
      }));
      setPictos(mapPictos);
    })();
  }, []);

  //init grues list
  useEffect(() => {
    const usl = usePrefTechStore.getState().prefs.USL;
    (async () => {
      const data = await getGrue(usl);
      const mapGrue = data.map((item) => ({
        id: nanoid(),
        value: item.numerogrue,
        label: item.numerogrue,
      }));
      setGrues(mapGrue);
    })();
  }, []);

  useEffect(() => {
    if (watchGrue != '') {
      (async () => {
        setValue('typepicto', 'B');
      })();
    }
  }, [setValue, watchGrue]);

  // Disabled select of grue & silo if 'typecircuit' is not equal to B
  useEffect(() => {
    if (watchTypePicto == 'B') {
      setDisabledGrue(false);
    } else if (watchTypePicto != 'B') {
      setValue('grue', '');
      setDisabledGrue(watchTypePicto && watchTypePicto != 'B');
    }
  }, [setValue, watchTypePicto]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Modifier les infos picto</Typography>
        <Divider />
        <FormSelect name="pictoId" control={control} label="Pictos" MenuProps={MenuProps}>
          {pictos.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <img src={`data:image/png;base64,${option.src}`} loading="lazy" alt={option.label} />
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText name="label" control={control} label="Etiquette" />
        <FormInputText name="comment" control={control} label="Commentaire" />
        <FormSelect
          name="typepicto"
          control={control}
          label="Type de picto (facultatif)"
          MenuProps={MenuProps}
        >
          {typepictos.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="grue"
          control={control}
          label="Grue (facultatif)"
          MenuProps={MenuProps}
          inputProps={{ readOnly: disabledGrue }}
        >
          {grues.map((option) => (
            <MenuItem key={option.id} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputDate
          name="enddisplaydate"
          control={control}
          label="Date de fin d'affichage"
          InputProps={{ readOnly: true }}
        />
        <FormInputCheckBox name="supprimable" control={control} label="Bloquer au TG la suppression du picto" />
        <Divider />
        <Typography variant="inherit" color="red">
          {errors?.form?.message}
        </Typography>
        <Button
          onClick={() => {
            const picto = getValues('pictoId');
            if (picto === '') {
              setError('form', {
                type: 'manual',
                message: 'Veuillez sélectionner un picto avant de valider',
              });
            } else {
              clearErrors();
              return handleSubmit(onSubmit)();
            }
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'Modifier le picto'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default EditForm;
