import { axios } from '../../../lib/axios';

const getMaterielAgricole = async () => {
  const response = await axios.get(`/parcelles-api/materiel-agricole`);
  return response;
};

const getEntreprise = async ({ text }) => {
  const { divers } = await axios.get(
    `/divers/entreprise/recherche?chaine=${text.toUpperCase() || ''}`
  );
  return divers;
};

const getUsines = async () => {
  const { divers } = await axios.get('/divers/usine/cu');
  return divers;
};

const getMaterielAgricoleByType = async (type) => {
  const { divers } = await axios.get(`/divers/materielagricole?typemat=${type || ''}`);
  return divers;
};

export { getMaterielAgricole, getEntreprise, getUsines, getMaterielAgricoleByType };
