/* eslint-disable no-unused-vars */
import { Divider, Paper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { METEO_TYPE_HYGRO, METEO_TYPE_PRECIP, METEO_TYPE_TEMP, METEO_TYPE_VENT, useMeteoStore } from '../../../../stores/meteo';
import { format, parse } from 'date-fns';
import { getScale } from '../../scale';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CommuneInfo = ({ feature, days }) => {

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const [precipData, setPrecipData] = useState({labels: [], datasets: []});
  const [hygroData, setHygroData] = useState({labels: [], datasets: []});
  const [tempData, setTempData] = useState({labels: [], datasets: []});
  const [ventData, setVentData] = useState({labels: [], datasets: []});
  const dateDebutCumul = useMeteoStore.getState().dateDebutCumul;
  const [formattedDateDebutCumul, setFormattedDateDebutCumul] = useState('');

  useEffect(() => {
    setFormattedDateDebutCumul(format(parse(dateDebutCumul, 'yyyyMMdd', new Date()), 'dd/MM/yyyy'));


    const precipScale = getScale(METEO_TYPE_PRECIP);
    const hygroScale = getScale(METEO_TYPE_HYGRO);
    const tempScale = getScale(METEO_TYPE_TEMP);
    const ventScale = getScale(METEO_TYPE_VENT);

    setPrecipData({
      labels: days.map(date => format(parse(date, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')),
      datasets: [{
        data: days.map(date => {
          return date in feature.getProperties() ? feature.get(date)[METEO_TYPE_PRECIP+'_value'] : 0.0;
        }),
        backgroundColor: days.map(date => {
          const value = date in feature.getProperties() ? feature.get(date)[METEO_TYPE_PRECIP+'_value'] : 0.0;
          return precipScale(value).css();
        })
      }]
    });
    
    setHygroData({
      labels: days.map(date => format(parse(date, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')),
      datasets: [{
        data: days.map(date => {
          return date in feature.getProperties() ? feature.get(date)[METEO_TYPE_HYGRO+'_value'] : 0.0;
        }),
        backgroundColor: days.map(date => {
          const value = date in feature.getProperties() ? feature.get(date)[METEO_TYPE_HYGRO+'_value'] : 0.0;
          return hygroScale(value).css();
        })
      }]
    });
    
    setTempData({
      labels: days.map(date => format(parse(date, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')),
      datasets: [{
        data: days.map(date => {
          return date in feature.getProperties() ? feature.get(date)[METEO_TYPE_TEMP+'_value'] : 0.0;
        }),
        backgroundColor: days.map(date => {
          const value = date in feature.getProperties() ? feature.get(date)[METEO_TYPE_TEMP+'_value'] : 0.0;
          return tempScale(value).css();
        })
      }]
    });

    setVentData({
      labels: days.map(date => format(parse(date, 'yyyyMMdd', new Date()), 'dd/MM/yyyy')),
      datasets: [{
        data: days.map(date => {
          return date in feature.getProperties() ? feature.get(date)[METEO_TYPE_VENT+'_value'] : 0.0;
        }),
        backgroundColor: days.map(date => {
          const value = date in feature.getProperties() ? feature.get(date)[METEO_TYPE_VENT+'_value'] : 0.0;
          return ventScale(value).css();
        })
      }]
    });

  }, [feature, days, dateDebutCumul]);

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '10px',
        padding: '10px',
      }}
    >
      <Typography variant="h6">{feature.get('libgeo')}</Typography>
      <Typography variant="subtitle2">Données cumulées depuis le {formattedDateDebutCumul} :</Typography>
      <Typography variant="body2" component="div">
        <ul style={{ margin: '0 0 10px 0' }}>
            <li>Cumul pluviométrique : <strong>{feature.get('99999999')[METEO_TYPE_PRECIP+'_description']}</strong></li>
            <li>Moyenne hygrométrique : <strong>{feature.get('99999999')[METEO_TYPE_HYGRO+'_description']}</strong></li>
            <li>Moyenne des températures : <strong>{feature.get('99999999')[METEO_TYPE_TEMP+'_description']}</strong></li>
            <li>Vitesse moyenne du vent : <strong>{feature.get('99999999')[METEO_TYPE_VENT+'_description']}</strong></li>
        </ul>
      </Typography>
      <Divider />
      <Typography variant="subtitle1">Pluviométrie (mm)</Typography>
      <Bar options={chartOptions} data={precipData} />
      <Divider />
      <Typography variant="subtitle1">Hygrométrie (%)</Typography>
      <Bar options={chartOptions} data={hygroData} />
      <Divider />
      <Typography variant="subtitle1">Température (°C)</Typography>
      <Bar options={chartOptions} data={tempData} />
      <Divider />
      <Typography variant="subtitle1">Vitesse du vent (km/h)</Typography>
      <Bar options={chartOptions} data={ventData} />
    </Paper>
  );
};

export default CommuneInfo;
