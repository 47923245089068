import React from 'react';
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  Pagination,
  ListSubheader,
  ListItemButton,
  PaginationItem,
  Badge,
} from '@mui/material';
import InfosCard from '../../InfosCard';
import MaterialIcon from '../../MaterialIcon';

const LayerSectionedListItems = ({
  menuListItems,
  itemsPerPage,
  sectionStatus,
  toggleSectionStatus,
  layerName,
  getColor,
  handleSectionPageChange,
}) => {
  return (
    <Box>
      <List component="div">
        {menuListItems.map((item) => {
          return (
            <div key={item.itemId}>
              <ListSubheader sx={{ p: 0, color: '#000', fontWeight: 'bold' }} component="div">
                <ListItemButton
                  component="div"
                  onClick={toggleSectionStatus.bind(this, item.itemId)}
                >
                  <ListItemIcon>
                    <MaterialIcon
                      icon={sectionStatus[item.itemId].open ? 'ExpandLess' : 'ExpandMore'}
                    />
                  </ListItemIcon>
                  <ListItemText primary={item.section} />
                  {item.showBadge && (
                    <Badge
                      badgeContent={item.childs.length}
                      max={10000}
                      sx={{
                        marginRight: '10px',
                        '& .MuiBadge-badge': {
                          color: '#fff',
                          backgroundColor: item.badgeColor,
                        },
                      }}
                    ></Badge>
                  )}
                </ListItemButton>
              </ListSubheader>
              {sectionStatus[item.itemId].open &&
                item.childs
                  .slice(
                    (sectionStatus[item.itemId].page - 1) * itemsPerPage,
                    sectionStatus[item.itemId].page * itemsPerPage
                  )
                  .map((child) => (
                    <ListItemButton
                      sx={{
                        p: 0,
                        m: 0,
                        cursor: 'auto',
                        backgroundColor: 'transparent',
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                      }}
                      component="div"
                      disableRipple={true}
                      disableTouchRipple={true}
                      key={child.itemId}
                      id={'card-' + child.itemId + '-anchor'}
                    >
                      <InfosCard
                        key={child.itemId}
                        layerName={layerName}
                        color={getColor.bind(this, child)}
                        {...child}
                      />
                    </ListItemButton>
                  ))}
              {sectionStatus[item.itemId].open && sectionStatus[item.itemId].noOfPages > 1 && (
                <>
                  <Pagination
                    sx={{ paddingBottom: '20px' }}
                    count={sectionStatus[item.itemId].noOfPages}
                    page={sectionStatus[item.itemId].page}
                    onChange={handleSectionPageChange}
                    defaultPage={1}
                    data-section-id={item.itemId}
                    color="primary"
                    renderItem={(pageItem) => <PaginationItem {...pageItem} />}
                  />
                  <Divider />
                </>
              )}
            </div>
          );
        })}
      </List>
    </Box>
  );
};

export default LayerSectionedListItems;
