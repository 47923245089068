import { Box, Fab, Slider } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { setTimelineValues, setTimelineVisible, useTimelineStore } from '../../stores/timeline';
import { useLayerStore } from '../../stores/layers';
import { Pause, PlayArrow } from '@mui/icons-material';
import { format } from 'date-fns';

const Timeline = () => {
  const activeLayer = useLayerStore((state) => state.activeLayer);
  const visible = useTimelineStore((state) => state.visible);
  const layerIds = useTimelineStore((state) => state.layerIds);
  const isInterval = useTimelineStore((state) => state.isIntervalForLayerId);
  const valuesForLayerId = useTimelineStore((state) => state.valuesForLayerId);
  const dates = useTimelineStore((state) => state.datesForLayerId);
  const [max, setMax] = useState(100);
  const [value, setValue] = useState([0, 1]);
  const values = useRef([]);
  const [autoPlay, setAutoPlay] = useState(false);
  const autoPlayTimer = useRef(null);
  const autoPlayIndex = useRef(0);

  const valueText = (value) => {
    if (value < values.current.length) {
      return values.current[value].label;
    }
    return '-';
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, value) => {
    if (Array.isArray(value)) {
      const startDate = new Date(values.current[value[0]].value.getTime());
      const endDate = new Date(values.current[value[1]].value.getTime());
      endDate.setHours(23, 59, 59, 999);
      setTimelineValues(activeLayer.get('id'), [startDate, endDate]);
    } else {
      const date = new Date(values.current[value].value.getTime());
      setTimelineValues(activeLayer.get('id'), [date]);
    }
  };

  const onAutoplayClick = () => {
    if (autoPlay) {
      if (autoPlayTimer.current != null) {
        clearInterval(autoPlayTimer.current);
      }
    } else {
      // Met à jour l'index de lecture auto avec la valeur courante du slider
      if (isInterval[activeLayer.get('id')]) {
        autoPlayIndex.current = value[1];
      } else {
        autoPlayIndex.current = value;
      }

      // Si on est déjà à la fin de l'échelle de temps, on redémarre au début
      if (autoPlayIndex.current >= max) {
        autoPlayIndex.current = 0;

        let newValue = 0;
        if (isInterval[activeLayer.get('id')]) {
          newValue = [0, 0]; 
        }
        setValue(newValue);
        handleChangeCommitted(null, newValue);
      }

      autoPlayTimer.current = setInterval(() => {
        autoPlayIndex.current = autoPlayIndex.current + 1;
        if (autoPlayIndex.current > max) {
          clearInterval(autoPlayTimer.current);
          setAutoPlay(false);
          return;
        }

        let newValue = autoPlayIndex.current;
        if (isInterval[activeLayer.get('id')]) {
          newValue = [0, autoPlayIndex.current]; 
        }
        
        setValue(newValue);
        handleChangeCommitted(null, newValue);
      }, 2000);
    }

    setAutoPlay(!autoPlay);
  }

  useEffect(() => {
    let showTimeline = false;
    for (let layerId of layerIds) {
      if (activeLayer && activeLayer.get('id') == layerId) {
        showTimeline = true;
      }
    }
  
    setAutoPlay(false);
    if (autoPlayTimer.current != null) {
      clearInterval(autoPlayTimer.current);
    }
    setTimelineVisible(showTimeline);

    if (!showTimeline || !dates[activeLayer.get('id')] || dates[activeLayer.get('id')].length == 0) {
      return;
    }

    const day = 24 * 60 * 60 * 1000;
    const startDate = dates[activeLayer.get('id')][0];
    const endDate = dates[activeLayer.get('id')][1];

    const daysBetween = Math.ceil(Math.max(1, (endDate.getTime() - startDate.getTime()) / day));

    const valuesAndLabels = [];
    let initStartIndex = -1;
    let initStartDate = null;
    if (valuesForLayerId[activeLayer.get('id')] && valuesForLayerId[activeLayer.get('id')].length > 0) {
      initStartDate = valuesForLayerId[activeLayer.get('id')][0];
    }
    let initEndIndex = -1;
    let initEndDate = null;
    if (valuesForLayerId[activeLayer.get('id')] && valuesForLayerId[activeLayer.get('id')].length > 1) {
      initEndDate = valuesForLayerId[activeLayer.get('id')][1];
    }

    for (let i = 0; i <= daysBetween; i++) {
      const dateMs = startDate.getTime() + (i * day);
      const date = new Date(dateMs);
      const label = date.toLocaleString('fr-FR', { dateStyle: 'short' }).replace(',', '');
      let addDay = false;

      // Timeline by month
      if (daysBetween > 365 && i > 0 && i < daysBetween) {
        if (label.indexOf('01/01') >= 0) {
          addDay = true;
        }
        // Timeline by weeks
      } else if (daysBetween > 60 && i > 0 && i < daysBetween) {
        if (date.toLocaleString('fr-Fr', { weekday: 'long' }).toLowerCase().indexOf('lundi') >= 0) {
          addDay = true;
        }
        // Timeline by days or first/last day
      } else {
        addDay = true;
      }

      if (addDay) {
        valuesAndLabels.push({
          label: label,
          value: date,
        });

        if (initStartDate && format(initStartDate, 'yyyyMMdd') == format(date, 'yyyyMMdd')) {
          initStartIndex = valuesAndLabels.length-1;
        }
        if (initEndDate && format(initEndDate, 'yyyyMMdd') == format(date, 'yyyyMMdd')) {
          initEndIndex = valuesAndLabels.length-1;
        }
      }
    }

    if (initStartIndex < 0) {
      initStartIndex = 0;
    }
    if (initEndIndex < 0) {
      initEndIndex = valuesAndLabels.length - 1;
    }

    values.current = valuesAndLabels;
    setMax(valuesAndLabels.length - 1);
    if (isInterval[activeLayer.get('id')]) {
      setValue([initStartIndex, initEndIndex]);
    } else {
      setValue(initStartIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerIds, activeLayer, dates, isInterval]); // valuesForLayerId excluded

  return (
    <>
      <Box
        onClick={onAutoplayClick}
        sx={{
          position: 'absolute',
          bottom: isMobile ? '85px' : '40px',
          left: isMobile ? '10px' : '20px',
          display: !visible ? 'none' : 'inline-block'
        }}>
        <Fab 
          color="#ffffff" 
          size="small">
          {autoPlay && (<Pause color="primary" />)}
          {!autoPlay && (<PlayArrow color="primary" />)}
        </Fab>
      </Box>
      <Slider
        id="timeline"
        sx={{
          position: 'absolute',
          width: 'unset',
          display: !visible ? 'none' : 'inline-block',
          bottom: isMobile ? '83px' : '45px',
          left: isMobile ? '65px' : '95px',
          right: isMobile ? '45px' : '190px',
          '& .MuiSlider-track': {
            height: 6,
          },
          '& .MuiSlider-rail': {
            color: '#000000',
            height: 6,
          },
          '& .MuiSlider-valueLabel': {
            bgcolor: 'primary.main',
            opacity: 0.9,
            padding: '2px 5px'
          },
          '& .MuiSlider-thumb': {
            height: 24,
            width: 24,
            bgcolor: '#ffffff',
            border: '3px solid currentColor'
          }
        }}
        color="primary"
        getAriaLabel={() => 'Test'}
        getAriaValueText={valueText}
        valueLabelFormat={valueText}
        min={0}
        step={1}
        max={max}
        defaultValue={[0, 1]}
        valueLabelDisplay="on"
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        marks
      />
    </>
  );
};

export default Timeline;
