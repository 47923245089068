import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import FormInputText from '../../../../components/FormInputText';
import FormInputDate from '../../../../components/FormInputDate';
import FormSelect from '../../../../components/FormSelect';
import FormInputCheckBox from '../../../../components/FormInputCheckBox';
import { Button, Paper, Typography, Divider, CircularProgress, MenuItem } from '@mui/material';
import { getSiloEmplacements, getParametrage } from '../../api';
import { parse } from 'date-fns';
import { fr } from 'date-fns/locale';

const limit = (val, max) => {
  if (val.length === 1 && val[0] > max[0]) {
    val = '0' + val;
  }

  if (val.length === 2) {
    if (Number(val) === 0) {
      val = '00';

      //this can happen when user paste number
    } else if (val > max) {
      val = max;
    }
  }

  return val;
};

const EditForm = ({ onSubmit, siloProps }) => {
  const defaultValues = {
    id: siloProps.id,
    comemps: siloProps.comEmps,
    datarrache:
      siloProps.dateArrachage !== '00000000'
        ? parse(siloProps.dateArrachage, 'ddMMyyyy', new Date(), { locale: fr })
        : '',
    datmad: siloProps.dateMad
      ? parse(siloProps.dateMad, 'ddMMyyyy', new Date(), { locale: fr })
      : '',
    surfarrachee: siloProps.surfaceArrache
      ? siloProps.surfaceArrache.replace('Ha', '').padStart(6, '0')
      : '',
    surfprevue: siloProps.surfacePrevue,
    datsaiweb: siloProps.datSaiWeb
      ? parse(siloProps.datSaiWeb, 'ddMMyyyy', new Date(), { locale: fr })
      : '',
    commweb: siloProps.commWeb,
    vupartech: siloProps.vuParTechnicien === 'O' ? true : false,
    irriguee: siloProps.parcelleIrriguee,
    codeemps: (siloProps.codeEmps && siloProps.codeEmps != '00') ? siloProps.codeEmps : '',
    tonprev: siloProps.tonnageUtilePrevu,
    usl: siloProps.usl,
    regroupe: siloProps.regroupe,
    camprevus: siloProps.camprevus
      ? siloProps.nbCamPrevu.replace(/(\d+)([A-Za-z]+)/g, '$1 $2')
      : '', // add a space between numbers and characters
    kms: siloProps.kms ? siloProps.kms.replace(/(\d+)([A-Za-z]+)/g, '$1 $2') : '',
    ordre: siloProps.ordre,
    location: siloProps.posX + ', ' + siloProps.posY,
    conjonct: siloProps.indConjonct,
    postplanning: siloProps.indPostp,
    deterre: siloProps.deterre,
    abacher: siloProps.aBacher,
    entbachage: siloProps.entBachage,
    aniveler: siloProps.aNiveler,
    entnivelage: siloProps.entNivelage,
    bachages: siloProps.typeBachage,
    nivelages: siloProps.typeNivelage,
  };

  const methods = useForm({ defaultValues: defaultValues });

  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    // watch,
    control,
  } = methods;
  const [emplacements, setEmplacements] = useState([]);
  const [typeBachages, setTypeBachages] = useState([]);
  const [typeNivelages, setTypeNivelages] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  // debug
  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => console.log(value, name, type));
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  // init emplacements list
  useEffect(() => {
    (async () => {
      const { comEmps = '', codeEmps = '' } = siloProps;
      const codeOnly = parseInt(codeEmps.split('#').pop());
      const data = await getSiloEmplacements({ codeInsee: comEmps.split('#').pop() });
      const mapEmplacements = data.map((e) => ({ label: e.emplib, value: e.empnum }));
      setEmplacements(mapEmplacements);

      if (codeOnly !== 0) {
        setValue('codeemps', codeOnly, true);
      }
    })();
  }, [setValue, siloProps]);

  // init typeNivelage and typeBachage list
  useEffect(() => {
    (async () => {
      const dataBachages = await getParametrage('BAC', 'W');
      const mapBachahes = dataBachages.map((b) => ({ label: b.libelle, value: b.arg2 }));
      setTypeBachages(mapBachahes);

      const dataNivelages = await getParametrage('NIV', 'W');
      const mapNivelages = dataNivelages.map((b) => ({ label: b.libelle, value: b.arg2 }));
      setTypeNivelages(mapNivelages);
    })();
  }, []);
  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      <FormProvider {...methods}>
        <Typography variant="h6">Modifier un silo</Typography>
        <Divider />
        <FormInputCheckBox name="vupartech" control={control} label="Vu par technicien" />
        <FormInputText name="commweb" control={control} label="Commentaires" />
        <Divider />
        <Typography variant="subtitle1">Caractéristique parcelle</Typography>
        <FormInputDate
          name="datarrache"
          control={control}
          label="Date d'arrachage"
          rules={{ required: true }}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="surfarrachee"
          control={control}
          label="Surface arrachée"
          number={true}
          format={(val) => {
            let int = limit(val.substring(0, 3), '999');
            let dec = limit(val.substring(3, 5), '99');

            return (
              (int.length ? int.padEnd(3, '_') : '___') +
              (dec.length ? ',' + dec.padEnd(2, '_') : ',__') +
              'Ha'
            );
          }}
          allowLeadingZeros={true}
          allowNegative={false}
        />
        <FormInputText
          name="surfprevue"
          control={control}
          label="Surface prévue"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputDate name="datmad" control={control} label="Date MAD" readOnly={true} />
        <FormInputText
          name="irriguee"
          control={control}
          label="Irriguée ?"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="regroupe"
          control={control}
          label="Regoupé ?"
          InputProps={{
            readOnly: true,
          }}
        />
        <Divider />
        <Typography variant="subtitle1">Caractéristique silo</Typography>
        <FormSelect name="codeemps" control={control} label="Emplacements" MenuProps={MenuProps}>
          {emplacements.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText
          name="tonprev"
          control={control}
          label="Tonnage utile prévu"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="usl"
          control={control}
          label="Code usine de livraison"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="kms"
          control={control}
          label="Distance"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="ordre"
          control={control}
          label="Ordre d'enlèvement"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="location"
          control={control}
          label="Coordonnées geo."
          InputProps={{
            readOnly: true,
          }}
        />
        <Typography variant="subtitle1">Bachage / Nivelage</Typography>
        <FormSelect
          name="bachages"
          control={control}
          label="Type de bachages"
          MenuProps={MenuProps}
        >
          {typeBachages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText
          name="abacher"
          control={control}
          label="A bacher"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="entbachage"
          control={control}
          label="Entreprise de bachage"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormSelect
          name="nivelages"
          control={control}
          label="Type de nivelages"
          MenuProps={MenuProps}
        >
          {typeNivelages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </FormSelect>
        <FormInputText
          name="aniveler"
          control={control}
          label="A niveler"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="entnivelage"
          control={control}
          label="Entreprise de nivelage"
          InputProps={{
            readOnly: true,
          }}
        />
        <Typography variant="subtitle1">Indemnités</Typography>
        <FormInputText
          name="conjonct"
          control={control}
          label="Conjoncturelle"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="postplanning"
          control={control}
          label="Post planning"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputText
          name="deterre"
          control={control}
          label="Déterrage fictif"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormInputDate
          name="datsaiweb"
          control={control}
          label="Date de saisie web"
          readOnly={true}
        />

        <Divider />
        <Typography variant="inherit" color="red">
          {errors.datarrache && "Veuillez renseigner une date d'arrachage."}
        </Typography>
        <Button
          onClick={() => {
            return handleSubmit(onSubmit)();
          }}
          variant="contained"
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress color="inherit" size={20} /> : 'MODIFIER'}
        </Button>
      </FormProvider>
    </Paper>
  );
};

export default EditForm;
