const typeMaterielAgricole = [
  { value: 'GRU', label: 'Grue', img: 'img/materiel/CU_chantier.svg', img_engin_A: 'img/materiel/CU_avaleur.svg' },
  { value: 'NIV', label: 'Machine nivelage', img: 'img/materiel/CU_nivelage.svg' },
  { value: 'BAC', label: 'Machine bachage', img: 'img/materiel/CU_bachage.svg' },
  { value: 'RLZ', label: 'Récolteuse', img: 'img/materiel/CU_recolteuse.svg' },
  { value: 'ALZ', label: 'Andaineuse', img: 'img/materiel/CU_andaineuse.svg' },
  { value: 'FLZ', label: 'Faucheuse', img: 'img/materiel/CU_faucheuse.svg' },
  { value: 'EPA', label: 'Epandeur', img: 'img/materiel/CU_epandage.svg' },
];

export { typeMaterielAgricole };
