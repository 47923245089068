import { create } from 'zustand';

const useSideBarStore = create(() => ({
  visible: false,
}));

const toggleSideBar = (visible) =>
  useSideBarStore.setState(() => ({
    visible,
  }));

export { useSideBarStore, toggleSideBar };
