const calcEnd = (number, iteration) => {
  let result = 0;
  for (let i = 0; i < iteration; i++) {
    result = result + (i + 1) * number;
  }
  return result;
};

const snailMatrix = (size, distance) => {
  let level = 1,
    length = size;

  while (calcEnd(8, level) < length) {
    size = parseFloat(size) / 8.0;
    level++;
  }

  let matrix = new Array(length),
    moveX = distance,
    moveY = distance;

  // Init the matrix for the automatic position algorithm
  for (let i = 0; i < level; i++) {
    let levelTmpX = i + 1,
      levelTmpY = i + 1,
      yTop = false,
      yBottom = false,
      xLeft = false,
      xRight = false,
      start = calcEnd(8, i),
      end = Math.min(calcEnd(8, i + 1), length);

    for (let j = start; j < end; j++) {
      if (!yTop) {
        matrix[j] = { moveX: moveX * (levelTmpX - 1) * -1, moveY: moveY * levelTmpY };
        if (levelTmpX == i * -1) {
          yTop = true;
          levelTmpX = i + 1;
        } else levelTmpX--;
      } else if (!xRight) {
        matrix[j] = { moveX: moveX * levelTmpX, moveY: moveY * (levelTmpY - 1) };
        if (levelTmpY - 1 == i * -1) {
          xRight = true;
          levelTmpY = i + 1;
        } else levelTmpY--;
      } else if (!yBottom) {
        matrix[j] = { moveX: moveX * levelTmpX, moveY: moveY * levelTmpY * -1 };
        if (levelTmpX == (i + 1) * -1) {
          yBottom = true;
          levelTmpX = i + 1;
          levelTmpY = i * -1;
        } else levelTmpX--;
      } else if (!xLeft) {
        matrix[j] = { moveX: moveX * levelTmpX * -1, moveY: moveY * levelTmpY };
        if (levelTmpY == i + 1) {
          xLeft = true;
          levelTmpY = i + 1;
        } else levelTmpY++;
      }
    }
  }
  return matrix;
};

export default snailMatrix;
