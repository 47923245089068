import React, { useState, useEffect } from 'react';
import { Paper, IconButton, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import debounce from '../../utils/debounce';
import { useViewStore } from '../../stores/view';
import { getOrderedCommunesFromText } from '../../features/geocoder';
import { fromWGS84 } from '../../utils/webmercator';
import SideMenuLayout from '../SideMenu/SideMenuLayout';
import { isMobile } from 'react-device-detect';

const SearchBox = ({ sx }) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const loading = open && items.length === 0;

  const [showMainMenu, setShowMainMenu] = useState(false);
  // const [editPrefs, setEditPrefs] = useState([false, false, false, false, false]);

  const debouncedChangeHandler = debounce(async (e) => {
    const newOptions = await getOrderedCommunesFromText({ text: e.target.value });
    setOpen(true);
    setItems(newOptions);
  }, 1000);

  const moveToXY = ({ coordinates }) => {
    const wmCoords = fromWGS84(coordinates[0], coordinates[1]);
    useViewStore.getState().setCenter(wmCoords);
    useViewStore.getState().setZoom(15);
  };

  useEffect(() => {
    if (!open) {
      setItems([]);
    }
  }, [open]);

  return (
    <>
      <Paper
        sx={{
          zIndex: 3,
          m: '5px',
          p: '3px 4px 0px 2px',
          display: 'flex',
          position: 'absolute',
          top: '0px',
          width: !isMobile ? `240px` : `calc(100% - 100px)`,
          maxWidth: '240px',
          ...sx,
        }}
        className="SearchBox" //Ajout d'une classe pour cacher lors de l'impression
      >
        <IconButton onClick={() => setShowMainMenu(true)} sx={{ p: '10px' }} aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Autocomplete
          freeSolo
          sx={{ ml: 1, flex: 1 }}
          open={open}
          onChange={(event, value) => {
            if (value) {
              moveToXY({ coordinates: value.centre.coordinates });
            }
          }}
          onClose={() => setOpen(false)}
          options={items}
          getOptionLabel={(option) => option.nom + ' - ' + option?.departement?.code}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(e) => debouncedChangeHandler(e)}
              placeholder="Aller sur la commune"
              variant="outlined"
              size="small"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Paper>
      <SideMenuLayout
        anchor="left"
        showMainMenu={showMainMenu}
        setShowMainMenu={setShowMainMenu}
      />
    </>
  );
};

export default SearchBox;
