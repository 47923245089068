import { axios } from '../../../lib/axios';

const getPrefs = async () => {
  const { divers } = await axios.get(`/divers/technicien/prefs`);
  return divers;
};

const updatePrefs = async (prefs) => {
  const response = await axios.put(`divers/technicien/prefs`, prefs);
  return response;
};

export { getPrefs, updatePrefs };
