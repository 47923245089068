import { CLIENT_ID, CUWS_TOKEN_KEY } from '../config';
const Gstjet = require('gstjet/Gstjet');

function deferred() {
  let resolve, reject;
  const promise = new Promise((res, rej) => {
    [resolve, reject] = [res, rej];
  });
  return { promise, reject, resolve };
}

const storage = {
  getToken: () => {
    const defer = deferred();

    Gstjet.getLsJet.bind({
      key: CUWS_TOKEN_KEY,
      deCryJet: Gstjet.deCryJet,
    })(CLIENT_ID, (jeton) => {
      if (jeton) {
        jeton.token_type = jeton.token_type.trim();
        jeton.access_token = jeton.access_token.trim();
      }
      defer.resolve(jeton);
    });

    return defer.promise;
  },
  clearToken: () => {
    window.localStorage.removeItem(CLIENT_ID);
  },
};

export default storage;
