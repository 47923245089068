import React from "react";
import { useAlertDialogStore } from "../../stores/alertDialog";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const AlertDialog = () => {
  const state = useAlertDialogStore();

  return (
    <Dialog
      open={state.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {state.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {state.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {state.buttons.map((button, index) => (
          <Button key={index} onClick={button.handler}>{button.title}</Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
