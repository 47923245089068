/**
 * 
 * @param {byte[]} buffer 
 * @param {String} fileName 
 * @returns 
 */
const fileBufferToBase64 = (buffer, fileName) => {
  return new Promise((resolve) => {
    var reader = new window.FileReader();
      reader.readAsDataURL(buffer); 
      reader.onload = function() {
        let base64 = reader.result;
        if(fileName && (fileName.toUpperCase().endsWith('.JPG') || fileName.toUpperCase().endsWith('.JPG'))) {
          base64 = base64.replace('application/pdf', 'image/jpeg');
        }
        if(fileName && (fileName.toUpperCase().endsWith('.TIF') || fileName.toUpperCase().endsWith('.TIFF'))) {
          base64 = base64.replace('application/pdf', 'image/tiff');
        }
        if(fileName && fileName.toUpperCase().endsWith('.PNG')) {
          base64 = base64.replace('application/pdf', 'image/png');
        }
        if(fileName && fileName.toUpperCase().endsWith('.BMP')) {
          base64 = base64.replace('application/pdf', 'image/bmp');
        }
        if(fileName && fileName.toUpperCase().endsWith('.GIF')) {
          base64 = base64.replace('application/pdf', 'image/gif');
        }
        resolve(base64);
      }     
  });
}

/**
 * Retourne le chemin de base pour l'affichage des images 
 * @returns String
 */
const imageBasePath = () => {
  const url = new URL(window.location.href);
  let path = url.origin + url.pathname;
  if (!path.endsWith('/')) {
    path += '/';
  }
  return path;
};

export { fileBufferToBase64, imageBasePath };
