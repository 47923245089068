import { Fill, Style, Text, Circle } from 'ol/style';
import Stroke from 'ol/style/Stroke';
import { RavageurMapIcon } from '../icon/ravageurIcon';
/*const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';*/
const fontCluster = 'bold' + ' ' + '12px' + '/' + '1' + ' ' + 'Arial';

const ravageurIcon = {};

const iconForRavageur = (feature) => {
  let imageColor = '#000000';
  let pointColor = '';

  if(feature.get('dateval') == '00000000' && feature.get('profilval') == '') {
    imageColor = '#ff0000';
  } else {
    const codifInfos = feature.get('codif_list_infos');
    imageColor = codifInfos && codifInfos.length > 0 && codifInfos[0].couleur ? '#'+codifInfos[0].couleur : '#000000';
    pointColor = codifInfos && codifInfos.length > 0 && codifInfos[0].couleurr ? '#'+codifInfos[0].couleurr : '';
  }
  
  if(!ravageurIcon[imageColor+'_'+pointColor]) {
    ravageurIcon[imageColor+'_'+pointColor] = RavageurMapIcon(imageColor, pointColor);
  }
  return ravageurIcon[imageColor+'_'+pointColor];
};

const ravStyle = new Style({
  image: null,
  /*text: new Text({
    text: '',
    fill: new Fill({
      color: '#ff0000',
    }),
    offsetY: 25,
    font: font,
  }),*/
});

const clusterStyle = new Style({
  image: new Circle({
    radius: 12,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#000',
    }),
  }),
  text: new Text({
    text: '',
    offsetY: 1,
    font: fontCluster,
    fill: new Fill({
      color: '#fff',
    }),
  }),
});

const clusterAValiderStyle = new Style({
  image: new Circle({
    radius: 12,
    stroke: new Stroke({
      color: '#fff',
    }),
    fill: new Fill({
      color: '#f00',
    }),
  }),
  text: new Text({
    text: '',
    offsetY: 1,
    font: fontCluster,
    fill: new Fill({
      color: '#fff',
    }),
  }),
});

const style = (feature) => {
  const features = feature.get('features');

  if (features.length > 1) {
    const style = features.filter(f => {
      const r = f.getProperties();
      return r.dateval == '00000000' && r.profilval == '' && r.datesup == '00000000' && r.profilsup == '';
    }).length > 0 ? clusterAValiderStyle : clusterStyle;
    style.getText().setText(features.length.toString());
    return style;
  }

  feature = features[0];

  /*ravStyle.getText().setText(feature.get('chrono'));*/
  ravStyle.setImage(iconForRavageur(feature));

  return ravStyle;
};

export { style };
