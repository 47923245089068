import { getColor } from "../style";

const circuitIconSrc = (feature) => {

  const color = getColor(feature);

  var svg = `<?xml version="1.0" encoding="utf-8"?>
  <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32" height="32"
     viewBox="0 0 31.18 31.18" style="enable-background:new 0 0 31.18 31.18;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:${color};}
    .st1{fill:#FFFFFF;}
  </style>
  <ellipse transform="matrix(0.7071 -0.7071 0.7071 0.7071 -6.4578 15.5906)" class="st0" cx="15.59" cy="15.59" rx="15.59" ry="15.59"/>
  <g>
    <path class="st1" d="M21.12,24.76c-1.49-0.18-3.05-0.33-4.6-0.56c-1.83-0.28-3.66-0.59-5.47-0.97c-1.64-0.34-3.26-0.78-4.73-1.59
      c-0.55-0.3-1.11-0.68-1.5-1.16c-0.83-1.02-0.44-2.39,0.81-3.16c1.03-0.63,2.18-0.92,3.35-1.13c1.37-0.24,2.76-0.41,4.13-0.68
      c0.59-0.12,1.19-0.34,1.72-0.62c0.74-0.4,0.74-1.22,0-1.61c-0.57-0.3-1.2-0.5-1.82-0.68c-0.58-0.16-1.18-0.23-1.76-0.36
      c-0.11-0.03-0.2-0.16-0.3-0.24c0.11-0.06,0.24-0.19,0.34-0.17c1.34,0.2,2.71,0.3,3.94,0.94c1.12,0.58,1.18,1.98,0.11,2.66
      c-1.08,0.68-2.31,0.86-3.53,1.09c-1.52,0.29-3.04,0.6-4.54,0.98c-0.48,0.12-0.92,0.48-1.31,0.81c-0.58,0.49-0.54,1.32,0.02,1.82
      c0.58,0.52,1.3,0.77,2.02,1c2.11,0.68,4.28,1.06,6.47,1.35c2.24,0.3,4.48,0.55,6.72,0.83c0.2,0.03,0.41,0.09,0.6,0.17
      c0.31,0.12,0.49,0.36,0.42,0.71c-0.06,0.33-0.3,0.51-0.63,0.56C21.44,24.76,21.31,24.75,21.12,24.76z"/>
    <path class="st1" d="M11.06,5.29C9.92,5.29,9,6.22,9,7.36c0,1.08,1.82,3.77,1.89,3.88c0.04,0.06,0.1,0.09,0.17,0.09
      c0.07,0,0.13-0.03,0.17-0.09c0.08-0.11,1.89-2.8,1.89-3.88C13.13,6.22,12.2,5.29,11.06,5.29z M11.05,8.4
      c-0.54,0-0.98-0.44-0.98-0.98c0-0.54,0.44-0.98,0.98-0.98c0.54,0,0.98,0.44,0.98,0.98C12.03,7.96,11.59,8.4,11.05,8.4z"/>
    <path class="st1" d="M22.06,10.98c-2.18,0-3.95,1.77-3.95,3.95c0,2.06,3.47,7.2,3.62,7.42c0.07,0.11,0.2,0.18,0.33,0.18
      c0.13,0,0.26-0.07,0.33-0.18c0.15-0.22,3.62-5.36,3.62-7.42C26.01,12.75,24.24,10.98,22.06,10.98z M22.04,16.93
      c-1.04,0-1.88-0.84-1.88-1.88s0.84-1.88,1.88-1.88s1.88,0.84,1.88,1.88S23.07,16.93,22.04,16.93z"/>
  </g>
  </svg>`;

  return 'data:image/svg+xml;base64,'+btoa(svg);
}

export { circuitIconSrc }