import Feature from 'ol/Feature';
import { GeoJSON } from 'ol/format';
import { format, isValid } from 'date-fns';

const updateGeometry = ({ feature, updateKms }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  const f = feature.getProperties();

  if (f.doublon === true) {
    updateFeature.set('siloId', f.id ? f.id : '');
    updateFeature.set('usl', f.usl ? f.usl : '');
    updateFeature.set('codeModif', 'E');
    updateFeature.set('posX', 0);
    updateFeature.set('posY', 0);
    updateFeature.set('etiqPosX', feature.getGeometry().getCoordinates()[0]);
    updateFeature.set('etiqPosY', feature.getGeometry().getCoordinates()[1]);
    updateFeature.set('saisieParTechnicien', 'O');
  } else {
    updateFeature.set('siloId', f.id ? f.id : '');
    updateFeature.set('usl', f.usl ? f.usl : '');
    updateFeature.set('codeModif', updateKms ? 'K' : 'G');
    updateFeature.set('posX', feature.getGeometry().getCoordinates()[0]);
    updateFeature.set('posY', feature.getGeometry().getCoordinates()[1]);
    updateFeature.set('etiqPosX', 0);
    updateFeature.set('etiqPosY', 0);
    updateFeature.set('saisieParTechnicien', 'O');
  }

  const featureCollection = new GeoJSON().writeFeaturesObject([updateFeature]);

  return featureCollection;
};

const updateGeometryProperties = ({ feature, data }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  updateFeature.set('siloId', data.id ? data.id : '');
  updateFeature.set('usl', data.usl ? data.usl : '');
  updateFeature.set('codeInsee', data.comemps.length === 5 ? data.comemps + '00' : data.comemps);
  updateFeature.set('emplacement', data.codeemps ? data.codeemps : '');
  updateFeature.set(
    'dateArrachage',
    isValid(data.datarrache) ? format(data.datarrache, 'ddMMyyyy') : ''
  );
  data.surfarrachee = data.surfarrachee.replace(',', '');
  data.surfarrachee = data.surfarrachee.replace('Ha', '');
  data.surfarrachee = data.surfarrachee.replaceAll('_', '0');
  updateFeature.set('surfaceArrachee', data.surfarrachee);
  updateFeature.set('typeNivelage', data.nivelages);
  updateFeature.set('typeBachage', data.bachages);
  if (data.commweb) {
    updateFeature.set(
      'commWeb',
      feature.getProperties().commWeb === data.commweb ? '' : data.commweb
    );
  } else {
    updateFeature.set('commWeb', '');
  }
  updateFeature.set('vuParTechnicien', data.vupartech ? 'O' : 'N');
  updateFeature.set('saisieParTechnicien', 'O');

  const featureCollection = new GeoJSON().writeFeaturesObject([updateFeature]);

  return featureCollection;
};

const deleteGeometry = async ({ feature }) => {
  const updateFeature = new Feature();

  updateFeature.setId(feature.getId());
  updateFeature.setGeometry(feature.getGeometry());
  updateFeature.setProperties(feature.getProperties());

  const f = feature.getProperties();

  updateFeature.set('siloId', f.id ? f.id : '');
  updateFeature.set('usl', f.usl ? f.usl : '');
  updateFeature.set('codeModif', 'E');
  updateFeature.set('etiqPosX', 0);
  updateFeature.set('etiqPosY', 0);
  updateFeature.set('posX', feature.getGeometry().getCoordinates()[0]);
  updateFeature.set('posY', feature.getGeometry().getCoordinates()[1]);
  updateFeature.set('saisieParTechnicien', 'O');

  const featureCollection = new GeoJSON().writeFeaturesObject([updateFeature]);

  return featureCollection;
};

const createSiloLieGeometry = async ({ f, fProps }) => {
  const createGeometry = new Feature();

  createGeometry.setId(fProps.id);
  createGeometry.setGeometry(fProps.geometry);
  createGeometry.setProperties(fProps);

  createGeometry.set('siloId', fProps.id);
  createGeometry.set('usl', fProps.usl);
  createGeometry.set('codeModif', 'E');
  createGeometry.set('etiqPosX', f.getGeometry().getCoordinates()[0]);
  createGeometry.set('etiqPosY', f.getGeometry().getCoordinates()[1]);
  createGeometry.set('posX', fProps.geometry.getCoordinates()[0]);
  createGeometry.set('posY', fProps.geometry.getCoordinates()[1]);
  createGeometry.set('saisieParTechnicien', 'O');

  const featureCollection = new GeoJSON().writeFeaturesObject([createGeometry]);

  return featureCollection;
};
export { updateGeometry, updateGeometryProperties, deleteGeometry, createSiloLieGeometry };
