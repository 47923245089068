import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import { props } from '../../../features/aire-stockage';
import { imageBasePath } from '../../../utils/image';

const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';
const fontCluster = 'bold' + ' ' + '14px' + '/' + '1' + ' ' + 'Arial';

const style = (feature) => {
  const features = feature.get('features');
  if (features.length > 1) {
    // cluster feature
    return new Style({
      image: new CircleStyle({
        radius: 14,
        stroke: new Stroke({
          color: '#fff',
        }),
        fill: new Fill({
          color: props.color ? props.color : '#FF5E33',
        }),
      }),
      text: new Text({
        text: features.length.toString(),
        offsetY: 1,
        font: fontCluster,
        fill: new Fill({
          color: '#fff',
        }),
      }),
    });
  } else {
    if (features[0].get('codeInsee') !== undefined) {
      // single feature
      return new Style({
        image: new Icon({
          src:
            imageBasePath() +
            'img/stockage/CU_stockage.svg',
        }),
        text: new Text({
          text: features[0].get('codeInsee').substring(2) + '-' + features[0].get('numeroAire'),
          fill: new Fill({
            color: '#D62828',
          }),
          stroke: new Stroke({
            color: '#ffffff',
            width: 3
          }),
          offsetY: -24,
          font: font,
        }),
      });
    }
  }
};

export { style };
