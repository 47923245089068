import { SvgIcon } from "@mui/material";
import { Icon } from 'ol/style';
import React from "react";

const RavageurIcon = (props) => {
    return (
      <SvgIcon viewBox="0 0 24 24" sx={{width: '32px', height: '32px'}}>
        <g>
            <rect style={{fill: '#ffffff', fillOpacity: 0.01}} x="0" y="0" width="100%" height="100%" />
            <path fill={props.imageColor} stroke="#000000" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
        </g>
        {props.pointColor && (
            <ellipse strokeWidth="0" ry="2.68965" rx="2.62069" id="svg_5" cy="9.02067" cx="12" stroke={props.pointColor} fill={props.pointColor} />
        )}
      </SvgIcon>
    );
  }

const RavageurMapIcon = (imageColor, pointColor) => {

  var ellipse = !pointColor ? '' : '<ellipse stroke-width="0" ry="2.68965" rx="2.62069" id="svg_5" cy="9.02067" cx="12" stroke="'+pointColor+'" fill="'+pointColor+'"/>';

  var svg = `<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 25.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg width="36" height="36" version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 24 24" style="enable-background:new 0 0 40 40;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#ffffff; fill-opacity: 0.01;}
    .st1{fill:${imageColor}; stroke: #000000;}
  </style>
  <g>
    <rect class="st0" x="0" y="0" width="100%" height="100%" />
    <path class="st1" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"></path>
  </g>
  ${ellipse}
  </svg>`;

  return new Icon({
    src: 'data:image/svg+xml;base64,'+btoa(svg),
    opacity: 1,
    scale: 1.0
  });
}

export { RavageurIcon, RavageurMapIcon }