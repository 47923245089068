import { geoApiGouv } from '../../../lib/axios';

const getCommunesFromText = async ({ text }) => {
  const data = await geoApiGouv.get(
    `/communes?nom=${text}&fields=code,nom,centre,departement&limit=20`
  );
  return data;
};

export { getCommunesFromText };
