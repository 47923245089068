import React from 'react';
import './App.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AreaSearchButton from './components/AreaSearchButton';
import Map from './components/Map';
import SearchBox from './components/SearchBox';
import Layers from './components/Layers';
import SideBar from './components/SideBar';
import PDFMultiPagesViewer from './components/PDFMultiPagesViewer';
import { Slide } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { Paper } from '@mui/material';
import ImageDialog from './components/ImageDialog';
import WaitDialog from './components/WaitDialog';
import AlertDialog from './components/AlertDialog';
import { isMobile } from 'react-device-detect';
import GeolocationButton from './components/GeolocationButton';
import Timeline from './components/Timeline';
import Scale from './features/meteo/components/Scale';

function App() {
  return (
    <Paper
      sx={{
        '.notistack-SnackbarContainer': {
          marginTop: isMobile ? 11 : 6,
        },
        '.notistack-MuiContent': {
          padding: isMobile ? '2px 10px' : '6px 16px',
        },
      }}
    >
      <SnackbarProvider
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        TransitionComponent={Slide}
        preventDuplicate
        dense
      >
        <AreaSearchButton />
        <GeolocationButton />
        <SideBar />
        <Map />
        <SearchBox />
        <Layers />
        <Timeline />
        <Scale />
        <PDFMultiPagesViewer />
        <ImageDialog />
        <WaitDialog />
        <AlertDialog />
      </SnackbarProvider>
    </Paper>
  );
}

export default App;
