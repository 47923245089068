import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Paper, Typography, Divider, CircularProgress } from '@mui/material';
import FormInputText from '../../../../components/FormInputText';

const EditForm = ({ onSubmit, prefTechProps }) => {
  const defaultValues = {
    label: prefTechProps.COUCHECARTO,
  };
  const methods = useForm({ defaultValues: defaultValues });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = methods;

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
      }}
    >
      {prefTechProps.COUCHECARTO ? (
        <Typography variant="h6">Modifier la préférence</Typography>
      ) : (
        <Typography variant="h6">Ajouter une préférence</Typography>
      )}

      <Divider />

      <FormInputText name="label" control={control} label="Nom préférence" inputProps={{ maxLength: 20 }} />

      <Button onClick={handleSubmit(onSubmit)} variant="contained" disabled={isSubmitting}>
        {isSubmitting ? (
          <CircularProgress color="inherit" size={20} />
        ) : prefTechProps.COUCHECARTO ? (
          'Modifier'
        ) : (
          'Ajouter'
        )}
      </Button>
    </Paper>
  );
};

export default EditForm;
