import { Fill, Style, Stroke } from 'ol/style';
import CircleStyle from 'ol/style/Circle';

const positionStyle = [
  new Style({
    image: new CircleStyle({
      radius: 13,
      fill: new Fill({
        color: 'rgba(0, 0, 0, 0.5)',
      }),
    }),
  }), new Style({
    image: new CircleStyle({
      radius: 10,
      fill: new Fill({
        color: '#1976d2',
      }),
      stroke: new Stroke({
        color: '#fff',
        width: 3,
      }),
    }),
  })
];



export { positionStyle };
