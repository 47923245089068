import { Circle as CircleStyle, Fill, Stroke, Style, Text, Icon } from 'ol/style';
import LineString from 'ol/geom/LineString';
import { parse, format } from 'date-fns';
import { props } from '../../../features/silo';
import { statutBach, statutNiv } from '../enums';
//import { toSize } from 'ol/size';
import { getViewZoom } from '../../../stores/view';
import { imageBasePath } from '../../../utils/image';
import { siloImageSrc } from '../icon';

const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';

// default silo style
const style = (feature) => {
  const features = feature.get('features');
  const colorSilo = props.color ? props.color : '#3399CC';
  // const highlight = features[0].get('highlight') ? '#BADA55' : colorSilo;
  let arrayStyleSilo = [];

  if (features.length > 1) {
    // cluster feature
    let styleCluster = new Style({
      image: 
      new Icon({
        scale: 1,
        src: siloImageSrc(features),
      }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: '#000000',
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3
        }),
        font: font,
        scale: 1,
        offsetY: 26,
      }),
    });
    //reSizeIcon(styleCluster);
    arrayStyleSilo.push(styleCluster);
  } else {
    // single feature
    if (
      parseInt(features[0].get('etiqPosX')) !== 0 &&
      parseInt(features[0].get('etiqPosY')) !== 0
    ) {
      // silo doublon
      // draw linestring between the 2 features by return 2 style in an array (one for the linestring, and one for the feature)
      const startPoint = [parseInt(features[0].get('posX')), parseInt(features[0].get('posY'))];
      const endPoint = [
        parseInt(features[0].get('etiqPosX')),
        parseInt(features[0].get('etiqPosY')),
      ];

      return [
        new Style({
          geometry: new LineString([startPoint, endPoint]),
          stroke: new Stroke({
            color: colorSilo,
            lineDash: [10, 10],
            width: 4,
          }),
        }),
        new Style({
          image: new Icon({
            scale: 1,
            src: siloImageSrc(features),
          }),
          text: new Text({
            text: '' + parseInt(features[0].get('codePlanteur').substring(0, 7), 10) +
              (features[0].get('ordre') && parseInt(features[0].get('ordre')) > 0 ? ' - '+parseInt(features[0].get('ordre')) : ''),
            fill: new Fill({
              color: '#D62828',
            }),
            stroke: new Stroke({
              color: '#ffffff',
              width: 3
            }),
            offsetY: -24,
            font: font,
          }),
        }),
      ];
    } else {
      // default silo feature
      let defaultStyle =  new Style({
        image: new Icon({
          scale: 1,
          src: siloImageSrc(features),
        }),
        text: new Text({
          text: '' + parseInt(features[0].get('codePlanteur').substring(0, 7), 10) +
            (features[0].get('ordre') && parseInt(features[0].get('ordre')) > 0 ? ' - '+parseInt(features[0].get('ordre')) : ''),
          fill: new Fill({
            color: '#D62828',
          }),
          stroke: new Stroke({
            color: '#ffffff',
            width: 3
          }),
          offsetY: -24,
          font: font,
        }),
      });
      //reSizeIcon(defaultStyle);
      arrayStyleSilo.push(defaultStyle);
    }
  }

  return arrayStyleSilo;
};

// silo encodage style
const styleEncodage = (feature) => {
  const features = feature.get('features');
  const colorSilo = features[0].get('encodagecolor') ? features[0].get('encodagecolor') : '#FF3030';

  if (features.length > 1) {
    // cluster feature
    let styleCluster = new Style({
      image: new Icon({
        scale: 1,
        src: siloImageSrc(features),
      }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: '#fff',
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3
        }),
        font: font,
        offsetY: 26,
      }),
    });
    //reSizeIcon(styleCluster);
    return styleCluster;
  } else {
    // single feature
    const htg = format(parse(features[0].get('htg'), 'HHmmss', new Date()), 'HH:mm:ss');
    let defaultStyle = new Style({
      image: new Icon({
        scale: 1,
        src: siloImageSrc(features),
        color: colorSilo
      }),
      text: new Text({
        text: htg + '-' + features[0].get('ordrechargement'),
        fill: new Fill({
          color: colorSilo,
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3
        }),
        offsetY: -24,
        font: font,
      }),
    });
    //reSizeIcon(defaultStyle);
      return defaultStyle;
  }
};
const getImageSrc = (feature) => {
  const mapStatut = [...statutBach, ...statutNiv];
  const imageSrc = mapStatut.find((i) => i.code === feature.get('statut'));
  return imageSrc;
};

const styleNivBach = (feature) => {
  const features = feature.get('features');
  let finalStyle = [];

  const featureStyle = (highlight, imageSrc) => {
    return new Style({
      image: imageSrc
        ? new Icon({
            scale: 1,
            src: imageBasePath() + imageSrc.src,
          })
        : new CircleStyle({
            radius: 10,
            stroke: new Stroke({
              color: '#fff',
            }),
            fill: new Fill({
              color: highlight,
            }),
          }),
      text:
        getViewZoom() > 12
          ? new Text({
              text: '' + parseInt(features[0].get('cpcu').substring(0, 7), 10),
              fill: new Fill({
                color: '#D62828',
              }),
              stroke: new Stroke({
                color: '#ffffff',
                width: 3
              }),
              offsetY: -24,
              font: font,
            })
          : '',
    });
  };

  const clusterStyle = (features, imageSrc) => {
    let colorFill = '#000000';
    return new Style({
      image: imageSrc
        ? new Icon({
            scale: 1,
            src: imageBasePath() + imageSrc.src,
          })
        : new Icon({
          scale: 1,
          src: siloImageSrc(features),
        }),
      text: new Text({
        text: features.length.toString(),
        fill: new Fill({
          color: colorFill,
        }),
        stroke: new Stroke({
          color: '#ffffff',
          width: 3
        }),
        font: font,
        scale: 1,
        textBaseline: 'middle',
        offsetY: 26,
      }),
    });
  };

  const featureLieeStyle = (colorSilo) => {
    const startPoint = [parseInt(features[0].get('gpslo')), parseInt(features[0].get('gpsla'))];
    const endPoint = [
      parseInt(features[0].get('longitudesilo')),
      parseInt(features[0].get('latitudesilo')),
    ];

    return new Style({
      geometry: new LineString([startPoint, endPoint]),
      stroke: new Stroke({
        color: colorSilo,
        lineDash: [10, 10],
        width: 4,
      }),
    });
  };

  if (features.length > 1) {
    let featuresFiltered = {};
    features.forEach((feature) => {
      const statut = feature.get('statut');
      if (!featuresFiltered[statut]) {
        featuresFiltered[statut] = [];
      }
      featuresFiltered[statut].push(feature);
    });

    Object.values(featuresFiltered).forEach((features) => {
      const imageSrc = getImageSrc(features[0]);
      let style;
      if (Object.values(featuresFiltered).length > 1) {
        style = clusterStyle(Object.values(featuresFiltered), null);
      } else {
        style = clusterStyle(features, imageSrc);
      }
      //reSizeIcon(style);
      finalStyle.push(style);
    });
  } else {
    const imageSrc = getImageSrc(features[0]);
    const colorSilo = props.color ? props.color : '#3399CC';
    const highlight = features[0].get('highlight') ? '#BADA55' : colorSilo;
    if (
      parseInt(features[0].get('longitudesilo')) !== 0 &&
      parseInt(features[0].get('latitudesilo')) !== 0
    ) {
      // silo doublon
      // draw linestring between the 2 features by return 2 style in an array (one for the linestring, and one for the feature)
      const lineStringStyle = featureLieeStyle(colorSilo);
      const nivBachStyle = featureStyle(highlight, imageSrc);
      //reSizeIcon(nivBachStyle);
      finalStyle.push(lineStringStyle, nivBachStyle);
    } else {
      const nivBachStyle = featureStyle(highlight, imageSrc);
      //reSizeIcon(nivBachStyle);
      finalStyle.push(nivBachStyle);
    }
  }

  return finalStyle;
};

export { style, styleEncodage, styleNivBach };
