import { Fill, Style, Text, Icon, Stroke } from 'ol/style';
import { typeMaterielAgricole } from '../enums';
import { imageBasePath } from '../../../utils/image';


const font = 'bold' + ' ' + '16px' + '/' + '1' + ' ' + 'Arial';

const style = (feature) => {
  // single feature
  const typeMat = typeMaterielAgricole.find((i) => i.value === feature.get('type'));

  const imgKey = feature.get('engin') ? 'img_engin_'+feature.get('engin') : 'img';
  const img = imgKey in typeMat ? typeMat[imgKey] : typeMat.img;

  let styleMat = new Style({
    image: new Icon({
      src: imageBasePath() + img,
      scale: 1
    }),
    text: new Text({
      text: feature.get('code'),
      fill: new Fill({
        color: '#D62828',
      }),
      stroke: new Stroke({
        color: '#ffffff',
        width: 3
      }),
      offsetY: -24,
      font: font,
    }),
  });
  return styleMat;
};

export { style };
