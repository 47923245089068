import { usePrefTechStore } from '../../../stores/prefTech';

const prefTech = usePrefTechStore.getState().prefs;
const { LOGIN } = prefTech;

// WS3504
// codemaj = C
// codemaj = M
const createOrUpdateBackendEntry = ({ data, campagne }) => {
  const {
    codeinsee = '',
    nomplanteur,
    codeplanteur = '',
    nomparcelle = '',
    codeparcelle = '',
    numilot = '',
    culture = '',
    typebio = '',
    surfreelle = '',
    surface = '',
    surfcalc = '',
    selparcelle,
    longitudeparc = '',
    latitudeparc = '',
    codemaj = 'C',
    edit = false,
    communeemps = '',
    emps = '',
  } = data;
  let formatSurfReelle = '';

  if (surfreelle != '') {
    formatSurfReelle = surfreelle
      .replace('.', '')
      .replace(',', '')
      .replace('ha', '')
      .replaceAll('_', '0')
      .padStart(8, '0');
  } else {
    formatSurfReelle = surface.padStart(8, '0');
  }

  const formatSurfCalc = surfcalc.replace('.', '').padStart(8, '0');

  const parcelleParams = {
    technicien: 'O',
    windows: LOGIN || '',
    parcelletracee: 'O',
    parcellevinass: '',
    commparcelle: codeinsee.padEnd(7, '0'),
    cpcualpha: nomplanteur.cpcu || codeplanteur,
    nomparcelle,
    codparcelle: codeparcelle,
    culture: !culture ? '' : culture.code ? culture.code : culture,
    numilot: numilot.padStart(3, '0'),
    campagne,
    typebio,
    surfreelle: formatSurfReelle,
    surfcalc: formatSurfCalc,
    longitudeparc,
    latitudeparc,
    codemaj,
    commemps: !communeemps || communeemps == '0000000' ? '' : communeemps,
    numemps: !emps || emps == '00' ? '' : emps
  };

  // the user selected a parcelle that exists in CreateForm list
  if (selparcelle) {
    parcelleParams.campagne = selparcelle.campagne;
    parcelleParams.commparcelle = selparcelle.commparcelle;
    parcelleParams.cpcualpha = selparcelle.cpcu;
    parcelleParams.nomparcelle = selparcelle.nomparcelle;
    parcelleParams.codparcelle = selparcelle.parcelle;
    parcelleParams.numilot = selparcelle.numilot;
    parcelleParams.codemaj = 'M';
  }

  // the user has edited parcelle infos in EditForm
  if (edit) {
    parcelleParams.cpcualpha = codeplanteur;
    parcelleParams.codparcelle = codeparcelle;
    parcelleParams.codemaj = 'M';
  }

  return parcelleParams;
};

// WS3504
// codemaj = S
const deleteBackendEntry = ({ data }) => {
  const {
    area = '',
    codeplanteur = '',
    codeparcelle = '',
    culture = '',
    longitudeparc = '',
    latitudeparc = '',
  } = data;
  const formatSurfCalc = area.replace('.', '').padStart(8, '0');

  const parcelleParams = {
    technicien: 'O',
    windows: LOGIN,
    // Si on supprime une culture, on laisse parcelletracee à O
    parcelletracee: culture ? 'O' : '',
    parcellevinass: '',
    commparcelle: '',
    cpcualpha: codeplanteur,
    nomparcelle: '',
    codparcelle: codeparcelle,
    culture: !culture ? '' : culture.code ? culture.code : culture,
    numilot: '',
    campagne: '',
    typebio: '',
    surfreelle: '',
    surfcalc: formatSurfCalc,
    longitudeparc,
    latitudeparc,
    codemaj: 'S',
  };

  return parcelleParams;
};

export { createOrUpdateBackendEntry, deleteBackendEntry };
